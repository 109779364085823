import { gql } from "graphql-request";

export const ADD_CONVERSATION_PARTICIPANT = gql`
  mutation AddConversationParticipant($conversationId: Int!, $participant: AI_CreateParticipantPayload_Input) {
    ai {
      add_ai_conversation_participant(conversation_id: $conversationId, input: $participant) {
        conversation_id
        id
        name
        email
        phone_number
        user_id
      }
    }
  }
`;

export const DELETE_CONVERSATION_PARTICIPANT = gql`
  mutation DeleteConversationParticipant($conversationId: Int!, $participantId: Int!) {
    ai {
      delete_conversation_participant(conversation_id: $conversationId, participant_id: $participantId)
    }
  }
`;

export const UPDATE_CONVERSATION_PARTICIPANT = gql`
  mutation UpdateConversationParticipant(
    $conversationId: Int!
    $participantId: Int!
    $channelType: AI_ChannelType
    $email: String = ""
    $name: String = ""
    $phoneNumber: String = ""
    $userId: String = ""
    $type: AI_ParticipantType
  ) {
    ai {
      update_conversation_participant(
        conversation_id: $conversationId
        participant_id: $participantId
        input: {
          channel_type: $channelType
          email: $email
          name: $name
          phone_number: $phoneNumber
          type: $type
          user_id: $userId
        }
      ) {
        name
        phone_number
        email
        conversation_id
        channel_type
      }
    }
  }
`;
