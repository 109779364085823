export function formatDate(
  date: string | number | null | undefined,
  variant: "numeric" | "short" | "long" = "numeric"
): string {
  if (date == null) {
    return "";
  }
  const dateObj = new Date(date);

  switch (variant) {
    case "numeric":
      return dateObj.toLocaleDateString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
    case "short":
      return dateObj.toLocaleDateString("en-US", { month: "short", day: "numeric", timeZone: "UTC" });
    case "long":
      return dateObj.toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric", timeZone: "UTC" });
    default:
      return "";
  }
}

export function formatAmount(amount: number | null | undefined): string {
  if (amount == null) {
    return "";
  }
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(amount);
}

export function formatTitle(title: string | null | undefined): string {
  if (title == null) {
    return "";
  }
  title = title.replace(/-/g, " "); // replace hyphens with spaces
  title = title.replace(/_/g, " "); // replace underscores with spaces
  title = title.replace(/([a-z])([A-Z])/g, "$1 $2"); // split camelCase words
  return title.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
}
export function formatAddress(address: any) {
  const formattedAddress = `${address?.line1 ? `${address.line1},` : ""} ${
    address?.line2 ? `${address.line2},` : ""
  } ${address?.city ? `${address.city},` : ""} ${address?.state ? `${address.state},` : ""} ${address?.postal_code ? `${address.postal_code},` : ""}`;
  return formattedAddress;
}

export function textEllipsis(text: string, length: number, side?: "start" | "end"): string {
  const ellipsis = "...";
  if (text?.length > length) {
    switch (side) {
      case "start":
        return ellipsis + text.slice(-(length - ellipsis?.length));
      case "end":
        return text.slice(0, length - ellipsis?.length) + ellipsis;
      default:
        return text.slice(0, length - ellipsis?.length / 2) + ellipsis + text.slice(-(length - ellipsis?.length / 2));
    }
  }
  return text;
}

export function getTimeDifference(updatedAt: string) {
  const _updatedAt = new Date(updatedAt).getTime();
  const now = new Date().getTime();
  const diffInSeconds = Math.floor((now - _updatedAt) / 1000);

  const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

  if (diffInSeconds < 60) {
    return `${diffInSeconds} seconds ago`;
  } else if (diffInSeconds < 3600) {
    // less than 1 hour
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    return rtf.format(-diffInMinutes, "minute");
  } else if (diffInSeconds < 86400) {
    // less than 1 day
    const diffInHours = Math.floor(diffInSeconds / 3600);
    return rtf.format(-diffInHours, "hour");
  } else if (diffInSeconds < 2592000) {
    // less than 1 month
    const diffInDays = Math.floor(diffInSeconds / 86400);
    return rtf.format(-diffInDays, "day");
  } else if (diffInSeconds < 31536000) {
    // less than 1 year
    const diffInMonths = Math.floor(diffInSeconds / 2592000);
    return rtf.format(-diffInMonths, "month");
  } else {
    const diffInYears = Math.floor(diffInSeconds / 31536000);
    return rtf.format(-diffInYears, "year");
  }
}

export function isTimeDifferenceGreaterThanOneHour(prevTimestamp: string | null, timestamp: string) {
  if (!prevTimestamp) return true;
  const _prevTimestamp = new Date(prevTimestamp).getTime();
  const _timestamp = new Date(timestamp).getTime();
  const diffInSeconds = Math.floor((_timestamp - _prevTimestamp) / 1000);
  return diffInSeconds > 3600;
}

export function formatTimestamp(timestamp: string) {
  const createdDate = new Date(timestamp);
  const now = new Date();
  const diffTime = Math.abs(now.getTime() - createdDate.getTime()); // milliseconds
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const time = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(createdDate);

  if (diffDays > 7) {
    // if more than a week ago, return in 'mm/dd/yyyy' format
    return `${new Intl.DateTimeFormat("en-US").format(createdDate)} ${time}`;
  } else {
    // else return as day of week and time
    const dayOfWeek = new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(createdDate);

    return `${dayOfWeek} ${time}`;
  }
}

export const isValidNumber = (value: number | string | null | undefined) => {
  // @ts-expect-error
  return !isNaN(parseFloat(value)) && isFinite(parseFloat(value));
};

export const formatNumber = (
  value: number | string | null | undefined,
  precision = 2,
  defaultValue: string | null = "-"
) => {
  if (!isValidNumber(value)) return defaultValue;
  return Number(value).toFixed(precision);
};

export const getTomorrowDate = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  return new Intl.DateTimeFormat("en-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(tomorrow);
};

export function getScrollParent(node: HTMLElement | null) {
  if (node == null) {
    return null;
  }
  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return getScrollParent(node.parentNode as HTMLElement);
  }
}
