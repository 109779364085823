import { isMacOS } from "@/utils";
import {
  Popover,
  PopoverTrigger,
  Button,
  Icon,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Alert,
  Kbd,
  VStack,
  Box,
  Text,
} from "@chakra-ui/react";
import { Fragment } from "react";
import { TbKeyboard } from "react-icons/tb";

export default function ShortcutGuide() {
  return (
    <Popover placement="top">
      <PopoverTrigger>
        <Button variant="outline" borderRadius="full" _hover={{ bgColor: "gray.50" }} py="0" h="6">
          <Text fontSize="2xs">More keyboard shortcuts</Text>
          <Icon as={TbKeyboard} boxSize="4" p="0" ml="2" />
        </Button>
      </PopoverTrigger>
      <PopoverContent _focusVisible={{ outline: "none" }}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader textAlign="center" display="flex" alignItems="center" gap="2">
          <Icon as={TbKeyboard} boxSize="4" p="0" />
          <Text display="inline">More keyboard shortcuts</Text>
        </PopoverHeader>
        <PopoverBody>
          <Alert
            status="info"
            bgColor="transparent"
            borderColor="gray.100"
            borderWidth="thin"
            w="full"
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
          >
            Click on{" "}
            <Text display="inline" bgColor="primary.200" mx="1" px="1">
              highlighted text
            </Text>{" "}
            or press <Kbd mx="1">tab</Kbd>
            to view suggested order items
          </Alert>
          <VStack my="2" alignItems="start">
            {shortcuts.map((elem, index) => (
              <Box w="full" key={index}>
                <Text mx="1" color="gray.600">
                  {elem.text}
                </Text>
                <Text borderColor="gray.100" borderWidth="thin" py="1" px="2" mt="1" borderRadius="md">
                  Press{" "}
                  {elem.keys.map((key, i) => (
                    <Fragment key={i}>
                      <Kbd key={i}>{key}</Kbd>
                      {i < elem.keys.length - 1
                        ? elem.keys[i + 1] == "shift" || index == 4 || index == 5
                          ? "+ "
                          : "or "
                        : ""}
                    </Fragment>
                  ))}
                </Text>
              </Box>
            ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
const shortcuts = [
  { text: "View the suggested list of the next item detection", keys: ["tab", "→"] },
  { text: "View the suggested list of the previous item detection", keys: ["tab", "shift", "←"] },
  { text: "Select a different suggested item", keys: ["↑", "↓"] },
  { text: "Close the suggested list popup", keys: ["esc"] },
  { text: "Ignore this item detection", keys: ["shift", "i"] },
  { text: "Change the quantity of the item detection", keys: ["shift", "↑"] },
];
