import { useLocation, useOutlet } from "react-router-dom";
import { ReactNode, useContext, createContext, useEffect, useState } from "react";
import {
  Flex,
  Stack,
  Icon,
  IconButton,
  Box,
  useDisclosure,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  useBreakpointValue,
  Text,
  Spacer,
} from "@chakra-ui/react";
import { HiArrowDown, HiArrowUp, HiDotsHorizontal, HiDotsVertical } from "react-icons/hi";
import { useSortOrderContext } from "@/providers/sort-order";
import { FiChevronLeft } from "react-icons/fi";
import { CustomerInvite } from "@/components/items/customer-invite";

export default function SidebarShell({
  children,
  refreshChats,
}: {
  children: ReactNode;
  refreshChats: () => void;
}): ReactNode {
  const outlet = useOutlet();
  const { pathname } = useLocation();
  const isBaseView = useBreakpointValue({ base: true, lg: false }, { ssr: false });
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: !isBaseView,
  });

  const [customerToAdd, setCustomerToAdd] = useState<string | null>(null); // 'name,id' as string

  // Close sidebar when the user clicks on a customer, location changes
  useEffect(() => {
    if (isBaseView && isOpen) {
      onToggle();
    }
  }, [pathname]);

  return (
    <SidebarContext.Provider value={{ isOpen, onToggle }}>
      <Flex mt="2">
        <Stack
          w={isOpen ? { base: "full", lg: "auto" } : "auto"}
          h={isOpen ? "full" : "auto"}
          px="6"
          pt="2"
          position={isOpen ? { base: "fixed", lg: "relative" } : "fixed"}
          bgColor={isOpen ? "white" : "transparent"}
          zIndex="2"
        >
          {isOpen ? (
            <>
              <Box
                w={isOpen ? { base: "full", lg: "xs" } : "auto"} // Note: below lg is full width
                borderWidth="thin"
                borderColor="gray.200"
                borderTopRadius="lg"
                borderBottomLeftRadius="lg"
                borderBottomRightRadius="none"
              >
                <HStack
                  justifyContent="space-between"
                  px="4"
                  py="2"
                  borderBottomWidth="thin"
                  borderBottomColor="gray.200"
                  alignItems="center"
                >
                  {/* <IconButton
                    onClick={onToggle}
                    variant="unstyled"
                    icon={
                      <Icon
                        as={isOpen ? HiX : isBaseView ? HiArrowLeft : HiMenuAlt4}
                        boxSize="4"
                        color="gray.600"
                        mt="1.5"
                      />
                    }
                    aria-label="Toggle Sidebar"
                  />
                  <SearchBar />
                  <CustomerSort /> */}
                  <Text>Customers</Text>
                  <Spacer />
                  {/* Modal and button to add an exclusion */}
                  <CustomerInvite
                    customerToAdd={customerToAdd}
                    setCustomerToAdd={setCustomerToAdd}
                    refreshChats={refreshChats}
                  />
                </HStack>

                <Stack h={{ base: "calc(100vh - 9rem)", lg: "calc(100vh - 7.5rem)" }} overflowY="auto" py="2">
                  {children}
                </Stack>
              </Box>
            </>
          ) : (
            // When sidebar is closed
            <IconButton
              onClick={onToggle}
              variant="outline"
              size="sm"
              icon={<Icon as={FiChevronLeft} boxSize="4" color="gray.600" />}
              aria-label="Toggle Sidebar"
              borderRadius="lg"
              borderColor="gray.200"
              mt={{ base: "-2", lg: "2" }}
              ml={{ lg: "2" }}
              bgColor="white"
            />
          )}
        </Stack>

        {!(isBaseView && isOpen) && (
          <Box maxW="full" flex="1" overflow="auto">
            {outlet}
          </Box>
        )}
      </Flex>
    </SidebarContext.Provider>
  );
}

interface SidebarContextValue {
  isOpen: boolean;
  onToggle: () => void;
}

const SidebarContext = createContext({
  isOpen: false,
  onToggle: () => {},
});

export function useSidebarContext(): SidebarContextValue {
  return useContext(SidebarContext);
}

// Dropdown for customer sort
function CustomerSort(): ReactNode {
  const { isDesc, setIsDesc, orderBy, setOrderBy } = useSortOrderContext();

  return (
    <Menu closeOnSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={IconButton}
            icon={<Icon as={isOpen ? HiDotsHorizontal : HiDotsVertical} boxSize={{ base: "3", lg: "4" }} />}
            variant="outline"
            borderRadius="full"
            borderColor="gray.200"
            _focusVisible={{ outline: "none" }}
          />
          <MenuList minWidth="12rem">
            <MenuOptionGroup
              type="radio"
              onChange={(val) => {
                setIsDesc(JSON.parse(val as "true" | "false"));
                localStorage.setItem("isDesc", val as string);
              }}
              value={JSON.stringify(isDesc)}
              defaultValue={JSON.stringify(isDesc)}
              title="Sort"
            >
              <MenuItemOption value="true" icon={<HiArrowDown />}>
                Descending
              </MenuItemOption>
              <MenuItemOption value="false" icon={<HiArrowUp />}>
                Ascending
              </MenuItemOption>
            </MenuOptionGroup>
            <MenuDivider />
            <MenuOptionGroup
              type="radio" // Could be 'checkbox' and const [sortBy, setSortBy] = useState([]);
              onChange={(val) => {
                setOrderBy(val as "total" | "overdue" | "display");
                localStorage.setItem("orderBy", val as string);
              }}
              value={orderBy}
              defaultValue={orderBy}
              title="Order by"
            >
              {/* <MenuItemOption value="overdue" isDisabled>Overdue Balance</MenuItemOption>
              <MenuItemOption value="total">Total Balance</MenuItemOption> */}
              <MenuItemOption value="display">Display Name</MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </>
      )}
    </Menu>
  );
}
