import { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState, MutableRefObject } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Divider,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spinner,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { HiPlus } from "react-icons/hi";
import _ from "lodash";
import { queryCustomersByCompanyId } from "@/api/queries/customer";
import { Conversation, Customer } from "@/utils/types";
import { getUserCompany } from "@/utils/user";
import queryClient from "@/api/query-client";
import InviteUser from "@/components/items/invite-user";
import { queryAllConversationsByCompanyId } from "@/api/queries/conversation";

export async function loader() {
  const {
    company: { companyId },
  } = getUserCompany();
  const fetchConversations = queryAllConversationsByCompanyId(companyId, 0, 999);
  const { conversations }: { conversations: Conversation } = (await queryClient.fetchQuery(fetchConversations)) ?? {};

  return { conversations };
}

export function CustomerInvite({
  customerToAdd,
  setCustomerToAdd,
  refreshChats,
}: {
  customerToAdd: string | null;
  setCustomerToAdd: Dispatch<SetStateAction<string | null>>;
  refreshChats: () => void;
}): ReactNode {
  const initialRef = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmittable, setIsSubmittable] = useState<boolean>(false);

  const [step, setStep] = useState<number>(0); // 0 select customer, 1 fill in customer info, 2 confirm and create conversation
  const nextStep = () => {
    const next = step + 1;
    if (next > 2) return; // three steps in total
    setStep(next);
  };
  const prevStep = () => {
    const prev = step - 1;
    if (prev < 0) return;
    setStep(prev);
  };
  return (
    <>
      <Button
        onClick={() => {
          onOpen();
        }}
        variant="solid"
        colorScheme="primary"
        bg="primary.400"
        borderRadius="full"
        size="xs"
        w="36"
        _focusVisible={{ boxShadow: "none" }}
      >
        <Icon as={HiPlus} mr="1" />
        Invite a Customer
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setStep(0);
          refreshChats();
        }}
        isCentered
        size={{ base: "sm", lg: "xl" }}
        motionPreset="slideInBottom"
        initialFocusRef={initialRef}
        closeOnOverlayClick={false}
      >
        <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
        <ModalContent mx="2">
          <ModalHeader>Invite a customer to join</ModalHeader>
          <ModalCloseButton _focusVisible={{ outline: "none" }} />
          <Divider borderColor="gray.400" w="96%" mx="auto" />
          <ModalBody>
            {step == 0 && (
              <SearchInstantCustomer
                customerToAdd={customerToAdd}
                setCustomerToAdd={setCustomerToAdd}
                setIsSubmittable={setIsSubmittable}
                initialRef={initialRef}
                prevStep={prevStep}
                nextStep={nextStep}
              />
            )}
            {step == 1 && customerToAdd && (
              <InviteUser
                customer={JSON.parse(customerToAdd) as Customer}
                isAdditionalParticipant={false}
                prevStep={prevStep}
                nextStep={nextStep}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function SearchInstantCustomer({
  customerToAdd,
  setCustomerToAdd,
  setIsSubmittable,
  initialRef,
  prevStep,
  nextStep,
}: {
  customerToAdd: string | null;
  setCustomerToAdd: Dispatch<SetStateAction<string | null>>;
  setIsSubmittable: Dispatch<SetStateAction<boolean>>;
  initialRef: MutableRefObject<null> | null;
  prevStep: () => void;
  nextStep: () => void;
}): ReactNode {
  const [displayedCustomers, setDisplayedCustomers] = useState<Customer[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<null | string>(null);

  useEffect(() => {
    if (!searchTerm) return;
    const timeoutId = setTimeout(() => {
      handleSearchChange(searchTerm);
    }, 1000); // 1s delay

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);

  const {
    company: { companyId },
  } = getUserCompany();

  useEffect(() => {
    setCustomerToAdd(null);
    setIsSubmittable(false);
  }, []);

  function handleSearchChange(val: string): void {
    const searchText = val;
    const isDesc = true;
    const orderBy = "DisplayName";
    const offset = 0;
    const maxLength = 999;

    (async () => {
      setIsLoading(true);
      const fetchCustomers = queryCustomersByCompanyId(companyId, searchText, isDesc, orderBy, offset, maxLength);
      const { customers = [] }: { customerCount: number; customers: Customer[] } =
        (await queryClient.fetchQuery(fetchCustomers)) ?? {};

      setDisplayedCustomers(customers);
      setIsLoading(false);
    })();
  }

  return (
    <Box mt="3">
      {customerToAdd && (
        <VStack w="full" mb="4" px={{ lg: "8" }}>
          <Text fontWeight="semibold" fontSize={{ base: "xs", lg: "md" }}>
            {customerToAdd && JSON.parse(customerToAdd)?.customerName}
          </Text>
        </VStack>
      )}
      <InputGroup w="full">
        <InputLeftElement pointerEvents="none">
          <Icon as={FiSearch} color="gray.400" />
        </InputLeftElement>
        <Input
          type="search"
          name="q"
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          placeholder="Search for a customer"
          aria-label="Search"
          autoComplete="off"
          borderRadius={8}
          borderColor="gray.400"
          ref={initialRef}
        />
      </InputGroup>

      {/* List of Customers */}
      <VStack
        alignItems="start"
        mt="4"
        py="2"
        h="60"
        overflowY="auto"
        borderWidth={displayedCustomers.length > 0 ? "thin" : "none"}
        borderColor="gray.200"
        borderLeftRadius="lg"
        spacing="1"
      >
        {isLoading ? (
          <Center py="2" h="8" w="full">
            <Spinner boxSize="4" />
          </Center>
        ) : !searchTerm ? (
          <Center py="2" h="8" w="full">
            <Text>Look up a customer by typing in the search box above</Text>
          </Center>
        ) : displayedCustomers.length == 0 ? (
          <Center py="2" h="8" w="full">
            <Text>No customers have been found</Text>
          </Center>
        ) : (
          <RadioGroup defaultValue="1" onChange={setCustomerToAdd} w="full" zIndex="modal">
            {displayedCustomers.map((customer) => {
              const { customerName, customerId } = customer;
              return (
                <Box
                  key={customerId}
                  _hover={{
                    bgColor:
                      customerToAdd && customerId == JSON.parse(customerToAdd).customerId ? "gray.100" : "gray.50",
                  }}
                  bgColor={customerToAdd && customerId == JSON.parse(customerToAdd).customerId ? "gray.100" : "none"}
                  fontSize="2xs"
                >
                  <Radio
                    value={JSON.stringify(customer)}
                    px={{ lg: "4" }}
                    py={{ base: "1", lg: "2" }}
                    size="xs"
                    _focusVisible={{ boxShadow: "none" }}
                    bgColor="none"
                    border="none"
                    _checked={{ bgColor: "none", border: "none" }}
                    w="full"
                  >
                    <Text fontSize="xs">{customerName}</Text>
                  </Radio>
                </Box>
              );
            })}
          </RadioGroup>
        )}
      </VStack>

      <ButtonGroup justifyContent="end" my="4" w="full">
        <Button size="sm" ml="2" w="16" colorScheme="primary" isDisabled={!customerToAdd} onClick={nextStep}>
          Next
        </Button>
      </ButtonGroup>
    </Box>
  );
}
