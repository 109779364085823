import queryClient from "@/api/query-client";
import { AuthRequired, configureLogRocket, LogRocketWrapper } from "@butter-technologies/dish";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactNode } from "react";
import PromptInstallProvider from "@/providers/prompt-install";
import SortOrderProvider from "@/providers/sort-order";
import AudioProvider from "@/providers/audio-provider";
import NewOrderProvider from "@/providers/order-provider";

configureLogRocket(import.meta.env.PROD, "xcbecb/butter-web-conversational");

// @ts-expect-error
export const combineComponents = (...components) => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      // @ts-expect-error
      return ({ children }: { children }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent.component {...CurrentComponent.props}>{children}</CurrentComponent.component>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }: { children?: ReactNode }) => <>{children}</>
  );
};

const providers = [
  { component: AuthRequired },
  { component: PromptInstallProvider },
  { component: QueryClientProvider, props: { client: queryClient } },
  { component: SortOrderProvider },
  { component: AudioProvider },
  { component: NewOrderProvider },
  { component: LogRocketWrapper, props: { isProd: import.meta.env.PROD } },
];
export const AppContext = combineComponents(...providers);
