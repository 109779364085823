import { formatAmount } from "@/utils/formatter";
import { OrderLineItem } from "@/utils/types";
import { Box, ListItem, HStack, IconButton, Input, Text, useNumberInput, Button, Icon } from "@chakra-ui/react";
import { ReactNode, useState, useEffect } from "react";
import { HiOutlineTrash } from "react-icons/hi";

export default function DetailRow({
  item,
  isFirst,
  isEditable,
}: {
  item: OrderLineItem;
  isFirst: boolean;
  isEditable: boolean;
}): ReactNode {
  const [qty, setQty] = useState(item.quantity); // Necessary because default value of useNumberInput component does not update after a rerender

  useEffect(() => {
    setQty(item.quantity);
  }, [item.quantity]);

  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step: 1,
    defaultValue: qty,
    min: 0,
    max: 9999,
    precision: 0,
  });

  const increment = getIncrementButtonProps();
  const decrement = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <Box borderTopWidth={isFirst ? "none" : "thin"} borderTopColor="gray.100" pl="8">
      <ListItem as={HStack} w="full" h={{ base: "auto", lg: "12" }} spacing="8">
        {/* <ListIcon as={MdCircle} boxSize="2" textColor="gray.800" /> */}
        <Text fontSize="xs" w="72" textAlign="left">
          {item.description}
        </Text>
        <Text fontSize="xs" w="32" textAlign="center">
          {qty}
        </Text>
        <HStack>
          <Button {...increment} size="xs" isDisabled={!isEditable}>
            +
          </Button>
          <Input {...input} w="16" variant="flushed" textAlign="center" />
          <Button {...decrement} size="xs" isDisabled={!isEditable}>
            -
          </Button>
        </HStack>
        <Text fontSize="xs" w="32" textAlign="center">
          {item.unitPrice ? formatAmount(item.unitPrice) : "-"}
        </Text>
        <Text fontSize="xs" w="32" textAlign="center">
          {item.unitPrice ? formatAmount(item.unitPrice * item.quantity) : "-"}
        </Text>
        <IconButton
          variant="unstyled"
          aria-label="Delete"
          icon={<Icon as={HiOutlineTrash} boxSize="4" color="gray.400" _hover={{ color: "gray.800" }} />}
          _focusVisible={{ boxShadow: "none" }}
          w="32"
          isDisabled={!isEditable}
        />
      </ListItem>
    </Box>
  );
}

export function checkIsValidQty(val: string): boolean {
  const num = parseFloat(val);
  return !isNaN(num) && num >= 0 && num <= 999;
}
