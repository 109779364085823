import { gql } from "graphql-request";
import graphQLClient from "@/api/graphql-client";
import type { CompanyAltCase, Customer, QueryError } from "@/utils/types";

const ALL_CUSTOMERS_BY_COMPANY_ID_QUERY = gql`
  query AllCustomersByCompanyId(
    $companyId: ID!
    $isDesc: Boolean
    $orderBy: CustomerOrderBy!
    $offset: Int
    $maxLength: Int
  ) {
    company(company_pid: $companyId) {
      customer_count
      customers(sort: { is_descending: $isDesc, order_by: $orderBy }, offset: $offset, max_length: $maxLength) {
        id
        total_balance
        overdue_balance
        display_name
        phone
        email
      }
    }
  }
`;

export const queryAllCustomersByCompanyId = (
  companyId: string,
  isDesc: boolean,
  orderBy: string,
  offset: number,
  maxLength: number
): {
  queryKey: [string, string, boolean, string, number, number];
  queryFn: () => Promise<{ customerCount: number; customers: Customer[] } | QueryError>;
} => ({
  queryKey: ["customers", companyId, isDesc, orderBy, offset, maxLength],
  queryFn: async (): Promise<{ customerCount: number; customers: Customer[] } | QueryError> => {
    const _orderBy = orderBy == "display" ? "DisplayName" : "DisplayName";
    try {
      const { company }: { company: CompanyAltCase } = await graphQLClient.request(ALL_CUSTOMERS_BY_COMPANY_ID_QUERY, {
        companyId,
        isDesc,
        orderBy: _orderBy,
        maxLength,
        offset,
      });

      const { customer_count: count, customers: data } = company;
      const customers =
        data?.map((elem) => ({
          customerId: elem.id,
          customerName: elem.display_name,
          customerPhone: elem.phone,
          customerEmail: elem.email,
        })) ?? [];

      return { customerCount: parseFloat(count ?? "999"), customers };
    } catch (error) {
      return {
        error: {
          message: "An error occurred while querying customers: " + String(error),
        },
      };
    }
  },
});

const CUSTOMERS_BY_COMPANY_ID_QUERY = gql`
  query CustomersByCompanyId(
    $companyId: ID!
    $searchText: String
    $isDesc: Boolean
    $orderBy: CustomerOrderBy!
    $offset: Int
    $maxLength: Int
  ) {
    company(company_pid: $companyId) {
      customer_count(search: { text: $searchText })
      customers(
        search: { text: $searchText }
        sort: { is_descending: $isDesc, order_by: $orderBy }
        offset: $offset
        max_length: $maxLength
      ) {
        id
        total_balance
        overdue_balance
        display_name
        phone
        email
        butter_company_pid
        billing_address {
          attn
          city
          line1
          line2
          postal_code
          state
        }
        shipping_address {
          attn
          city
          line1
          line2
          postal_code
          state
        }
      }
    }
  }
`;

export const queryCustomersByCompanyId = (
  companyId: string,
  searchText: string,
  isDesc: boolean,
  orderBy: string,
  offset: number,
  maxLength: number
): {
  queryKey: [string, string, string, boolean, string, number, number];
  queryFn: () => Promise<{ customers: Customer[] } | QueryError>;
} => ({
  queryKey: ["customers", companyId, searchText, isDesc, orderBy, offset, maxLength],
  queryFn: async (): Promise<{ customerCount: number; customers: Customer[] } | QueryError> => {
    const _orderBy =
      orderBy == "overdue"
        ? "OverdueBalance"
        : orderBy == "total"
          ? "TotalBalance"
          : orderBy == "display"
            ? "DisplayName"
            : "TotalBalance";
    try {
      const { company }: { company: CompanyAltCase } = await graphQLClient.request(CUSTOMERS_BY_COMPANY_ID_QUERY, {
        companyId,
        searchText,
        isDesc,
        orderBy: _orderBy,
        maxLength,
        offset,
      });

      const { customer_count: count, customers: data } = company;
      const customers =
        data?.map((elem) => ({
          customerId: elem.id,
          customerName: elem.display_name,
          customerPhone: elem.phone,
          customerEmail: elem.email,
          companyId: elem.butter_company_pid,
          billingAddress: elem.billing_address,
          shippingAddress: elem.shipping_address,
        })) ?? [];

      return { customerCount: parseFloat(count ?? "999"), customers };
    } catch (error) {
      return {
        error: {
          message: "An error occurred while querying customers: " + String(error),
        },
      };
    }
  },
});

const CUSTOMERS_BY_CUSTOMER_EMAIL_QUERY = gql`
  query CustomersByCustomerEmail(
    $companyId: ID!
    $customerEmail: String
    $isDesc: Boolean
    $orderBy: CustomerOrderBy!
    $offset: Int
    $maxLength: Int
  ) {
    company(company_pid: $companyId) {
      customer_count(search: { emails: $customerEmail })
      customers(
        search: { emails: $customerEmail }
        sort: { is_descending: $isDesc, order_by: $orderBy }
        offset: $offset
        max_length: $maxLength
      ) {
        id
        total_balance
        overdue_balance
        display_name
        phone
        email
      }
    }
  }
`;

export const queryCustomersByCustomerEmail = (
  companyId: string,
  customerEmail: string,
  isDesc: boolean,
  orderBy: string,
  offset: number,
  maxLength: number
): {
  queryKey: [string, string, string, boolean, string, number, number];
  queryFn: () => Promise<{ customers: Customer[] } | QueryError>;
} => ({
  queryKey: ["customers", companyId, customerEmail, isDesc, orderBy, offset, maxLength],
  queryFn: async (): Promise<{ customerCount: number; customers: Customer[] } | QueryError> => {
    const _orderBy =
      orderBy == "overdue"
        ? "OverdueBalance"
        : orderBy == "total"
          ? "TotalBalance"
          : orderBy == "display"
            ? "DisplayName"
            : "TotalBalance";

    try {
      const { company }: { company: CompanyAltCase } = await graphQLClient.request(CUSTOMERS_BY_CUSTOMER_EMAIL_QUERY, {
        companyId,
        customerEmail,
        isDesc,
        orderBy: _orderBy,
        maxLength,
        offset,
      });
      const { customer_count: count, customers: data } = company;
      const customers =
        data?.map((elem) => ({
          customerId: elem.id,
          customerName: elem.display_name,
          customerPhone: elem.phone,
          customerEmail: elem.email,
        })) ?? [];
      return { customerCount: parseFloat(count ?? "999"), customers };
    } catch (error) {
      return {
        error: {
          message: "An error occurred while querying customers: " + String(error),
        },
      };
    }
  },
});

const CUSTOMERS_BY_CUSTOMER_IDS_QUERY = gql`
  query CustomersByCustomerIds(
    $companyId: ID!
    $customerIds: String
    $isDesc: Boolean
    $orderBy: CustomerOrderBy!
    $offset: Int
    $maxLength: Int
  ) {
    company(company_pid: $companyId) {
      customer_count(search: { ids: $customerIds })
      customers(
        search: { ids: $customerIds }
        sort: { is_descending: $isDesc, order_by: $orderBy }
        offset: $offset
        max_length: $maxLength
      ) {
        id
        total_balance
        overdue_balance
        display_name
        phone
        email
      }
    }
  }
`;

export const queryCustomersByCustomerIds = (
  companyId: string,
  customerIds: string
): {
  queryKey: [string, string, string];
  queryFn: () => Promise<{ customers: Customer[] } | QueryError>;
} => ({
  queryKey: ["customers", companyId, customerIds],
  queryFn: async (): Promise<{ customerCount: number; customers: Customer[] } | QueryError> => {
    const isDesc = true;
    const orderBy = "DisplayName";
    const offset = 0;
    const maxLength = 999;

    try {
      const { company }: { company: CompanyAltCase } = await graphQLClient.request(CUSTOMERS_BY_CUSTOMER_IDS_QUERY, {
        companyId,
        customerIds,
        isDesc,
        orderBy,
        maxLength,
        offset,
      });

      const { customer_count: count, customers: data } = company ?? {};
      const customers =
        data?.map((elem) => ({
          customerId: elem.id,
          customerName: elem.display_name,
          customerPhone: elem.phone,
          customerEmail: elem.email,
        })) ?? [];

      return { customerCount: parseFloat(count ?? "100"), customers };
    } catch (error) {
      return {
        error: {
          message: "An error occurred while querying customers: " + String(error),
        },
      };
    }
  },
});

const CUSTOMER_BY_CUSTOMER_ID_QUERY = gql`
  query CustomerByCustomerId($companyId: ID!, $customerId: ID!) {
    company(company_pid: $companyId) {
      customer(customer_id: $customerId) {
        butter_company_pid
        display_name
        total_balance
        overdue_balance
        phone
        email
        billing_address {
          attn
          city
          line1
          line2
          postal_code
          state
        }
        shipping_address {
          attn
          city
          line1
          line2
          postal_code
          state
        }
      }
    }
  }
`;

export const queryCustomerByCustomerId = (
  companyId: string,
  customerId: string
): {
  queryKey: [string, string, string];
  queryFn: () => Promise<{ customer: Customer } | QueryError>;
} => ({
  queryKey: ["customer", companyId, customerId],
  queryFn: async (): Promise<{ customer: Customer } | QueryError> => {
    try {
      const { company }: { company: CompanyAltCase } = await graphQLClient.request(CUSTOMER_BY_CUSTOMER_ID_QUERY, {
        companyId,
        customerId,
      });
      const { customer: data } = company;
      const customer = {
        customerId,
        companyId: data.butter_company_pid,
        customerName: data.display_name,
        customerPhone: data.phone,
        customerEmail: data.email,
        billingAddress: data.billing_address,
        shippingAddress: data.shipping_address,
      };

      return { customer };
    } catch (error) {
      return {
        error: {
          message: "An error occurred while querying customer: " + String(error),
        },
      };
    }
  },
});
