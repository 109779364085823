import graphQLClient from "@/api/graphql-client";
import { CREATE_MANUAL_ORDER, CREATE_MESSAGE_MEDIA_URL } from "@/api/mutations/order";
import { Customer } from "@/utils/types";
import { getUserCompany } from "@/utils/user";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  Center,
  Divider,
  FormControl,
  FormLabel,
  Textarea,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  Icon,
  Box,
  Text,
  CircularProgress,
  HStack,
  Tooltip,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import _ from "lodash";
import { useEffect, useRef, useState, useCallback } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import SearchableDropdown from "@/components/layouts/searchable-dropdown";
import { useOrderContext } from "@/providers/order-provider";
import { useDropzone } from "react-dropzone";
import { AiFillFileAdd } from "react-icons/ai";
import uploadFile from "@/api/upload";
import { HiPlus } from "react-icons/hi";

export default function NewOrder() {
  const toast = useToast();
  const initialRef = useRef(null);
  const textRef = useRef(null);
  const finalRef = useRef(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useOrderContext();
  const [customer, setCustomer] = useState<Customer | null>(null); // customerId, customerName
  const [isLoading, setIsLoading] = useState(false);
  const [currMsg, setCurrMsg] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [lastShiftPress, setLastShiftPress] = useState(0);

  const [searchText, setSearchText] = useState("");
  const [isPossibleCustomer, setIsPossibleCustomer] = useState(false);
  const { customerId, customerName } = (customer as unknown as { customerId: string; customerName: string }) ?? {};
  const {
    company: { companyId: supplierId },
  } = getUserCompany();

  useEffect(() => {
    if (searchParams.get("m") == "order") {
      onOpen();
    }
  }, [searchParams]);
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        const now = Date.now();
        if (now - lastShiftPress < 300) {
          handleNewOrder();
        }
        setLastShiftPress(now);
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [lastShiftPress]);
  useHotkeys(
    "mod+enter",
    () => {
      if (!currMsg || isLoading || !customer) return;
      handleSubmit();
    },
    {
      preventDefault: true,
      enableOnFormTags: ["input", "textarea"],
    }
  );

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      if (!file.type.startsWith("audio/")) {
        console.log("Not an audio file");
        return;
      }
      setSelectedFile(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "audio/*": [],
    },
    maxFiles: 1,
    multiple: false,
  });

  // create manual order
  const mutateCreateManualOrder = useMutation<
    {
      ai: {
        create_ai_manual_order: string;
      };
    },
    unknown,
    { supplierId: string; customerId: string; messages: { content?: string; type: string; attachment_url?: string }[] }
  >(async ({ supplierId, customerId, messages }) => {
    return await graphQLClient.request(CREATE_MANUAL_ORDER, {
      supplierId,
      customerId,
      messages,
    });
  });

  // create message media url
  const mutateCreateMediaUrl = useMutation<
    {
      ai: {
        create_message_media_url: {
          upload_url: string;
          download_url: string;
        };
      };
    },
    unknown,
    { filename: string }
  >(async ({ filename }) => {
    return await graphQLClient.request(CREATE_MESSAGE_MEDIA_URL, {
      filename,
    });
  });

  async function handleSubmit() {
    try {
      setIsLoading(true);
      let orderId = "";
      if (tabIndex == 0) {
        const res = await mutateCreateManualOrder.mutateAsync({
          supplierId,
          customerId: customerId,
          messages: [
            {
              type: "Text",
              content: currMsg,
            },
          ],
        }); // only one message
        orderId = res?.ai.create_ai_manual_order;
      } else {
        const urlRes = await mutateCreateMediaUrl.mutateAsync({ filename: selectedFile!.name });
        const { upload_url, download_url } = urlRes?.ai.create_message_media_url;
        // upload media
        await uploadFile(selectedFile!, upload_url, (progress) => {
          console.log(progress);
          setProgress(Math.round(progress));
        });
        // create order
        const res = await mutateCreateManualOrder.mutateAsync({
          supplierId,
          customerId: customerId,
          messages: [
            {
              type: "AudioUrl",
              content: download_url,
            },
          ],
        }); // only one message
        orderId = res?.ai.create_ai_manual_order;
      }
      toast({
        title: "Order created!",
        description: `You have successfully created a new order for ${customerName}`,
        status: "success",
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => {},
      });
      handleCancel();
      navigate(`/orders/${orderId}/analyzer`);
    } catch (err) {
      console.log(err);
      toast({
        title: "Failed to create order",
        description: `There has been an error creating a new order for ${customerName}, try again later.`,
        status: "error",
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => {},
      });
    } finally {
      setIsLoading(false);
    }
  }

  const handleNewOrder = () => {
    onOpen();
    setCurrMsg("");
    searchParams.set("m", "order");
    setSearchParams(searchParams, { replace: true });
  };

  const handleCancel = () => {
    onClose();
    setCurrMsg("");
    setCustomer(null);
    setTabIndex(0);
    setProgress(0);
    setSelectedFile(null);
    searchParams.set("m", "");
    setSearchParams(searchParams, { replace: true });
    setSearchText("");
  };

  return (
    <>
      <Tooltip label="Keyboard shortcut: ⇧ ⇧" borderRadius="full" placement="top">
        <Button
          display="flex"
          onClick={onOpen}
          variant="solid"
          colorScheme="primary"
          bg="primary.400"
          borderRadius="full"
          size="sm"
          w="32"
          _focusVisible={{ boxShadow: "none" }}
        >
          <Icon as={HiPlus} mr="1" />
          New Order
        </Button>
      </Tooltip>
      <Modal
        isOpen={isOpen}
        onClose={handleCancel}
        size={{ base: "full", lg: "lg" }}
        initialFocusRef={initialRef}
        allowPinchZoom={false}
        finalFocusRef={finalRef}
        closeOnEsc={true}
      >
        <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
        <ModalContent>
          <ModalHeader>Create an Order</ModalHeader>
          <ModalCloseButton onClick={handleCancel} />
          <ModalBody>
            <VStack>
              <SearchableDropdown
                searchText={searchText}
                setSearchText={setSearchText}
                isPossibleCustomer={isPossibleCustomer}
                setIsPossibleCustomer={setIsPossibleCustomer}
                setCustomer={setCustomer}
                ref={initialRef}
                nextRef={textRef}
                placeholder="Search for a customer"
              />
              <Divider mt="4" />
              <Tabs
                size="md"
                variant="unstyled"
                onChange={(index) => {
                  // reset({ name: inputFullName });
                  setTabIndex(index);
                }}
                mt="4"
              >
                <TabList w="60" borderWidth="thin" borderRadius="md" borderColor="primary.500">
                  {["Text", "Voicemail"].map((option, index) => (
                    <Tab
                      key={index}
                      borderRightWidth={index == 0 ? "thin" : "0"}
                      borderRightColor="primary.500"
                      color="gray.600"
                      bgColor="white"
                      _hover={{ color: "gray.600", bgColor: "primary.50" }}
                      _selected={{ color: "white", bgColor: "primary.400" }}
                      fontSize="md"
                      borderLeftRadius={index == 0 ? "md" : "none"}
                      borderRightRadius={index == 1 ? "md" : "none"}
                      w="32"
                      fontWeight="medium"
                    >
                      {option}
                    </Tab>
                  ))}
                </TabList>
                <TabIndicator />
              </Tabs>
              {tabIndex == 0 ? (
                <FormControl mt="4" isRequired>
                  <FormLabel color="gray.600" ml="2" isTruncated maxW="20rem">
                    {customerName ? `A message from ${customerName}` : "Enter a message"}
                  </FormLabel>
                  <Center w="full">
                    <Textarea
                      value={currMsg}
                      onChange={(e) => setCurrMsg(e.target.value)}
                      placeholder="Enter an order message"
                      size="sm"
                      ref={textRef}
                      h="240"
                      w="full"
                    />
                  </Center>
                </FormControl>
              ) : (
                <FormControl mt="4" isRequired>
                  <FormLabel color="gray.600" ml="2" isTruncated maxW="20rem">
                    {customerName ? `An audio file from ${customerName}` : "Upload an audio file"}
                  </FormLabel>
                  <Center
                    py={10}
                    cursor="pointer"
                    bg={isDragActive ? "gray.50" : "transparent"}
                    _hover={{ bg: "gray.50" }}
                    transition="background-color 0.2s ease"
                    borderRadius={4}
                    border="1px dashed"
                    borderColor="gray.300"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <Box>
                      {selectedFile ? (
                        <VStack>
                          <HStack>
                            <Icon as={AiFillFileAdd} mr={2} w={6} h={6} color="primary.500" />
                            <Text>Selected File: {selectedFile.name}</Text>
                          </HStack>
                          {progress > 0 && (
                            <>
                              <Text mt={2} fontSize="md" color="primary.500">
                                Upload progress: {progress}%
                              </Text>
                              <CircularProgress value={progress} size="80px" color="primary.500" thickness="6px" />
                            </>
                          )}
                        </VStack>
                      ) : isDragActive ? (
                        <HStack>
                          <Icon as={AiFillFileAdd} mr={2} w={6} h={6} color="primary.500" />
                          <Text>Drop the file here ...</Text>
                        </HStack>
                      ) : (
                        <HStack mx="4">
                          <Icon as={AiFillFileAdd} mr={2} w={6} h={6} color="primary.500" />
                          <Text>Drag and drop the audio file here, or click to browse and select the file</Text>
                        </HStack>
                      )}
                    </Box>
                  </Center>
                </FormControl>
              )}
            </VStack>
          </ModalBody>

          <ModalFooter justifyContent="flex-end">
            <Button variant="outline" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              colorScheme="primary"
              ml="3"
              onClick={handleSubmit}
              isDisabled={tabIndex == 0 ? !currMsg : !selectedFile}
              isLoading={isLoading}
            >
              Submit Order
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
