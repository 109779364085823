import { ReactNode, useState, useEffect, Dispatch, SetStateAction } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  InputGroup,
  InputLeftElement,
  Icon,
  Input,
  Box,
  Radio,
  RadioGroup,
  VStack,
  Center,
  Spinner,
  Text,
  Heading,
  HStack,
  IconButton,
  Divider,
  Button,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { HiCheck, HiX } from "react-icons/hi";
import queryClient from "@/api/query-client";
import { queryCustomersByCompanyId } from "@/api/queries/customer";
import { Customer } from "@/utils/types";
import { getUserCompany } from "@/utils/user";
import { formatTitle } from "@/utils/formatter";

export function SearchInstant({
  filterCustomer,
  setFilterCustomer,
  onCustomerChange,
}: {
  filterCustomer: string | null;
  setFilterCustomer: Dispatch<SetStateAction<string | null>>;
  onCustomerChange: (val: string) => Promise<void>;
}): ReactNode {
  const [displayedCustomers, setDisplayedCustomers] = useState<Customer[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<null | string>(null);
  const [_, setSearchParams] = useSearchParams();
  const {
    company: { companyId },
  } = getUserCompany();

  const navigate = useNavigate();

  useEffect(() => {
    setFilterCustomer(null);
  }, []);

  useEffect(() => {
    if (!searchTerm) return;

    const timeoutId = setTimeout(() => {
      handleSearchChange(searchTerm);
    }, 1000); // 1s delay

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchTerm]);

  async function handleSearchChange(val: string): Promise<void> {
    setIsLoading(true);
    const fetchCustomers = queryCustomersByCompanyId(
      companyId,
      val,
      true, // isDesc
      "DisplayName", // orderBy
      0, // offset
      999 // maxLength
    );
    const { customers = [] }: { customers: Customer[] } = (await queryClient.fetchQuery(fetchCustomers)) ?? {};

    setDisplayedCustomers(customers);
    setIsLoading(false);
  }

  return (
    <Box px="4" w="80">
      <HStack justifyContent="space-between">
        <Heading fontSize="sm" fontWeight="semibold">
          Filter by customer
        </Heading>
        <IconButton
          variant="unstyled"
          icon={<Icon as={HiX} />}
          _focusVisible={{ outline: "none" }}
          aria-label="Close"
          size="xs"
        />
      </HStack>
      <HStack w="full" mt="2">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon as={FiSearch} color="gray.400" boxSize="3" mb="1" />
          </InputLeftElement>
          <Input
            type="search"
            name="q"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            value={searchTerm ?? ""}
            placeholder="Search a customer"
            aria-label="Search"
            autoComplete="off"
            borderRadius="lg"
            borderColor="gray.100"
            _focusVisible={{ outline: "none" }}
            size="sm"
          />
        </InputGroup>
        <Button
          onClick={() => {
            setFilterCustomer(null);
            onCustomerChange(",");
            setSearchTerm("");
          }}
          variant="outline"
          size="sm"
          h="8"
          borderColor="gray.50"
          borderRadius="lg"
          _hover={{ borderColor: "gray.200" }}
        >
          Clear
        </Button>
      </HStack>

      {isLoading ? (
        <Center py="2" h="8" mt="4">
          <Spinner boxSize="4" />
        </Center>
      ) : !searchTerm ? (
        <Center mx="2" my="4" h="8" w="full">
          <Text>Look up a customer by typing above</Text>
        </Center>
      ) : displayedCustomers?.length == 0 ? (
        <Center py="2" h="8" w="full">
          <Text>No customers have been found</Text>
        </Center>
      ) : (
        <RadioGroup
          as={VStack}
          defaultValue="1"
          onChange={(val) => {
            setFilterCustomer(val);
            onCustomerChange(val as string);
          }}
          w="full"
          zIndex="modal"
          divider={<Divider borderColor="gray.100" />}
          alignItems="start"
          h="60"
          overflowY="auto"
          my="2"
          pt="2"
          pr="4"
        >
          {displayedCustomers?.map(({ customerName, customerId }) => (
            <HStack key={customerId}>
              <Box w="8">
                {customerId == filterCustomer?.split(",")[1] && <Icon as={HiCheck} boxSize="3" mt="1" ml="1" />}
              </Box>
              <Radio
                value={`${customerName},${customerId}`}
                size="sm"
                _focusVisible={{ boxShadow: "none" }}
                bgColor="none"
                border="none"
                _checked={{ bgColor: "none", border: "none" }}
                ml="-8"
                h="6"
              >
                <Text
                  fontSize="xs"
                  fontWeight={customerId == filterCustomer?.split(",")[1] ? "semibold" : "normal"}
                  color={customerId == filterCustomer?.split(",")[1] ? "gray.800" : "gray.600"}
                  isTruncated
                  w="56"
                >
                  {formatTitle(customerName)}
                </Text>
              </Radio>
            </HStack>
          ))}
        </RadioGroup>
      )}
    </Box>
  );
}
