import { Navigate, useLocation, useNavigate, useRouteError } from "react-router-dom";
import { Heading, VStack, Text, Button, Center } from "@chakra-ui/react";
import { getUserCompany } from "@/utils/user";
import { ReactNode } from "react";

export default function ErrorPage(): ReactNode {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const error = useRouteError();

  if (String(error).split(":")[0] == "TypeError" && String(error).includes("undefined")) {
    return <Navigate to="/orders" replace />;
  } else if (String(error).includes("Network request failed")) {
    return <Navigate to={pathname} replace />;
  }

  try {
    getUserCompany();

    return (
      <Center mt="40" flexDir="column">
        <VStack spacing={4} mt={8}>
          <Heading>Try again later</Heading>
          <Text fontSize="lg">We have detected an incident and are currently working on it</Text>
        </VStack>
        <Button
          onClick={() => {
            navigate("..");
          }}
          variant="outline"
          borderRadius="lg"
          borderColor="gray.400"
          fontWeight="bold"
          w="sm"
          mt="8"
          h="10"
        >
          Go back
        </Button>
      </Center>
    );
  } catch (error) {
    // Handle user not logged in
    return <Navigate to="/login" replace />;
  }
}
