import { gql } from "graphql-request";
import graphQLClient from "@/api/graphql-client";
import { CompanyAltCase, QueryError } from "@/utils/types";

const AI_SETTINGS_BY_COMPANY_ID_QUERY = gql`
  query AISettingsByCompanyId($companyId: ID!) {
    company(company_pid: $companyId) {
      ai_settings {
        email_enabled
        voicemail_email
      }
    }
  }
`;

export const queryAISettingsByCompanyId = (
  companyId: string
): {
  queryKey: [string, string];
  queryFn: () => Promise<{ emailEnabled: Boolean; voicemailEmail: String } | QueryError>;
} => ({
  queryKey: ["aiSettings", companyId],
  queryFn: async (): Promise<{ emailEnabled: Boolean; voicemailEmail: String } | QueryError> => {
    try {
      const { company }: { company: CompanyAltCase } = await graphQLClient.request(AI_SETTINGS_BY_COMPANY_ID_QUERY, {
        companyId,
      });
      const emailEnabled = company?.ai_settings?.email_enabled || true;
      const voicemailEmail = company?.ai_settings?.voicemail_email || "";

      return { emailEnabled, voicemailEmail };
    } catch (error) {
      return {
        emailEnabled: true,
        voicemailEmail: "",
      };
    }
  },
});
