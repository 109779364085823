import { Center, Icon } from "@chakra-ui/react";
import { ReactNode } from "react";
import { HiArrowLeft } from "react-icons/hi";

export default function SelectView({ item }: { item: string }): ReactNode {
  return (
    <Center
      borderWidth="thin"
      borderColor="gray.200"
      mt="40vh"
      h="40"
      w={{ base: "60", lg: "sm" }}
      borderRadius="lg"
      mx="auto"
    >
      <Icon as={HiArrowLeft} boxSize="4" mr="4" />
      Select {item} to view
    </Center>
  );
}
