import { useSubscription as useGraphQLHooksSubscription } from "graphql-hooks";
import { UseSubscriptionOperation } from "graphql-hooks/lib/types/common-types";
import { getUserCompany } from "@/utils/user.ts";

export const gql = (chunks: TemplateStringsArray, ...variables: unknown[]): string =>
  chunks.reduce((acc, chunk, index) => `${acc}${chunk}${index in variables ? String(variables[index]) : ``}`, ``);

export const useSubscription = <ResponseData = never, Variables extends object = object, TGraphQLError = Error[]>(
  options: UseSubscriptionOperation<Variables>,
  callback: (response: { data?: ResponseData; errors?: TGraphQLError[] }) => void
) => {
  // If variables are not of complex type, an error will be raised："useDeepCompareEffect should not be used with dependencies that are all primitive
  // values. Use React.useEffect instead.".
  useGraphQLHooksSubscription({ ...options, variables: options.variables ?? {} }, callback);
};

export type CreatedOrder = {
  id: string;
  supplier_pid: string;
  customer_id: string;
  customer_name?: string;
  status: string;
  delivery_date?: string;
  created_ts: string;
  updated_ts: string;
};

export const useOrderCreated = (callback: ({ data, errors }: { data?: CreatedOrder; errors?: Error[] }) => void) => {
  useSubscription(
    {
      query: gql`
        subscription ($input: AIOrderCreatedInput!) {
          ai_order_created(input: $input) {
            id
            supplier_pid
            customer_id
            customer_name
            status
            delivery_date
            created_ts
            updated_ts
          }
        }
      `,
      variables: {
        input: {
          company_pid: getUserCompany().company.companyId,
        },
      },
    },
    ({ data, errors }: { data?: { ai_order_created: CreatedOrder }; errors?: Error[] }) => {
      callback({ data: data?.ai_order_created, errors });
    }
  );
};

export type UpdatedOrder = { id: string; status: string; error_message?: string };

export const useOrderUpdated = (callback: ({ data, errors }: { data?: UpdatedOrder; errors?: Error[] }) => void) => {
  useSubscription(
    {
      query: gql`
        subscription ($input: AIOrderUpdatedInput!) {
          ai_order_updated(input: $input) {
            id
            status
            error_message
          }
        }
      `,
      variables: {
        input: {
          company_pid: getUserCompany().company.companyId,
        },
      },
    },
    ({ data, errors }: { data?: { ai_order_updated: UpdatedOrder }; errors?: Error[] }) => {
      callback({ data: data?.ai_order_updated, errors });
    }
  );
};

export type CreatedConversation = {
  id: string;
  name: string;
  status: string;
  created_ts: string;
  updated_ts: string;
  customer_id: string;
  customer_name: string;
};

export const useConversationCreated = (
  callback: ({ data, errors }: { data?: CreatedConversation; errors?: Error[] }) => void
) => {
  useSubscription(
    {
      query: gql`
        subscription ($input: AIConversationCreatedInput!) {
          ai_conversation_created(input: $input) {
            id
            name
            created_ts
            updated_ts
            customer_id
            status
          }
        }
      `,
      variables: {
        input: {
          company_pid: getUserCompany().company.companyId,
        },
      },
    },
    ({ data, errors }: { data?: { ai_conversation_created: CreatedConversation }; errors?: Error[] }) => {
      callback({ data: data?.ai_conversation_created, errors });
    }
  );
};

export type CreatedMessage = { id: string };

export const useMessageCreated = (
  order_id: number,
  callback: ({ data, errors }: { data?: CreatedMessage; errors?: Error[] }) => void
) => {
  useSubscription(
    {
      query: gql`
        subscription ($input: AIMessageCreatedInput!) {
          ai_message_created(input: $input) {
            id
          }
        }
      `,
      variables: {
        input: {
          company_pid: getUserCompany().company.companyId,
          order_id,
        },
      },
    },
    ({ data, errors }: { data?: { ai_message_created: CreatedMessage }; errors?: Error[] }) => {
      callback({ data: data?.ai_message_created, errors });
    }
  );
};
