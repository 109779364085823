import {
  HStack,
  Icon,
  Img,
  Modal,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { GoShare } from "react-icons/go";

const InstallPrompt = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
      <ModalOverlay />
      <ModalContent py={8}>
        <ModalCloseButton />
        <ModalBody py={4}>
          <VStack>
            <Img src="/icon.png" h={20} w={20} mb={4} />
            <Text>Add the GrubMarket AI Orders App to your Home Screen for easy access</Text>
            <HStack>
              <Text fontSize="lg">1. Tap</Text>
              <Icon as={GoShare} boxSize={5} />
              <Text fontSize="lg"> below</Text>
            </HStack>
            <Text fontSize="lg">2. Select “Add to Home Screen”</Text>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InstallPrompt;
