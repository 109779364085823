import { gql } from "graphql-request";

export const CREATE_CONVERSATION = gql`
  mutation CreateConversation(
    $customerId: String = ""
    $supplierCompanyPid: String = ""
    $buyerParticipants: [AI_CreateParticipantPayload_Input]
  ) {
    ai {
      create_ai_conversation(
        input: { supplier_pid: $supplierCompanyPid, buyer_participants: $buyerParticipants, customer_id: $customerId }
      ) {
        created_ts
        id
        sid
        status
        supplier_pid
        updated_ts
      }
    }
  }
`;
