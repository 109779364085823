import { gql } from "graphql-request";
import graphQLClient from "@/api/graphql-client";
import type { CompanyAltCase, Conversation, QueryError } from "@/utils/types";

const ALL_CONVERSATIONS_BY_COMPANY_ID_QUERY = gql`
  query AllConversationsByCompanyId($companyId: ID = "", $offset: Int, $maxLength: Int) {
    company(company_pid: $companyId) {
      ai_conversations(max_length: $maxLength, offset: $offset) {
        id
        status
        created_ts
        customer_id
        customer_name
        supplier_pid
        updated_ts
        participants {
          channel_type
          conversation_id
          created_ts
          email
          id
          phone_number
          type
          user_id
        }
      }
    }
  }
`;

export const queryAllConversationsByCompanyId = (
  companyId: string,
  offset: number,
  maxLength: number
): {
  queryKey: [string, string, number, number];
  queryFn: () => Promise<{ conversations: Conversation[] } | QueryError>;
} => ({
  queryKey: ["allConversations", companyId, offset, maxLength],
  queryFn: async (): Promise<{ conversations: Conversation[] } | QueryError> => {
    try {
      const { company }: { company: CompanyAltCase } =
        (await graphQLClient.request(ALL_CONVERSATIONS_BY_COMPANY_ID_QUERY, {
          companyId,
          offset,
          maxLength,
        })) ?? {};
      const data = company?.ai_conversations ?? [];

      const conversations =
        data?.map((elem) => ({
          conversationId: elem.id,
          conversationStatus: elem.status,
          customerId: elem.customer_id,
          customerName: elem.customer_name,
          createdAt: elem.created_ts,
          updatedAt: elem.updated_ts,
          participants: elem?.participants?.map((elem) => ({
            channelType: elem?.channel_type,
            conversationId: elem.conversation_id,
            createdAt: elem?.created_ts,
            emailAddress: elem?.email,
            phoneNumber: elem?.phone_number,
            participantId: elem?.id,
            displayName: elem?.name,
            type: elem?.type,
            userId: elem?.user_id,
            user: {
              userId: elem.user?.pid,
              userEmail: elem.user?.email,
              userPhone: elem.user?.phone_number,
              userName: elem.user?.name,
              companies: elem.user?.butter_companies,
              userRole: elem.user?.role,
              userTitle: elem.user?.title,
            },
          })),
        })) ?? [];

      return { conversations };
    } catch (error) {
      return {
        error: {
          message: "An error occurred while querying conversations: " + String(error),
        },
      };
    }
  },
});

const CONVERSATION_BY_CONVERSATION_ID_QUERY = gql`
  query ConversationByConversationId($companyId: ID = "", $conversationId: Int!) {
    company(company_pid: $companyId) {
      ai_conversation(conversation_id: $conversationId) {
        participants {
          channel_type
          type
          user_id
          phone_number
          email
          id
        }
        created_ts
        customer_id
        id
        supplier_pid
        updated_ts
        status
      }
    }
  }
`;

export const queryConversationByConversationId = (
  companyId: string,
  conversationId: string
): {
  queryKey: [string, string];
  queryFn: () => Promise<{ conversation: Conversation } | QueryError>;
} => ({
  queryKey: ["conversation", conversationId],
  queryFn: async (): Promise<{ conversation: Conversation } | QueryError> => {
    const _conversationId = parseInt(conversationId);

    try {
      const { company }: { company: CompanyAltCase } =
        (await graphQLClient.request(CONVERSATION_BY_CONVERSATION_ID_QUERY, {
          companyId,
          conversationId: _conversationId,
        })) ?? {};
      const data = company?.ai_conversation ?? {};

      const conversation = {
        conversationId: data.id,
        conversationStatus: data.status,
        customerId: data.customer_id,
        customerName: data.customer_name,
        createdAt: data.created_ts,
        updatedAt: data.updated_ts,
        participants: data?.participants?.map((elem) => ({
          channelType: elem?.channel_type,
          conversationId: elem.conversation_id,
          createdAt: elem?.created_ts,
          emailAddress: elem?.email,
          phoneNumber: elem?.phone_number,
          participantId: elem?.id,
          displayName: elem?.name,
          type: elem?.type,
          userId: elem?.user_id,
          user: {
            userId: elem.user?.pid,
            userEmail: elem.user?.email,
            userPhone: elem.user?.phone_number,
            userName: elem.user?.name,
            companies: elem.user?.butter_companies,
            userRole: elem.user?.role,
            userTitle: elem.user?.title,
          },
        })),
      };
      return { conversation };
    } catch (error) {
      return {
        error: {
          message: "An error occurred while querying a conversation: " + String(error),
        },
      };
    }
  },
});

const CONVERSATION_MESSAGES_BY_CONVERSATION_ID_QUERY = gql`
  query ConversationMessagesByConversationId(
    $companyId: ID = ""
    $conversationId: Int!
    $offset: Int!
    $maxLength: Int!
  ) {
    company(company_pid: $companyId) {
      ai_conversation(conversation_id: $conversationId, messages_max_length: $maxLength, messages_offset: $offset) {
        participants {
          name
          type
          user_id
          phone_number
          email
          id
        }
        messages {
          author
          body
          created_ts
          id
          index
          status
          type
        }
        created_ts
        customer_id
        id
        supplier_pid
        updated_ts
        status
      }
    }
  }
`;

export const queryConversationMessagesByConversationId = (
  companyId: string,
  conversationId: string,
  offset: number,
  maxLength: number
): {
  queryKey: [string, string, number, number];
  queryFn: () => Promise<{ conversationWithMessages: Conversation } | QueryError>;
} => ({
  queryKey: ["conversation", conversationId, offset, maxLength],
  queryFn: async (): Promise<{ conversationWithMessages: Conversation } | QueryError> => {
    const _conversationId = parseInt(conversationId);

    try {
      const { company }: { company: CompanyAltCase } =
        (await graphQLClient.request(CONVERSATION_MESSAGES_BY_CONVERSATION_ID_QUERY, {
          companyId,
          conversationId: _conversationId,
          maxLength,
          offset,
        })) ?? {};
      const data = company?.ai_conversation ?? {};

      const conversationWithMessages = {
        conversationId: data.id,
        conversationStatus: data.status,
        customerId: data.customer_id,
        customerName: data.customer_name,
        createdAt: data.created_ts,
        updatedAt: data.updated_ts,
        participants: data?.participants?.map((elem) => ({
          channelType: elem?.channel_type,
          conversationId: elem.conversation_id,
          createdAt: elem?.created_ts,
          emailAddress: elem?.email,
          phoneNumber: elem?.phone_number,
          participantId: elem?.id,
          displayName: elem?.name,
          type: elem?.type,
          userId: elem?.user_id,
          user: {
            userId: elem.user?.pid,
            userEmail: elem.user?.email,
            userPhone: elem.user?.phone_number,
            userName: elem.user?.name,
            companies: elem.user?.butter_companies,
            userRole: elem.user?.role,
            userTitle: elem.user?.title,
          },
        })),
        messages: data?.messages?.map((elem) => ({
          author: elem.author,
          body: elem.body,
          createdTs: elem.created_ts,
          messageId: elem.id,
          index: elem.index,
          messageStatus: elem.status,
          messageType: elem.type,
        })),
      };
      return { conversationWithMessages };
    } catch (error) {
      return {
        error: {
          message: "An error occurred while querying a conversation messages: " + String(error),
        },
      };
    }
  },
});
