import { createContext, useContext, useReducer, Dispatch, ReactNode } from "react";

interface State {
  playingTrack: string | null;
}

interface PlayAction {
  type: "PLAY";
  trackUrl: string;
}

interface PauseAction {
  type: "PAUSE";
}

type Action = PlayAction | PauseAction;

const AudioContext = createContext<[State, Dispatch<Action>] | undefined>(undefined);
const initialState: State = { playingTrack: null };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "PLAY":
      return { ...state, playingTrack: action.trackUrl };
    case "PAUSE":
      return { ...state, playingTrack: null };
    default:
      throw new Error();
  }
}

export const useAudioPlayer = () => {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error("useAudioPlayer must be used within a AudioProvider");
  }
  return context;
};

export default function AudioProvider({ children }: { children: ReactNode }) {
  const contextValue = useReducer(reducer, initialState);

  return <AudioContext.Provider value={contextValue}>{children}</AudioContext.Provider>;
}
