import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getActiveCompanyPid, getCookie, removeCookie } from "@butter-technologies/dish";

const redirectUrlKEY = "mobile_view_redirect_url";

export default function IndexPage() {
  const navigate = useNavigate();
  const activeCompanyPid = getActiveCompanyPid();
  let timer: NodeJS.Timeout;
  useEffect(() => {
    const redirectUrl = getCookie(redirectUrlKEY);
    timer = setTimeout(() => {
      if (redirectUrl) {
        navigate(redirectUrl, { replace: true });
        removeCookie(redirectUrlKEY);
      } else {
        navigate("/orders", { replace: true });
      }
    }, 1);
    return () => {
      clearTimeout(timer);
    };
  }, [activeCompanyPid]);
  return null;
}
