import { useState, useMemo, useEffect, ReactNode } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Stack,
  InputGroup,
  Text,
  ButtonGroup,
  MenuOptionGroup,
  MenuItemOption,
  useToast,
  InputLeftElement,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  Box,
  Alert,
  AlertDescription,
  AlertTitle,
  AlertIcon,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@saas-ui/react";
import { useMutation } from "@tanstack/react-query";
import type { Customer, MutationError, UserAltCase } from "@/utils/types";
import graphQLClient from "@/api/graphql-client";
import { isValidEmail, isValidPhoneNumber, formatPhoneNumber } from "@/utils/validation";
import { getUserCompany } from "@/utils/user";
import { HiExclamation, HiChevronDown, HiChevronUp, HiCheckCircle } from "react-icons/hi";
import { CREATE_CONVERSATION } from "@/api/mutations/conversation";
import { ADD_CONVERSATION_PARTICIPANT } from "@/api/mutations/participant";
import InputMask from "react-input-mask";

export default function InviteUser({
  customer,
  isAdditionalParticipant,
  conversationId,
  prevStep,
  nextStep,
}: {
  customer: Customer;
  isAdditionalParticipant: boolean;
  conversationId?: string;
  prevStep: () => void;
  nextStep?: () => void;
}): ReactNode {
  const toast = useToast();
  const { isOpen, onClose } = useDisclosure();
  const [users, setUsers] = useState<UserAltCase[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserAltCase | null>(null);
  const [isSendSuccess, setIsSendSuccess] = useState(false);
  const [asyncErrors, setAsyncErrors] = useState<string | null>(null);
  const [tabIndex, setTabIndex] = useState(0);
  const {
    company: { companyId: sellerId },
  } = getUserCompany();
  const { register, formState, reset, handleSubmit, getValues, watch } = useForm({});
  const { name: inputFullName, phone: inputPhoneNumber, email: inputEmail } = getValues();
  const { errors, isSubmitting, isValid } = formState;

  const watchName = watch("name");
  const watchEmail = watch("email");
  const watchPhone = watch("phone");
  const isFormComplete = useMemo(() => {
    if (watchName && (watchEmail || watchPhone)) return true;
    return false;
  }, [watchName, watchPhone, watchEmail]);
  const isDisabled = Object.keys(errors)?.length > 0 || !isFormComplete || asyncErrors != null;
  const [channelType, setChannelType] = useState<string>("Voicemail");

  useEffect(() => {
    const defaultValues = {
      name: "",
      phone: "",
      email: "",
    };
    reset(defaultValues);
    if (!isOpen) {
      setSelectedUser(null);
      setIsSendSuccess(false);
      setAsyncErrors(null);
    }
  }, [customer, reset, isOpen]);

  const onUserSelect = (pid: string | string[]): void => {
    const user = users.find((item) => item.pid === pid);
    if (user) {
      setSelectedUser(user);
      reset({
        name: user.name ?? "",
        phone: user.phone_number?.startsWith("+1") ? user.phone_number.slice(2) : user.phone_number ?? "",
        email: user.email ?? "",
      });
    }
  };

  function handleMutationError(e: MutationError) {
    toast({
      title: "Unable to initiate a conversation",
      description:
        e.response?.errors
          ?.map((e) => e.message)
          .join(", ")
          .slice(0, -1) ??
        "Please check if you have a valid email or phone number, or if there is already a conversation in progress.",
      status: "error",
      duration: 5000,
      isClosable: true,
      onCloseComplete: () => {
        setAsyncErrors(null);
      },
    });
    setAsyncErrors(
      // @ts-expect-error
      e?.response?.errors
        ?.map((e) => e.message)
        .join(", ")
        .slice(0, -1)
    );
  }

  const mutateCreateConversation = useMutation<
    unknown,
    MutationError,
    {
      supplierCompanyPid: string;
      customerId: string;
      buyerUserPhoneNumber: string;
      buyerUserEmail: string;
      buyerUserName: string;
      channelType: string;
    }
  >(
    async (input: {
      supplierCompanyPid: string;
      customerId: string;
      buyerUserPhoneNumber: string;
      buyerUserEmail: string;
      buyerUserName: string;
      channelType: string;
    }) => {
      return await graphQLClient.request(CREATE_CONVERSATION, {
        customerId: input.customerId,
        supplierCompanyPid: input.supplierCompanyPid,
        buyerParticipants: [
          {
            phone_number: input.buyerUserPhoneNumber,
            email: input.buyerUserEmail,
            name: input.buyerUserName,
            channel_type: input.channelType,
          },
        ],
      });
    },
    {
      onSuccess: () => {},
      onError: handleMutationError,
    }
  );

  // add participant mutation
  const mutateAddParticipant = useMutation<
    unknown,
    MutationError,
    {
      conversationId: string;
      participantPhoneNumber: string;
      participantEmail: string;
      participantName: string;
      participantType: string;
      participantChannelType: string;
    }
  >(
    async (input) => {
      return await graphQLClient.request(ADD_CONVERSATION_PARTICIPANT, {
        conversationId: input.conversationId,
        participant: {
          email: input.participantEmail,
          name: input.participantName,
          type: input.participantType,
          phone_number: input.participantPhoneNumber,
          channel_type: input.participantChannelType,
        },
      });
    },
    {
      onSuccess: () => {},
      onError: handleMutationError,
    }
  );

  // create conversation
  const initiateConversation = async () => {
    const conversationParams = {
      supplierCompanyPid: sellerId,
      customerId: customer.customerId,
      buyerUserPhoneNumber: formatPhoneNumber(inputPhoneNumber),
      buyerUserEmail: inputEmail,
      buyerUserName: inputFullName,
      channelType: channelType,
    };
    const resultConversation = await mutateCreateConversation.mutateAsync(conversationParams);

    //@ts-expect-error
    if (resultConversation?.errors) {
      toast({
        title: `Unable to initiate a conversation`,
        //@ts-expect-error
        description: resultConversation?.errors,
        status: "error",
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => {
          setAsyncErrors(null);
        },
      });
      return;
      //@ts-expect-error
    } else if (!resultConversation?.ai?.create_ai_conversation) {
      toast({
        title: `Unable to initiate a conversation`,
        description:
          "Please check if you have a valid email or phone number, or if there is already a conversation in progress.",
        status: "error",
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => {
          setAsyncErrors(null);
        },
      });
      return;
    }
    toast({
      title: `You have started a group conversation with GrubMarket and ${inputFullName}`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onClose();

    setIsSendSuccess(true);
  };

  // add participant
  const addParticipant = async (conversationId: string) => {
    const resultParticipant = await mutateAddParticipant.mutateAsync({
      conversationId,
      participantPhoneNumber: formatPhoneNumber(inputPhoneNumber),
      participantEmail: inputEmail,
      participantName: inputFullName,
      participantType: "BUYER",
      participantChannelType: channelType,
    });

    //@ts-expect-error
    if (resultParticipant?.errors) {
      toast({
        title: `Unable to initiate a conversation`,
        //@ts-expect-error
        description: resultParticipant?.errors,
        status: "error",
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => {
          setAsyncErrors(null);
        },
      });
      return;
      //@ts-expect-error
    } else if (!resultParticipant?.ai?.add_ai_conversation_participant) {
      toast({
        title: `Unable to initiate a conversation`,
        description:
          "Please check if you have a valid email or phone number, or if there is already a conversation in progress.",
        status: "error",
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => {
          setAsyncErrors(null);
        },
      });
      return;
    }
    toast({
      title: `You have added ${inputFullName} to this conversation!`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });

    setIsSendSuccess(true);
  };

  const handleSend = async (): Promise<void> => {
    // add a participant or start a conversation
    if (isAdditionalParticipant && conversationId) {
      await addParticipant(conversationId);
    } else {
      await initiateConversation();
    }
  };

  return (
    <Box maxW="2xl">
      <Box mt="4">
        {isSendSuccess ? (
          <Alert colorScheme="green" flexDir="column" py="4" mb="4">
            <HStack spacing="0">
              <AlertIcon as={HiCheckCircle} />
              <AlertTitle fontSize="lg">You invited {customer?.customerName}!</AlertTitle>
            </HStack>
            <AlertDescription mx="8" mt="4">
              We’ve initiated a chat group with{" "}
              <Text fontWeight="semibold" display="inline">
                {inputFullName}
              </Text>
              's {tabIndex == 0 ? "phone number" : "email address"}{" "}
              <Text fontWeight="semibold" display="inline">
                {inputPhoneNumber || inputEmail}
              </Text>
              . They can start placing orders via {tabIndex == 0 ? "text messages" : "emails"}, and we’ll take care of
              the processing seamlessly.
            </AlertDescription>
          </Alert>
        ) : (
          <>
            <Stack direction="row" mb="4" spacing="8">
              <Alert
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                borderRadius="lg"
                colorScheme="primary"
                py="4"
              >
                <AlertTitle fontSize="lg">A Group Conversation for Orders</AlertTitle>
                <AlertDescription maxWidth="md" mt="2" lineHeight="revert">
                  {isAdditionalParticipant
                    ? `
                      By clicking the ‘Add a Participant’ button, you will add the participant to the customer chat via text message or email. This chat will include you, the participant (using the phone number or email address you provide below), any existing participants, and our AI system.
                    `
                    : `
                      By clicking the ‘Start a Conversation’ button, you will initiate a group conversation via text message or email. This conversation will include you, your customer (using the phone number or email address you provide below), and our AI system.
                    `}
                </AlertDescription>
              </Alert>
            </Stack>
            <Stack direction="row" mt="6" spacing="8" alignItems="end">
              {users?.length > 0 && (
                <Menu>
                  {({ isOpen: isUndepositMenuOpen }) => (
                    <>
                      <MenuButton
                        mb="2"
                        h="9"
                        as={Button}
                        variant="outline"
                        isActive={isUndepositMenuOpen}
                        rightIcon={<Icon as={isUndepositMenuOpen ? HiChevronUp : HiChevronDown} boxSize="4" />}
                      >
                        {selectedUser?.name ?? "Select a user"}
                      </MenuButton>
                      <MenuList>
                        <MenuOptionGroup
                          type="radio"
                          onChange={onUserSelect}
                          value={selectedUser?.pid ?? ""}
                          defaultValue={selectedUser?.pid ?? ""}
                          title={selectedUser != null ? "Select a user" : undefined}
                        >
                          {users?.map((user) => (
                            <MenuItemOption value={user.pid} key={user.pid}>
                              {user.name}
                            </MenuItemOption>
                          ))}
                        </MenuOptionGroup>
                      </MenuList>
                    </>
                  )}
                </Menu>
              )}
              <FormControl id="name" mb="2" flex="1">
                <FormLabel fontSize={{ base: "2xs", md: "xs" }} color="gray.600" pl="2">
                  Full Name*
                </FormLabel>
                <Input type="name" {...register("name", { required: "Please enter a name" })} isRequired />
                {errors?.name && (
                  <Text color="orange.300" fontSize="16px">
                    {typeof errors.name.message === "string" ? errors.name.message : "Error"}
                  </Text>
                )}
              </FormControl>
            </Stack>
            <Stack direction="row" mt="4" spacing="8">
              <Stack spacing="2">
                <Text w="48" fontSize={{ base: "2xs", md: "xs" }} color="gray.600" pl="2">
                  Choose a channel type:
                </Text>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<Icon as={ChevronDownIcon} boxSize="4" />}
                    size="sm"
                    variant="outline"
                    h="9"
                    w="full"
                  >
                    {channelType ?? "Select a channel type"}
                  </MenuButton>
                  <MenuList>
                    <MenuOptionGroup
                      type="radio"
                      onChange={(value) => {
                        setChannelType(value as string);
                      }}
                      value={channelType}
                      defaultValue={channelType}
                    >
                      <MenuItemOption as="a" value="Voicemail">
                        Voicemail
                      </MenuItemOption>
                      <MenuItemOption value="Email">Email</MenuItemOption>
                      <MenuItemOption value="SMS">SMS</MenuItemOption>
                      <MenuItemOption value="GrubAssist">GrubAssist</MenuItemOption>
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </Stack>
              {channelType === "SMS" || channelType == "Voicemail" ? (
                <FormControl id="phone" mb="2">
                  <FormLabel fontSize={{ base: "2xs", md: "xs" }} color="gray.600" pl="2">
                    Phone Number
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none" color="gray.600" fontSize="sm" children="+1" />
                    <Input
                      as={InputMask}
                      mask="(999) 999-9999"
                      maskPlaceholder={null}
                      type="tel"
                      {...register("phone", {
                        validate: (v: string) =>
                          v === "" || isValidPhoneNumber(v) ? true : "Please enter a valid phone number",
                      })}
                    />
                  </InputGroup>
                  {errors.phone && (
                    <Text color="orange.400" fontSize="sm" mt="4">
                      {typeof errors.phone.message === "string" ? errors.phone.message : "Error"}
                    </Text>
                  )}
                </FormControl>
              ) : (
                <FormControl id="email" mb="2">
                  <FormLabel fontSize={{ base: "2xs", md: "xs" }} color="gray.600" pl="2">
                    Email Address
                  </FormLabel>
                  <Input
                    type="email"
                    pattern=".+@globex\.com"
                    {...register("email", {
                      validate: (v: string) => (v === "" || isValidEmail(v) ? true : "Please enter a valid email"),
                    })}
                  />
                  {errors.email && (
                    <Text color="orange.400" fontSize="sm" mt="4">
                      {typeof errors.email.message === "string" ? errors.email.message : "Error"}
                    </Text>
                  )}
                </FormControl>
              )}
            </Stack>
            {asyncErrors && (
              <HStack alignItems="center" justifyContent="center" mt="4">
                <Icon as={HiExclamation} color="orange.400" mt="1" />
                <Text color="orange.400" fontSize="sm">
                  {asyncErrors}
                </Text>
              </HStack>
            )}
          </>
        )}
        {watchPhone === "" && watchEmail === "" && !isSendSuccess && (
          <Text fontSize="sm" mt="2" mb="4" color="primary.400" ml="1">
            * A phone number or an email address is required to {isAdditionalParticipant ? "join" : "start"} a
            conversation
          </Text>
        )}
      </Box>

      {!isSendSuccess && (
        <ButtonGroup justifyContent="end" my="4" w="full">
          <Button aria-label="Cancel" variant="outline" size="sm" w="16" onClick={() => prevStep()}>
            Back
          </Button>
          <Button
            size="sm"
            ml="2"
            w="36"
            colorScheme="primary"
            isDisabled={isDisabled}
            onClick={handleSubmit(handleSend)}
            isLoading={formState.isSubmitting && asyncErrors !== null}
          >
            {isAdditionalParticipant ? "Add a Participant" : "Start a Conversation"}
          </Button>
        </ButtonGroup>
      )}
    </Box>
  );
}
