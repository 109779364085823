import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spacer,
  Text,
  Spinner,
  VStack,
  Divider,
  Stack,
  IconButton,
  useDisclosure,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { HiChevronDown } from "react-icons/hi";
import { useOutlet, useSearchParams, useLocation, useParams, useNavigate } from "react-router-dom";
import OrderItem from "@/components/items/order-item";
import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import { SearchInstant } from "@/components/layouts/search-instant";
import { formatTitle } from "@/utils/formatter";
import { getUserCompany } from "@/utils/user";
import { queryAllOrdersByCompanyId, queryOrderCountByStatus } from "@/api/queries/order-list";
import queryClient from "@/api/query-client";
import { Order, type OrderDetail, Message } from "@/utils/types";
import { CreatedOrder, UpdatedOrder, useOrderCreated, useOrderUpdated } from "@/hooks/subscription.ts";
import { queryOrderDetailByOrderId } from "@/api/queries/order-detail.ts";
import { checkSubscriptionError } from "@/utils/subscription.ts";
import { useInView } from "react-intersection-observer";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import SidebarContext from "@/providers/sidebar-context";
import SimpleBar from "simplebar-react";
import NewOrder from "@/components/items/new-order";
import { useHotkeys } from "react-hotkeys-hook";

const INITIAL_PAGE_LIMIT = 20;
const PAGE_LIMIT = 20;

// params: p (offset), q (searchText), c (customerId), s (orderStatus)
async function fetchOrders({
  orderStatus,
  searchText,
  customerId,
  offset,
  maxLength,
}: {
  orderStatus: string;
  searchText: string;
  customerId: string;
  offset: number;
  maxLength: number;
}) {
  const {
    company: { companyId },
  } = getUserCompany();

  const fetchOrders = queryAllOrdersByCompanyId(
    companyId,
    orderStatus,
    searchText,
    customerId,
    offset,
    maxLength,
    false
  );
  const { hasMore, orders = [] }: { hasMore: boolean; orders: Order[] } =
    queryClient.getQueryData(fetchOrders.queryKey) ?? (await queryClient.fetchQuery(fetchOrders)) ?? {};
  return { hasMore, orders };
}

async function fetchOrderCounts(): Promise<{
  initializedCount: string;
  needsReviewCount: string;
  confirmedCount: string;
}> {
  const {
    company: { companyId },
  } = getUserCompany();

  const fetchInitializedOrderCount = queryOrderCountByStatus(companyId, "Initialized");
  const fetchNeedsReviewOrderCount = queryOrderCountByStatus(companyId, "NeedsReview");
  const fetchConfirmedOrderCount = queryOrderCountByStatus(companyId, "Confirmed");

  const { count: initializedCount }: { count: string } =
    queryClient.getQueryData(fetchInitializedOrderCount.queryKey) ??
    (await queryClient.fetchQuery(fetchInitializedOrderCount)) ??
    {};

  const { count: needsReviewCount }: { count: string } =
    queryClient.getQueryData(fetchNeedsReviewOrderCount.queryKey) ??
    (await queryClient.fetchQuery(fetchNeedsReviewOrderCount)) ??
    {};
  const { count: confirmedCount }: { count: string } =
    queryClient.getQueryData(fetchConfirmedOrderCount.queryKey) ??
    (await queryClient.fetchQuery(fetchConfirmedOrderCount)) ??
    {};

  return { initializedCount, needsReviewCount, confirmedCount };
}

export async function loader() {
  const { initializedCount, needsReviewCount, confirmedCount } = await fetchOrderCounts();
  return {
    initializedCount,
    needsReviewCount,
    confirmedCount,
  };
}

const convertMessagesFromModelToView = (messages?: Message[]) =>
  messages?.map((message) => ({ body: message.body, jobId: message.jobId })) ?? [];

const convertOrderWithMessagesFromSchemaToView = (data: CreatedOrder, messages: Message[]): Order => {
  const viewMessages = convertMessagesFromModelToView(messages);
  return {
    id: data.id,
    createdAt: data.created_ts,
    customerId: data.customer_id,
    customerName: data.customer_name ?? "",
    deliveryDate: data.delivery_date ?? "",
    updatedAt: data.updated_ts,
    orderStatus: data.status,
    messages: viewMessages,
    firstMessage: viewMessages.find((elem) => elem.jobId)?.body ?? viewMessages[0]?.body ?? "",
  };
};

async function getOrderDetail(orderId: string, invalidateCache: boolean = false) {
  const {
    order: orderDetail,
  }: {
    order: OrderDetail;
  } = (await queryClient.fetchQuery(queryOrderDetailByOrderId(getUserCompany().company.companyId, orderId))) ?? {};
  return orderDetail;
}

type OrdersData = InfiniteData<{
  orders: Order[];
}>;
type OptionalOrdersData = undefined | OrdersData;
type OrdersDataState = ((prevState: OptionalOrdersData) => OptionalOrdersData) | OptionalOrdersData;

const handleOrderCreated =
  (setData: (value: OrdersDataState) => void) =>
  async ({ data: createdOrder, errors }: { data?: CreatedOrder; errors?: Error[] }) => {
    if (checkSubscriptionError(errors)) {
      return;
    }
    if (!createdOrder) {
      return;
    }
    let orderIndex = -1;
    const orderDetail = await getOrderDetail(createdOrder.id, false);
    setData((prevData) => {
      let pageIndex =
        prevData?.pages.findIndex((page) => {
          orderIndex = page.orders?.findIndex((order) => parseInt(order.id) >= parseInt(createdOrder.id)) ?? -1;
          return orderIndex >= 0;
        }) ?? -1;
      // the new order exits, ignore. maybe current page created this order.
      if (pageIndex >= 0 && orderIndex >= 0 && prevData?.pages[pageIndex].orders[orderIndex].id == createdOrder.id) {
        return prevData;
      }
      if (pageIndex < 0) {
        pageIndex = 0;
      }
      if (orderIndex < 0) {
        orderIndex = 0;
      }
      const page = prevData?.pages[pageIndex];
      const orders = page?.orders ?? [];
      const newOrder = convertOrderWithMessagesFromSchemaToView(createdOrder, orderDetail?.messages ?? []);
      return prevData
        ? {
            ...(prevData ?? { pages: [], pageParams: [] }),
            pages: [
              ...(prevData?.pages?.slice(0, pageIndex) ?? []),
              {
                ...page,
                orders: [...orders.slice(0, orderIndex), newOrder, ...orders.slice(orderIndex)],
              },
              ...(prevData?.pages?.slice(pageIndex + 1) ?? []),
            ],
          }
        : undefined;
    });
  };

const handleOrderUpdated =
  (setData: (value: OrdersDataState) => void) =>
  async ({ data: updatedOrder, errors }: { data?: UpdatedOrder; errors?: Error[] }) => {
    if (checkSubscriptionError(errors)) {
      return;
    }
    if (!updatedOrder) {
      return;
    }
    let updatingIndex = -1;
    const orderDetail = await getOrderDetail(updatedOrder.id, true);
    setData((prevData) => {
      const pageIndex =
        prevData?.pages.findIndex((page) => {
          updatingIndex = page.orders?.findIndex((order) => order.id == updatedOrder.id) ?? -1;
          return updatingIndex >= 0;
        }) ?? -1;
      if (updatingIndex < 0) {
        return prevData;
      }
      const page = prevData?.pages[pageIndex];
      const orders = page?.orders ?? [];
      return {
        ...(prevData ?? { pages: [], pageParams: [] }),
        pages: [
          ...(prevData?.pages?.slice(0, pageIndex) ?? []),
          {
            ...page,
            orders: [
              ...orders.slice(0, updatingIndex),
              {
                ...orders[updatingIndex],
                orderStatus: updatedOrder.status,
                customerId: orderDetail.customerId,
                customerName: orderDetail.customerName,
                messages: convertMessagesFromModelToView(orderDetail.messages),
              },
              ...orders.slice(updatingIndex + 1),
            ],
          },
          ...(prevData?.pages?.slice(pageIndex + 1) ?? []),
        ],
      };
    });
  };

export default function OrderList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { orderId } = useParams();
  const { pathname, search } = useLocation();

  const [filterCustomer, setFilterCustomer] = useState<string | null>(null);
  const outlet = useOutlet();
  const { ref, inView } = useInView();

  const {
    company: { companyId },
  } = getUserCompany();

  const isBaseView = useBreakpointValue({ base: true, lg: false }, { ssr: false });
  const { isOpen, onToggle, onClose, onOpen } = useDisclosure({
    defaultIsOpen: !isBaseView || !orderId,
  });
  const {
    isOpen: isMenuOpen,
    onOpen: onMenuOpen,
    onClose: onMenuClose,
    onToggle: onMenuToggle,
  } = useDisclosure({
    defaultIsOpen: true,
  });

  const {
    status,
    data: originalData,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ["orders", companyId, searchParams.get("s"), searchParams.get("c")],
    queryFn: async ({ pageParam }) => {
      pageParam = pageParam ?? { offset: 0, maxLength: INITIAL_PAGE_LIMIT };
      const { orders } = await fetchOrders({
        orderStatus: searchParams.get("s") ?? "",
        searchText: "",
        customerId: searchParams.get("c") ?? "",
        offset: pageParam.offset,
        maxLength: pageParam.maxLength,
      });
      return { orders };
    },
    getNextPageParam: (lastPage, allPages) => {
      const { orders } = lastPage;
      const hasMore = orders?.length === PAGE_LIMIT;

      if (!hasMore) return undefined;
      return { offset: INITIAL_PAGE_LIMIT + (allPages?.length - 1) * PAGE_LIMIT, maxLength: PAGE_LIMIT };
    },
  });
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, originalData]);

  const [data, setData] = useState<typeof originalData>(undefined);

  useEffect(() => {
    setData(originalData);
  }, [originalData]);

  useEffect(() => {
    if (isBaseView && isOpen && orderId) {
      onToggle();
    } else if (!orderId) {
      onOpen();
    }
  }, [pathname]);

  useEffect(() => {
    if (isBaseView) {
      onMenuOpen();
    }
  }, [isBaseView]);

  useEffect(() => {
    // clear status and customer filters
    setSearchParams((searchParams) => {
      ["c", "s"].forEach((param) => searchParams.has(param) && searchParams.delete(param));
      return searchParams;
    });
  }, []);

  async function handleCustomerChange(val: string): Promise<void> {
    setSearchParams((searchParams) => {
      searchParams.set("c", val.split(",")[1] as string);
      return searchParams;
    });
  }

  async function handleStatusChange(val: string): Promise<void> {
    setSearchParams((searchParams) => {
      searchParams.set("s", val == "allOrders" ? "" : (val as string));
      return searchParams;
    });
  }

  useOrderCreated(handleOrderCreated(setData));
  useOrderUpdated(handleOrderUpdated(setData));

  const isEmpty = useMemo(() => data?.pages?.[0]?.orders?.length === 0, [data?.pages]);

  // keyboard navigation
  const [orderIdFocused, setOrderIdFocused] = useState(orderId);

  // find the order index of the
  const initialOrderIndex = data?.pages?.[0]?.orders.findIndex((elem) => elem.id == orderId);

  const [orderIndexFocused, setOrderIndexFocused] = useState(initialOrderIndex || -1); // initial index focused is -1 instead of the order because the order could be hidden
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const queryParams = new URLSearchParams(search);
  const sParam = queryParams.get("s");

  const navigate = useNavigate();
  useHotkeys(
    "shift+down",
    () => {
      setOrderIndexFocused((prev) => {
        const newIndex = Math.min(prev + 1, data?.pages?.[0]?.orders.length ? data?.pages?.[0]?.orders.length - 1 : 0);
        itemRefs.current[newIndex]?.scrollIntoView({ behavior: "smooth", block: "nearest" });

        const nextOrderId = data?.pages?.[0]?.orders[newIndex].id;
        navigate(`/orders/${nextOrderId}/${"analyzer"}${sParam ? `?s=${sParam}` : ""}`);
        return newIndex;
      });
    },
    { preventDefault: true }
  );
  useHotkeys(
    "shift+up",
    () => {
      setOrderIndexFocused((prev) => {
        const newIndex = Math.max(prev - 1, 0);
        itemRefs.current[newIndex]?.scrollIntoView({ behavior: "smooth", block: "nearest" });

        const nextOrderId = data?.pages?.[0]?.orders[orderIndexFocused].id;
        navigate(`/orders/${nextOrderId}/${"analyzer"}${sParam ? `?s=${sParam}` : ""}`);
        return newIndex;
      });
    },
    { preventDefault: true }
  );

  useHotkeys(
    "enter",
    () => {
      const nextOrderId = data?.pages?.[0]?.orders[orderIndexFocused].id;
      navigate(`/orders/${nextOrderId}/${"analyzer"}${sParam ? `?s=${sParam}` : ""}`);
    },
    { preventDefault: true }
  );

  return (
    <SidebarContext.Provider value={{ isOpen, onToggle }}>
      <Flex overflowY="auto">
        <Stack
          w={isOpen ? { base: "full", lg: "auto" } : "auto"}
          h={isOpen ? "full" : "auto"}
          position={isOpen ? { base: "fixed", lg: "relative" } : "fixed"}
          bgColor={isOpen ? "white" : "transparent"}
          zIndex="2"
        >
          {isOpen && (
            <VStack px={isOpen ? { base: "4", lg: "6" } : "4"} alignItems="start">
              {/* Sidebar width control */}
              <VStack
                mt="2"
                p={0}
                spacing="0"
                borderWidth="thin"
                borderRadius="lg"
                borderColor="gray.100"
                w={isOpen ? { base: "full", lg: isMenuOpen ? "360px" : "58px" } : "auto"}
              >
                <HStack
                  color="gray.600"
                  px="4"
                  py="2"
                  borderBottomWidth="thin"
                  borderColor="gray.200"
                  spacing="2"
                  w="full"
                >
                  <Menu>
                    <MenuButton
                      as={Button}
                      variant="unstyled"
                      _hover={{ color: "gray.800" }}
                      color="gray.400"
                      display={isMenuOpen ? "inline" : "none"}
                      _focusVisible={{ outline: "none" }}
                    >
                      <HStack spacing="1">
                        <Text fontSize="sm">Customer</Text>
                        <Icon as={HiChevronDown} boxSize="4" />
                      </HStack>
                    </MenuButton>
                    <MenuList zIndex={10}>
                      <SearchInstant
                        filterCustomer={filterCustomer}
                        setFilterCustomer={setFilterCustomer}
                        onCustomerChange={handleCustomerChange}
                      />
                    </MenuList>
                  </Menu>
                  {/* Filter Status */}
                  <Menu closeOnSelect={false}>
                    <MenuButton
                      as={Button}
                      variant="unstyled"
                      _hover={{ color: "gray.800" }}
                      color="gray.400"
                      _focusVisible={{ outline: "none" }}
                      display={isMenuOpen ? "inline" : "none"}
                    >
                      <HStack spacing="1">
                        <Text fontSize="sm">Status</Text>
                        <Icon as={HiChevronDown} boxSize="4" />
                      </HStack>
                    </MenuButton>
                    <MenuList zIndex={10}>
                      <MenuOptionGroup
                        title="Filter by status"
                        defaultValue="allOrders"
                        type="radio"
                        mt="0"
                        p="1"
                        onChange={(val) => handleStatusChange(val as string)}
                      >
                        {["allOrders", "Initialized", "NeedsReview", "Confirmed", "Pending"].map((elem, index) => (
                          <MenuItemOption
                            value={elem}
                            fontSize="xs"
                            _hover={{ bgColor: "gray.50", color: "gray.800" }}
                            _focus={{ bgColor: "gray.50", color: "gray.800" }}
                            key={index}
                            _checked={{ color: "gray.800", fontWeight: "semibold" }}
                          >
                            {formatTitle(elem)}
                          </MenuItemOption>
                        ))}
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                  {isMenuOpen && <Spacer />}
                  {isMenuOpen && <NewOrder />}
                  {!isBaseView && (
                    <IconButton
                      variant="outline"
                      size="xs"
                      icon={<Icon as={isMenuOpen ? RiMenuFoldLine : RiMenuUnfoldLine} />}
                      color="gray.400"
                      _hover={{ color: "gray.800" }}
                      aria-label="Fold"
                      onClick={onMenuToggle}
                    />
                  )}
                </HStack>
                {status == "loading" ? (
                  <Center py="2" h="8">
                    <Spinner boxSize="4" />
                  </Center>
                ) : isEmpty && isMenuOpen ? (
                  <Box py="2" h="calc(100vh - 8rem)">
                    <Text p={4}>No orders found</Text>
                  </Box>
                ) : (
                  <SimpleBar style={{ height: "calc(100vh - 8rem)", width: "100%" }}>
                    {data?.pages?.map((page, pageIndex) => (
                      <Stack
                        key={pageIndex}
                        divider={
                          <Divider
                            borderColor="gray.100"
                            sx={{ marginTop: "0px !important", marginBottom: "0px !important" }}
                            my={0}
                            py={0}
                            h={0}
                          />
                        }
                        gap={0}
                        p={0}
                        w="full"
                        maxW="full"
                      >
                        {page?.orders?.map((elem, orderIndex) => {
                          const orderIndexInAll = orderIndex + pageIndex * PAGE_LIMIT;
                          const ref = (el: HTMLDivElement) => {
                            itemRefs.current[orderIndexInAll] = el;
                          };

                          return (
                            <OrderItem
                              key={orderIndexInAll}
                              order={elem}
                              isMenuOpen={isMenuOpen}
                              itemRef={ref}
                              // isFocused={elem.id == orderId}
                              orderIndex={orderIndexInAll}
                              orderIndexFocused={orderIndexFocused}
                              setOrderIndexFocused={setOrderIndexFocused}
                            />
                          );
                        })}
                      </Stack>
                    ))}

                    <Center py="2" h="8" ref={ref}>
                      {isFetchingNextPage && hasNextPage && <Spinner boxSize="4" />}
                    </Center>
                  </SimpleBar>
                )}
              </VStack>
            </VStack>
          )}
        </Stack>
        {!(isBaseView && isOpen) && (
          <Flex w={isOpen ? { base: "full", lg: "auto" } : "full"} direction="column" flex="1">
            {outlet}
          </Flex>
        )}
      </Flex>
    </SidebarContext.Provider>
  );
}
