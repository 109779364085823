import type { CompanyLite, UserAltCase, CompanyAltCase } from "@/utils/types";

export function getUserCompany(): { user: UserAltCase; company: CompanyLite } {
  const user = localStorage.getItem("user");
  const activeCompany = localStorage.getItem("activeCompany");
  if (!user || !activeCompany) {
    throw new Error("Unauthenticated");
  }

  const { pid: companyId, name: companyName } = JSON.parse(activeCompany);
  return { user: JSON.parse(user), company: { companyId, companyName } };
}

export function setActiveCompany(company: CompanyAltCase): void {
  localStorage.setItem("activeCompany", JSON.stringify(company));
}

export const getActiveUser = (): UserAltCase | Record<string, never> => {
  const user = localStorage.getItem("user");
  if (!user) return {};
  const parsedUser = JSON.parse(user);
  return parsedUser;
};

export const isFoundationFoods = (pid: string) => pid == "clutcyubj0000m63n6dng2bsd";
