import graphQLClient from "@/api/graphql-client";
import queryClient from "@/api/query-client";
import SelectView from "@/components/layouts/select-view";
import InstallPrompt from "@/components/layouts/install-prompt";
import ProgressCrumb from "@/components/layouts/progress-crumb";
import OrderInvoice, { loader as orderInvoiceLoader } from "@/components/tabs/order-invoice";
import OrderEditor, { loader as orderEditorLoader } from "@/components/tabs/order-editor";
import OrderAnalyzer, { loader as orderAnalyzerLoader } from "@/components/tabs/order-analyzer";
import { AppContext } from "@/providers/app-context";
import ErrorPage from "@/routes/error-page";
import IndexPage from "@/routes/index-page";
import CustomerList from "@/routes/customer-list";
import CustomerPage, { loader as customerPageLoader } from "@/routes/customer-page";
import OrderList, { loader as orderListLoader } from "@/routes/order-list.tsx";
import OrderPage, { loader as orderPageLoader } from "@/routes/order-page.tsx";
import OrderView, { loader as orderViewLoader } from "@/routes/order-view.tsx";
import { ButterModule, ButterCompanyTypes, isIos, isSafari } from "@butter-technologies/dish";
import { ClientContext, GraphQLClient } from "graphql-hooks";
import { createClient } from "graphql-ws";
import { ReactNode, useMemo } from "react";
import { BiCart } from "react-icons/bi";
import { BsPeopleFill, BsBorderAll } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { HiCube, HiViewGrid, HiCollection, HiCash, HiTemplate, HiAnnotation } from "react-icons/hi";
import { MdStorefront, MdOutlineInventory, MdSettings, MdInstallMobile } from "react-icons/md";
import { Outlet } from "react-router-dom";
import { usePWA } from "@/providers/pwa-context";
import PWASupport from "@/components/layouts/pwa-support";
import SettingsPage, { loader as settingsPageLoader } from "./routes/settings-page";

const routes = [
  {
    path: "/",
    element: (
      <AppContext>
        <PWASupport />
        <InstallPrompt />
        <ProgressCrumb />
        <Outlet />
      </AppContext>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: "orders",
        loader: orderListLoader,
        element: <OrderList />,
        children: [
          {
            index: true,
            element: <SelectView item="an order" />,
          },
          {
            path: ":orderId",
            loader: orderPageLoader,
            element: <OrderPage />,
            children: [
              {
                path: "analyzer",
                loader: orderAnalyzerLoader,
                element: <OrderAnalyzer />,
              },
              {
                path: "editor",
                loader: orderEditorLoader,
                element: <OrderEditor />,
              },
              {
                path: "summary",
                loader: orderInvoiceLoader,
                element: <OrderInvoice />,
              },
            ],
          },
        ],
      },
      {
        path: "customers",
        element: <CustomerList />,
        children: [
          {
            index: true,
            element: <SelectView item="a customer" />,
          },
          {
            path: ":customerId/:conversationId",
            loader: customerPageLoader,
            element: <CustomerPage />,
          },
        ],
      },
      {
        path: "settings",
        loader: settingsPageLoader,
        element: <SettingsPage />,
      },
    ],
  },
  {
    path: "/order-view/:code",
    loader: orderViewLoader,
    element: <OrderView />,
  },
];

const navLinks = [
  {
    label: "Orders",
    path: "/orders",
    icon: HiCube,
  },
  {
    label: "Customers",
    path: "/customers",
    icon: HiViewGrid,
  },
  {
    label: "Settings",
    path: "/settings",
    icon: HiCollection,
  },
];

const butterCentralUrl = import.meta.env.VITE_CENTRAL_SERVER_BASE_URL + "/graphql";
const butterCentralWsUrl = import.meta.env.VITE_CENTRAL_WS_SERVER_BASE_URL + "/graphql";
const butterDomain: string = import.meta.env.VITE_COOKIE_DOMAIN;
const subscriptionClient = () => createClient({ url: butterCentralWsUrl });
const graphQLHooksClient = new GraphQLClient({ url: butterCentralUrl, subscriptionClient });
const storefrontUrl = import.meta.env.VITE_STOREFRONT_URL;
const paymentUrl = import.meta.env.VITE_PAYMENT_URL;

const moreModules = [
  {
    key: "STOREFRONT",
    title: "StoreFront",
    icon: MdStorefront,
    navLinks: [
      {
        label: "Customers",
        path: `${storefrontUrl}/customers`,
        icon: BsPeopleFill,
        target: "_self",
        isExternal: true,
        withCompanyPid: true,
      },
      {
        label: "Products",
        path: `${storefrontUrl}/products`,
        icon: MdOutlineInventory,
        target: "_self",
        isExternal: true,
        withCompanyPid: true,
      },
      {
        label: "Orders",
        path: `${storefrontUrl}/orders`,
        icon: BsBorderAll,
        target: "_self",
        isExternal: true,
        withCompanyPid: true,
      },
      {
        label: "Carts",
        path: `${storefrontUrl}/carts`,
        icon: BiCart,
        target: "_self",
        isExternal: true,
        withCompanyPid: true,
      },
      {
        label: "Settings",
        path: `${storefrontUrl}/settings`,
        icon: FiSettings,
        target: "_self",
        isExternal: true,
        withCompanyPid: true,
      },
    ],
  },
  {
    key: "PAYMENT",
    title: "Payment",
    icon: HiCash,
    navLinks: [
      {
        label: "Overview",
        path: `${paymentUrl}/overview`,
        icon: HiCollection,
        target: "_self",
        isExternal: true,
        withCompanyPid: true,
      },
      {
        label: "Customers",
        path: `${paymentUrl}/customers`,
        icon: HiTemplate,
        target: "_self",
        isExternal: true,
        withCompanyPid: true,
      },
      {
        label: "Settings",
        path: `${paymentUrl}/settings`,
        icon: MdSettings,
        target: "_self",
        isExternal: true,
        withCompanyPid: true,
      },
      {
        label: "Integrations",
        path: `${paymentUrl}/integrations`,
        icon: HiViewGrid,
        target: "_self",
        isExternal: true,
        withCompanyPid: true,
      },
    ],
  },
];

const defaultCompanyPid = new URLSearchParams(window.location.search).get("supplier_pid") ?? "";

const App = () => {
  const { onShowIosInstallPrompt, iosInstallPromptShown } = usePWA();
  const showIosInstallAction = useMemo(() => {
    // @ts-expect-error: This will only show on iosSafari so is not registered on WorkerNavigator, but will be handled perfectly well if not detected
    const pwaInstalledOnIos = window.navigator?.standalone === true;
    return isIos && isSafari && !iosInstallPromptShown && !pwaInstalledOnIos;
  }, [window.navigator]);

  return (
    <ClientContext.Provider value={graphQLHooksClient}>
      <ButterModule
        icon={HiAnnotation as unknown as ReactNode}
        title="AI Orders"
        basePath="/"
        domain={butterDomain}
        sidebarActions={[
          {
            label: "Install App",
            enabled: showIosInstallAction,
            icon: MdInstallMobile,
            onClick: onShowIosInstallPrompt,
          },
        ]}
        validButterCompanyTypes={[ButterCompanyTypes.seller]}
        routes={routes}
        navLinks={navLinks}
        moreModules={moreModules}
        queryClient={queryClient}
        graphQLClient={graphQLClient}
        defaultCompanyPid={defaultCompanyPid}
        whiteLabelConfig={{
          app_theme: {
            fonts: {
              body: "Lato, Inter, sans-serif",
              heading: "Lato, Inter, sans-serif",
            },
            colors: {
              primary: {
                50: "#F7FDFB",
                100: "#e6efe6",
                200: "#9FE3CD",
                300: "#64D2AD",
                400: "#14864F",
                500: "#0EA371",
                600: "#0C875E",
                700: "#096949",
                800: "#07563C",
                900: "#14864F",
              },
              bg: {
                inverse: {
                  base: "#0d4b1c",
                  up1: "#0c3d07",
                  up2: "#0b3c17",
                },
              },
              brand: {
                logo: "#ffffff",
                groupIcon: "#ffffff",
                button: "#ffffff",
              },
            },
          },
          logo_url: "/grubmarket-ai-logo.svg",
          logo_padding: "2px 0",
          hide_sidebar: false,
          company_name: "",
        }}
      />
    </ClientContext.Provider>
  );
};

export default App;
