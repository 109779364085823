import { ManifestOptions } from "vite-plugin-pwa";

export const butterManifest: Partial<ManifestOptions> = {
  id: "/",
  name: "GrubMarket AI Orders",
  short_name: "GrubMarket AI Orders",
  start_url: window.origin,
  display: "standalone",
  background_color: "#000",
  lang: "en",
  description: "The AI Enabled Ordering App for Distributors",
  icons: [
    {
      src: `${window.origin}/icon.png`,
      sizes: "1024x1024",
      type: "image/png",
      purpose: "any",
    },
    {
      src: `${window.origin}/favicon.ico`,
      sizes: "64x64",
      type: "image/x-icon",
    },
    {
      src: `${window.origin}/pwa-64x64.png`,
      sizes: "64x64",
      type: "image/png",
    },
    {
      src: `${window.origin}/apple-touch-icon-180x180.png`,
      sizes: "180x180",
      type: "image/png",
    },
    {
      src: `${window.origin}/maskable-icon-512x512.png`,
      sizes: "512x512",
      type: "image/png",
      purpose: "maskable",
    },
    {
      src: `${window.origin}/pwa-192x192.png`,
      sizes: "192x192",
      type: "image/png",
    },
    {
      src: `${window.origin}/pwa-512x512.png`,
      sizes: "512x512",
      type: "image/png",
    },
  ],
  theme_color: "#FFCA00",
  prefer_related_applications: false,
};
