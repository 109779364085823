const PHONE_REGEX = /^(?:\+\d{1,3}\s?)?(?:\(\d{3}\)|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const isValidEmail = (email: string | undefined): boolean =>
  !email
    ? false
    : /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      );

export const isValidPhoneNumber = (phoneNumber: string | undefined): boolean => {
  return !phoneNumber ? false : PHONE_REGEX.test(phoneNumber);
};

export const handleEmailValidation = (email: string): boolean => isValidEmail(email);

export const formatPhoneNumber = (phoneNumber: string | undefined): string => {
  const formattedPhoneNumberRegex = /^\+\d{11}$/;
  let formattedPhoneNumber = "";
  const cleaned = phoneNumber?.replace(/\D/g, "");
  if (cleaned?.length === 11 && cleaned[0] === "1" && cleaned[1] === "1") {
    formattedPhoneNumber = `+1${cleaned.slice(2)}`;
  } else if (cleaned?.length === 10) {
    formattedPhoneNumber = `+1${cleaned}`;
  } else if (phoneNumber && formattedPhoneNumberRegex.test(phoneNumber)) {
    return phoneNumber;
  }

  return formattedPhoneNumber;
};

export const normalizePhoneNumber = (phoneNumber: string): string => {
  const numericOnly = phoneNumber.replace(/\D/g, "");
  const formattedNumber = `(${numericOnly.slice(1, 4)}) ${numericOnly.slice(4, 7)}-${numericOnly.slice(7)}`;

  return formattedNumber;
};
