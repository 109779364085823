import { ReactNode, useEffect } from "react";
import { Button, Text, Center } from "@chakra-ui/react";
import { getCookie, setCookie } from "@butter-technologies/dish";
import { usePromptInstallContext } from "@/providers/prompt-install";

const pwaPromptKEY = "pwa_prompt_hide";

export default function InstallPrompt(): ReactNode {
  const { promptable, promptToInstall, isInstalled, isHide, setIsHide } = usePromptInstallContext();

  useEffect(() => {
    setIsHide(getCookie(pwaPromptKEY) === "true");
  }, []);

  const handleHide = (): void => {
    setCookie(pwaPromptKEY, "true");
    setIsHide(true);
  };

  return promptable && !isInstalled && !isHide ? (
    <Center bg="bg.inverse.base" minH="40px" color="white" gap={4} px="2" fontSize="xs">
      <Text fontWeight="bold">Install app to your device?</Text>
      <Button size="xs" onClick={promptToInstall} bg="white" fontWeight="bold" px="2">
        Yes
      </Button>
      <Button size="xs" onClick={handleHide} bg="white" fontWeight="bold" px="2" minW="36">
        No, hide this message
      </Button>
    </Center>
  ) : null;
}
