import { queryAISettingsByCompanyId } from "@/api/queries/voicemail";
import { queryAllUsersByCompanyId } from "@/api/queries/user";
import queryClient from "@/api/query-client";
import UserList from "@/components/items/user-list";
import { CreateAISettingsResponse, UpdateAISettingsResponse, User } from "@/utils/types";
import { getUserCompany } from "@/utils/user";
import {
  Box,
  VStack,
  Heading,
  Divider,
  Text,
  Input,
  ListItem,
  Spacer,
  Stack,
  List,
  Button,
  Icon,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
  IconButton,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useLoaderData } from "react-router-dom";
import { HiCheck, HiOutlineTrash } from "react-icons/hi";
import { isValidEmail } from "@/utils/validation";
import { useState, useMemo, useRef } from "react";
import graphQLClient from "@/api/graphql-client";
import { CREATE_AI_SETTINGS, UPDATE_AI_SETTINGS } from "@/api/mutations/voicemail";

export async function loader() {
  const {
    company: { companyId },
  } = getUserCompany();
  const fetchUsers = queryAllUsersByCompanyId(companyId);

  const fetchVoicemailEmail = queryAISettingsByCompanyId(companyId);

  const { users }: { users: User[] } = await queryClient.fetchQuery(fetchUsers);
  const { emailEnabled, voicemailEmail }: { emailEnabled: boolean; voicemailEmail: string } =
    await queryClient.fetchQuery(fetchVoicemailEmail);
  return { users, emailEnabled, voicemailEmail };
}

export default function SettingsPage() {
  const { users, emailEnabled, voicemailEmail } = useLoaderData() as {
    users: User[];
    emailEnabled: boolean;
    voicemailEmail: string;
  };
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState(voicemailEmail);
  const [isEmailEnabled, setIsEmailEnabled] = useState(emailEnabled);

  const toast = useToast();
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const cancelAlertRef = useRef<HTMLButtonElement>(null);
  const {
    company: { companyId: supplierId },
  } = getUserCompany();

  const isValid = useMemo(() => {
    if (emailAddress !== voicemailEmail && isValidEmail(emailAddress)) {
      return true;
    } else return false;
  }, [emailAddress]);

  async function handleSave() {
    try {
      setIsLoading(true);
      if (!voicemailEmail && voicemailEmail !== "") {
        // create voicemail email
        const response = await mutateCreateAISettings.mutateAsync({
          supplierId,
          voicemailEmail: emailAddress,
        });
        if (response?.errors) {
          toast({
            title: `Unable to add the voicemail provider email address.`,
            //@ts-expect-error
            description: response?.errors,
            status: "error",
            duration: 5000,
            isClosable: true,
            onCloseComplete: () => {},
          });
          return;
        } else if (!response?.ai?.create_ai_settings) {
          toast({
            title: `Unable to add the voicemail provider email address.`,
            description: "Please check if you have a valid email.",
            status: "error",
            duration: 5000,
            isClosable: true,
            onCloseComplete: () => {},
          });
          return;
        }
        toast({
          title: `You have added ${emailAddress} as the voicemail provider email address!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        // update voicemail email
        const response = await mutateUpdateAISettings.mutateAsync({
          supplierId,
          emailEnabled: isEmailEnabled,
          voicemailEmail: emailAddress,
        });
        if (response?.errors) {
          toast({
            title: `Unable to update the voicemail provider email address.`,
            //@ts-expect-error
            description: response?.errors,
            status: "error",
            duration: 5000,
            isClosable: true,
            onCloseComplete: () => {},
          });
          return;
        } else if (!response?.ai?.update_ai_settings) {
          toast({
            title: `Unable to update the voicemail provider email address.`,
            description: "Please check if you have a valid email.",
            status: "error",
            duration: 5000,
            isClosable: true,
            onCloseComplete: () => {},
          });
          return;
        }
        toast({
          title: `You have updated the voicemail provider email address with ${emailAddress}!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      setIsSaved(true);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
    return;
  }

  async function handleDelete() {
    try {
      setIsLoading(true);

      // update voicemail email
      const response = await mutateUpdateAISettings.mutateAsync({
        supplierId,
        emailEnabled: isEmailEnabled,
        voicemailEmail: "",
      });
      if (response?.errors) {
        toast({
          title: `Unable to delete the voicemail provider email address.`,
          //@ts-expect-error
          description: response?.errors,
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
        return;
      } else if (!response?.ai?.update_ai_settings) {
        toast({
          title: `Unable to delete the voicemail provider email address.`,
          description: "Please check if you have a valid email.",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
        return;
      }
      toast({
        title: `You have delete the voicemail provider email address ${emailAddress}!`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setIsSaved(true);
    } catch (error) {
    } finally {
      setIsLoading(false);
      onAlertClose();
    }
    return;
  }

  async function handleToggle(isChecked: boolean) {
    try {
      setIsLoading(true);

      // toggle email processing
      const response = await mutateUpdateAISettings.mutateAsync({
        supplierId,
        emailEnabled: isChecked,
        voicemailEmail,
      });
      if (response?.errors) {
        toast({
          title: `Unable to update your email processing at this time.`,
          //@ts-expect-error
          description: response?.errors,
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
        return;
      } else if (!response?.ai?.update_ai_settings) {
        toast({
          title: `Unable to update your email processing at this time`,
          description: "Please try again later.",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
        return;
      }
      toast({
        title: `You have ${isChecked ? "enabled" : "disabled"} your email processing.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setIsSaved(true);
    } catch (error) {
    } finally {
      setIsLoading(false);
      onAlertClose();
    }
    return;
  }

  const mutateCreateAISettings = useMutation<
    CreateAISettingsResponse,
    unknown,
    {
      supplierId: string;
      voicemailEmail: string;
    }
  >(
    async ({ supplierId, voicemailEmail }) => {
      return await graphQLClient.request(CREATE_AI_SETTINGS, {
        supplierId,
        voicemailEmail,
      });
    },
    {
      onSuccess: () => {},
      onError: (e) => {
        toast({
          // @ts-expect-error
          title: e.response?.errors
            // @ts-expect-error
            ?.map((e) => e.message)
            .join(", ")
            .slice(0, -1),
          description: "Please try again",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
      },
    }
  );

  const mutateUpdateAISettings = useMutation<
    UpdateAISettingsResponse,
    unknown,
    {
      supplierId: string;
      emailEnabled: boolean;
      voicemailEmail: string;
    }
  >(
    async ({ supplierId, emailEnabled, voicemailEmail }) => {
      return await graphQLClient.request(UPDATE_AI_SETTINGS, {
        supplierId,
        emailEnabled,
        voicemailEmail,
      });
    },
    {
      onSuccess: () => {},
      onError: (e) => {
        toast({
          // @ts-expect-error
          title: e.response?.errors
            // @ts-expect-error
            ?.map((e) => e.message)
            .join(", ")
            .slice(0, -1),
          description: "Please try again",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
      },
    }
  );

  return (
    <>
      <Box w="full" pb="8" overflowY="auto">
        <VStack px={{ base: "4", lg: "8" }} mx="auto" spacing="4">
          <Box>
            <Heading textAlign="center" w="full" pl="2" pb="2" mt="12" size="sm">
              Email Forwarding
            </Heading>
            <Divider bgColor="gray.100" w="full" />
            <Box pt="2" fontSize="sm" textAlign="center" mx="auto" maxW="xl">
              Configure your email settings to forward order emails copies to{" "}
              <Text display="inline" fontWeight="semibold">
                ai@butterapp.io
              </Text>
              . Our AI system processes orders by analyzing email and voicemail messages, identifying and matching them
              based on provided phone numbers and email addresses.
            </Box>
          </Box>
          <Box borderWidth="thin" borderColor="primary.300" bgColor="primary.50" borderRadius="xl" p="4">
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="email-alerts" mb="0" fontSize="sm">
                Enable email processing
              </FormLabel>
              <Switch
                id="email-alerts"
                defaultChecked={isEmailEnabled}
                onChange={(e) => {
                  setIsEmailEnabled(e.target.checked);
                  handleToggle(e.target.checked);
                }}
              />
            </FormControl>
          </Box>
          <Box borderWidth="thin" borderColor="gray.200" borderRadius="xl" p="4" w="full">
            <Text textAlign="left" ml="2" mb="2" fontWeight="semibold">
              Email and Voicemail Orders
            </Text>
            <Text textAlign="left" ml="2" mb="2" fontWeight="normal" fontSize="sm">
              Please provide the full name, phone number, and/or email address of a team user for email and voicemail
              orders.
            </Text>

            <Box borderWidth="thin" borderColor="gray.100" maxH="480">
              <UserList users={users} channelType="Email" isBuyer={false} />
            </Box>
          </Box>

          <Box borderWidth="thin" borderColor="gray.200" borderRadius="xl" p="4" w="full">
            <Text textAlign="left" ml="2" mb="2" fontWeight="semibold">
              Voicemail
            </Text>
            <Text textAlign="left" ml="2" mb="2" fontWeight="normal" fontSize="sm">
              Enter the email address of your voicemail service provider for voicemail orders.
            </Text>

            <Box borderWidth="thin" borderColor="gray.100" maxH="60" overflowY="auto" px="4">
              <List>
                <ListItem
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ lg: "center" }}
                  borderBottomColor="gray.100"
                  flexDir={{ base: "column", lg: "row" }}
                  py="4"
                >
                  <Stack flexDir={{ base: "column", lg: "row" }}>
                    <Text fontWeight="medium" fontSize="xs">
                      Service Provider
                    </Text>
                  </Stack>
                  <Spacer />
                  <Stack px="2">
                    <Input
                      defaultValue={voicemailEmail ?? ""}
                      onChange={(e) => {
                        setEmailAddress(e.target.value);
                      }}
                      placeholder="Enter an email for your voicemail service provider"
                      size="sm"
                      variant="flushed"
                      w="72"
                    />
                  </Stack>
                  <Button
                    aria-label="Add/Update"
                    _focusVisible={{ boxShadow: "none" }}
                    w="20"
                    h="6"
                    onClick={handleSave}
                    gap="1"
                    alignItems="center"
                    colorScheme={isValid ? "primary" : "gray"}
                    borderRadius="full"
                    isDisabled={!isValid || isSaved}
                    isLoading={isLoading}
                    fontSize="xs"
                  >
                    {isSaved && <Icon as={HiCheck} boxSize="4" color="inherit" />}
                    {isSaved ? "Updated!" : "Update"}
                  </Button>
                  <IconButton
                    variant="unstyled"
                    aria-label="Delete"
                    icon={<Icon as={HiOutlineTrash} boxSize="4" color="gray.400" _hover={{ color: "gray.800" }} />}
                    onClick={onAlertOpen}
                    _focusVisible={{ boxShadow: "none" }}
                    w="12"
                    pt="1"
                  />
                  <AlertDialog
                    isCentered
                    isOpen={isAlertOpen}
                    leastDestructiveRef={cancelAlertRef}
                    onClose={onAlertClose}
                  >
                    <AlertDialogOverlay>
                      <AlertDialogContent w="90%">
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                          Delete
                        </AlertDialogHeader>
                        <AlertDialogBody>
                          Are you sure to delete {emailAddress} as the voicemail service provider?
                        </AlertDialogBody>
                        <AlertDialogFooter>
                          <Button ref={cancelAlertRef} onClick={onAlertClose}>
                            Cancel
                          </Button>
                          <Button colorScheme="red" ml={3} onClick={handleDelete}>
                            Delete
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialog>
                </ListItem>
              </List>
            </Box>
          </Box>
        </VStack>
      </Box>
    </>
  );
}
