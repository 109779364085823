import { createRoot } from "react-dom/client";
import { configureSentry } from "@butter-technologies/dish";
import "simplebar-react/dist/simplebar.min.css";
import { PWAContextProvider } from "@/providers/pwa-context";
import App from "@/app";

configureSentry(import.meta.env.MODE, import.meta.env.VITE_SENTRY_DSN);

createRoot(document.getElementById("root")!).render(
  <PWAContextProvider>
    <App />
  </PWAContextProvider>
);
