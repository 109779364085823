import { queryAllOrdersByCompanyId } from "@/api/queries/order-list";
import queryClient from "@/api/query-client";
import { formatDate, formatTitle } from "@/utils/formatter";
import { Order } from "@/utils/types";
import { getUserCompany } from "@/utils/user";
import { Button, Spacer, Text } from "@chakra-ui/react";
import { NavItem } from "@saas-ui/react";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export default function CustomerItem({
  createdAt,
  customerId,
  customerName,
  conversationId,
}: {
  createdAt: string;
  customerId: string;
  customerName: string;
  conversationId: string;
}): ReactNode {
  const navigate = useNavigate();
  const { customerId: activeId } = useParams();
  const [searchParams, _] = useSearchParams();
  const [__, setOrderCount] = useState(0);

  useEffect(() => {
    (async () => {
      const {
        company: { companyId },
      } = getUserCompany();

      const fetchOrders = queryAllOrdersByCompanyId(companyId, "", "", customerId, 0, 300, false);
      const { orders = [] }: { orders: Order[] } = (await queryClient.fetchQuery(fetchOrders)) ?? {};
      setOrderCount(orders?.length);
    })();
  }, []);

  return (
    <NavItem
      onClick={() => {
        searchParams.set("p", "1");
        navigate(`${customerId}/${conversationId}?${searchParams?.toString()}`);
      }}
      as={Button}
      isActive={customerId == activeId}
      px="4"
      w="100%"
      bgColor="white"
      title={customerName}
      _hover={{ bgColor: "gray.100" }}
      _active={{ bgColor: "gray.100" }}
      borderRadius="0"
    >
      <Text isTruncated align="left">
        {formatTitle(customerName)}
      </Text>
      <Spacer />
      <Text color="gray.400" align="right" fontWeight="normal">
        {formatDate(createdAt)}
      </Text>
    </NavItem>
  );
}
