import { Fragment, ReactNode, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import type { Conversation } from "@/utils/types";
import queryClient from "@/api/query-client";
import { getUserCompany } from "@/utils/user";
import SidebarShell from "@/components/layouts/sidebar-shell";
import { Box, Center, Spinner } from "@chakra-ui/react";
import { NavGroup } from "@saas-ui/react";
import { useSortOrderContext } from "@/providers/sort-order";
import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import CustomerItem from "@/components/items/customer-item";
import { queryAllConversationsByCompanyId } from "@/api/queries/conversation";
import { CreatedConversation, useConversationCreated } from "@/hooks/subscription.ts";
import { checkSubscriptionError } from "@/utils/subscription.ts";

const INITIAL_PAGE_LIMIT = 100;
const PAGE_LIMIT = 100;

async function fetchConversations(offset: number, maxLength: number): Promise<{ conversations: Conversation[] }> {
  const {
    company: { companyId },
  } = getUserCompany();

  const fetchConversations = queryAllConversationsByCompanyId(companyId, offset, maxLength);
  const { conversations }: { conversations: Conversation[] } = (await queryClient.fetchQuery(fetchConversations)) ?? {};
  return { conversations };
}

const handleConversationCreated =
  (
    setData: (
      value: (
        prevData: InfiniteData<{ conversations: Conversation[] }> | undefined
      ) => InfiniteData<{ conversations: Conversation[] }> | undefined
    ) => void
  ) =>
  async ({ data: createdConversation, errors }: { data?: CreatedConversation; errors?: Error[] }) => {
    if (checkSubscriptionError(errors)) {
      return;
    }
    if (!createdConversation) {
      return;
    }
    let conversationIndex = -1;
    const newConversationId = parseInt(createdConversation.id);

    setData((prevData: InfiniteData<{ conversations: Conversation[] }> | undefined) => {
      let pageIndex =
        prevData?.pages.findIndex((page: { conversations: Conversation[] }) => {
          conversationIndex =
            page.conversations?.findIndex(
              (conversation: { conversationId: string }) => parseInt(conversation.conversationId) >= newConversationId
            ) ?? -1;
          return conversationIndex >= 0;
        }) ?? -1;
      // The new conversation exits, ignore. maybe current page created this conversation.
      if (
        pageIndex >= 0 &&
        conversationIndex >= 0 &&
        prevData?.pages[pageIndex].conversations[conversationIndex].conversationId == createdConversation.id
      ) {
        return prevData;
      }
      if (pageIndex < 0) {
        pageIndex = 0;
      }
      if (conversationIndex < 0) {
        conversationIndex = 0;
      }
      const page = prevData?.pages[pageIndex];
      const conversations = page?.conversations ?? [];
      const newConversation = {
        conversationId: createdConversation.id,
        conversationStatus: createdConversation.status,
        customerId: createdConversation.customer_id,
        customerName: createdConversation.customer_name,
        createdAt: createdConversation.created_ts,
        updatedAt: createdConversation.updated_ts,
      };
      return prevData
        ? {
            ...(prevData ?? { pages: [], pageParams: [] }),
            pages: [
              ...(prevData?.pages?.slice(0, pageIndex) ?? []),
              {
                ...page,
                conversations: [
                  ...conversations.slice(0, conversationIndex),
                  newConversation,
                  ...conversations.slice(conversationIndex),
                ],
              },
              ...(prevData?.pages?.slice(pageIndex + 1) ?? []),
            ],
          }
        : undefined;
    });
  };

// customers that are active
export default function CustomerList(): ReactNode {
  const [searchParams] = useSearchParams();
  const { isDesc, orderBy } = useSortOrderContext();
  const {
    company: { companyId },
  } = getUserCompany();

  const { ref, inView } = useInView();

  const {
    status,
    data: originalData,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["conversations", companyId, searchParams.get("q"), isDesc, orderBy],
    async ({ pageParam }) => {
      pageParam = pageParam ?? { offset: 0, maxLength: INITIAL_PAGE_LIMIT };
      const { conversations } = await fetchConversations(pageParam.offset, pageParam.maxLength);
      return { conversations };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const { conversations } = lastPage;
        const hasMore = conversations?.length === PAGE_LIMIT;

        if (!hasMore) return undefined; // !hasMore for all customers
        return { offset: INITIAL_PAGE_LIMIT + (allPages?.length - 1) * PAGE_LIMIT, maxLength: PAGE_LIMIT };
      },
    }
  );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  const [data, setData] = useState<typeof originalData>(undefined);

  useEffect(() => {
    setData(originalData);
  }, [originalData]);

  useConversationCreated(handleConversationCreated(setData));

  function refreshChats() {
    (async () => {
      // useConversationCreated(handleConversationCreated(setData))
    })();
  }

  return (
    <Box overflowX="auto">
      <SidebarShell refreshChats={refreshChats}>
        {status == "loading" ? (
          <Center py="2" h="8">
            <Spinner boxSize="4" />
          </Center>
        ) : data?.pages[0]?.conversations?.length == 0 ? (
          <Center py="2" h="8">
            <Box p={4}>No chats found</Box>
          </Center>
        ) : (
          <NavGroup>
            {data?.pages?.map((page, index) => (
              <Fragment key={index}>
                {page?.conversations?.map(({ conversationId, createdAt, customerId, customerName }, index) => (
                  <CustomerItem
                    createdAt={createdAt}
                    customerId={customerId}
                    customerName={customerName}
                    conversationId={conversationId}
                    key={index}
                  />
                ))}
              </Fragment>
            ))}
            <Center py="2" h="8" ref={ref}>
              {isFetchingNextPage && hasNextPage && <Spinner boxSize="4" />}
            </Center>
          </NavGroup>
        )}
      </SidebarShell>
    </Box>
  );
}
