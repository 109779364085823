import { gql } from "graphql-request";
import graphQLClient from "@/api/graphql-client";
import type { CompanyAltCase, Order, OrderAltCase, QueryError } from "@/utils/types";

const ALL_ORDERS_BY_COMPANY_ID_QUERY = gql`
  query AllOrdersByCompanyId(
    $companyId: ID = ""
    $status: String = ""
    $text: String = ""
    $customerId: String = ""
    $offset: Int
    $maxLength: Int
    $invalidateCache: Boolean
  ) {
    company(company_pid: $companyId) {
      ai_order_list(
        invalidate_cache: $invalidateCache
        max_length: $maxLength
        offset: $offset
        search: { customer_id: $customerId, status: $status, text: $text }
      ) {
        has_more
        orders {
          id
          created_ts
          customer_id
          customer_name
          delivery_date
          updated_ts
          status
          messages {
            body
            created_ts
            job_id
          }
        }
      }
    }
  }
`;

export const queryAllOrdersByCompanyId = (
  companyId: string,
  status: string,
  text: string,
  customerId: string,
  offset: number,
  maxLength: number,
  invalidateCache: boolean
): {
  queryKey: [string, string, string, string, string, number, number, boolean];
  queryFn: () => Promise<{ hasMore: boolean; orders: Order[] } | QueryError>;
} => ({
  queryKey: ["orders", companyId, status, text, customerId, offset, maxLength, invalidateCache],
  queryFn: async (): Promise<{ hasMore: boolean; orders: Order[] } | QueryError> => {
    try {
      const { company }: { company: CompanyAltCase } =
        (await graphQLClient.request(ALL_ORDERS_BY_COMPANY_ID_QUERY, {
          companyId,
          status,
          text,
          customerId,
          offset,
          maxLength,
          invalidateCache,
        })) ?? {};
      const { has_more: hasMore, orders: _orders } = company?.ai_order_list ?? {};
      const orders =
        _orders?.map((elem: OrderAltCase) => ({
          id: elem.id,
          createdAt: elem.created_ts,
          customerId: elem.customer_id,
          customerName: elem.customer_name,
          deliveryDate: elem.delivery_date,
          updatedAt: elem.updated_ts,
          orderStatus: elem.status,
          firstMessage: elem.messages?.find((elem) => elem.job_id)?.body ?? elem.messages?.[0]?.body ?? "",
          lastMessageAt: elem.messages?.[elem.messages.length - 1]?.created_ts ?? "",
          messages: elem.messages,
        })) ?? [];

      return { hasMore, orders };
    } catch (error) {
      return {
        error: {
          message: "An error occurred while querying orders: " + String(error),
        },
      };
    }
  },
});

const ORDER_COUNT_BY_STATUS_QUERY = gql`
  query OrderCountByStatus($companyId: ID = "", $status: String = "") {
    company(company_pid: $companyId) {
      ai_order_count(status: $status) {
        count
      }
    }
  }
`;

export const queryOrderCountByStatus = (
  companyId: string,
  status: string
): {
  queryKey: [string, string, string];
  queryFn: () => Promise<{ count: string } | QueryError>;
} => ({
  queryKey: ["orderCount", companyId, status],
  queryFn: async (): Promise<{ count: string } | QueryError> => {
    try {
      const { company }: { company: CompanyAltCase } =
        (await graphQLClient.request(ORDER_COUNT_BY_STATUS_QUERY, {
          companyId,
          status,
        })) ?? {};
      const { count } = company?.ai_order_count ?? {};
      return { count };
    } catch (error) {
      return {
        error: {
          message: "An error occurred while querying orders: " + String(error),
        },
      };
    }
  },
});
