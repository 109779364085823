import { queryCustomersByCompanyId } from "@/api/queries/customer";
import queryClient from "@/api/query-client";
import { formatAddress } from "@/utils/formatter";
import { Customer } from "@/utils/types";
import { getUserCompany } from "@/utils/user";
import { useDisclosure, Popover, PopoverTrigger, Input, PopoverContent, Box, Button } from "@chakra-ui/react";
import { forwardRef, useRef, useState } from "react";

const SearchableDropdown = forwardRef<
  HTMLInputElement,
  {
    searchText: string;
    setSearchText: (text: string) => void;
    isPossibleCustomer: boolean;
    setIsPossibleCustomer: (value: boolean) => void;
    setCustomer: (customer: Customer | null) => void;
    nextRef?: React.RefObject<HTMLInputElement>;
    placeholder?: string;
    [key: string]: any; // for any additional props
  }
>(
  (
    {
      searchText,
      setSearchText,
      isPossibleCustomer,
      setIsPossibleCustomer,
      setCustomer,
      nextRef = null,
      placeholder = "Search for a customer",
      ...props
    },
    ref
  ) => {
    const isSearchActive = useRef(false);
    const [active, setActive] = useState(0);
    const [displayedCustomers, setDisplayedCustomers] = useState<Customer[] | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null); // ref to store the timeout id

    const handleKeyPress = (event: { keyCode: number }) => {
      setIsPossibleCustomer(false);
      const isUpKeyCode = event.keyCode == 38;
      const isDownKeyCode = event.keyCode == 40;
      const isEnterKeyCode = event.keyCode == 13;
      const isExitKeyCode = event.keyCode == 27;
      if (isUpKeyCode && active > 0) {
        setActive(active - 1);
      }
      if (isDownKeyCode && displayedCustomers?.length && active < displayedCustomers?.length - 1) {
        setActive(active + 1);
      }
      if (isEnterKeyCode) {
        setCustomer(displayedCustomers?.[active] ?? null);
        setSearchText(displayedCustomers?.[active]?.customerName ?? "");
        onClose();
        setTimeout(() => {
          // nextRef?.current?.focus(); // delay the focus to avoid the 'Enter' key event
        }, 0);
      }
      if (isExitKeyCode) {
        onClose();
      }
      if (!isSearchActive.current) {
        onClose();
      }
    };

    async function handleSearch(searchText: string) {
      if (isPossibleCustomer) return; // skip search if it's one of the possible customers
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current); // clear the previous timeout
      }
      if (!searchText) {
        setDisplayedCustomers([]);
        return;
      }
      searchTimeoutRef.current = setTimeout(async () => {
        const isDesc = true;
        const orderBy = "DisplayName";
        const offset = 0;
        const maxLength = 10; // pagination
        const {
          company: { companyId: supplierId, companyName },
        } = getUserCompany();

        const fetchCustomers = queryCustomersByCompanyId(
          supplierId,
          searchText, // searchText
          isDesc,
          orderBy,
          offset,
          maxLength
        );
        const { customers = [] }: { customers: Customer[] } = (await queryClient.fetchQuery(fetchCustomers)) ?? {};
        setActive(0);
        setDisplayedCustomers(customers);
        customers?.length > 0 ? onOpen() : onClose();

        // keep focus on input
        (ref as React.MutableRefObject<HTMLInputElement>).current?.focus();
      }, 500);
    }

    return (
      <Popover {...props} isOpen={isOpen} onClose={onClose} onOpen={onOpen} matchWidth closeOnBlur closeOnEsc>
        <PopoverTrigger>
          <Input
            ref={ref}
            value={searchText}
            autoComplete="off"
            onChange={(e) => {
              setSearchText(e.target.value);
              isSearchActive.current = true;
              handleSearch(e.target.value);
              (ref as React.MutableRefObject<HTMLInputElement>).current?.focus();
            }}
            placeholder={placeholder}
            onKeyDown={handleKeyPress}
            isRequired={true}
            fontSize="sm"
            borderRadius="lg"
          />
        </PopoverTrigger>
        {!displayedCustomers || displayedCustomers?.length == 0 ? null : (
          <PopoverContent
            w="full"
            _focusVisible={{ outline: "none" }}
            onFocus={() => {
              (ref as React.MutableRefObject<HTMLInputElement>).current?.focus();
            }}
          >
            <Box maxH="320" overflowY="auto">
              {displayedCustomers?.map((elem: Customer, index: number) => (
                <Button
                  variant="ghost"
                  borderRadius="md"
                  w="full"
                  onClick={() => {
                    setCustomer(elem);
                    setSearchText(elem.customerName);
                    onClose();
                    // nextRef?.current?.focus();
                  }}
                  _focusVisible={{ outline: "none" }}
                  bgColor={index == active ? "primary.100" : "white"}
                  _hover={{ bgColor: "primary.100" }}
                  key={index}
                  fontSize="xs"
                  height={"40px"}
                  flexDirection={"column"}
                >
                  <Box>
                    [{elem.customerId}] - {elem.customerName}
                  </Box>
                  <Box fontSize={"10"} color={"gray.500"}>
                    {formatAddress(elem.shippingAddress)}
                  </Box>
                </Button>
              ))}
            </Box>
          </PopoverContent>
        )}
      </Popover>
    );
  }
);

export default SearchableDropdown;
