export default function uploadFile(file: File, url: string, onProgress: (percentage: number) => void): Promise<void> {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();

    xhr.open("PUT", url, true);
    xhr.setRequestHeader("Content-Type", "multipart/form-data");

    xhr.upload.onprogress = function (e) {
      if (e.lengthComputable) {
        onProgress((e.loaded / e.total) * 100); // pass percentage to onProgress callback
      }
    };

    xhr.onload = function () {
      if (xhr.status === 200) {
        resolve();
      } else {
        reject("Upload failed");
      }
    };

    xhr.onerror = function () {
      reject("Network error");
    };

    xhr.send(file);
  });
}
