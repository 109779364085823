import { gql } from "graphql-request";

export const CREATE_AI_SETTINGS = gql`
  mutation CreateAISettings($supplierId: String!, $voicemailEmail: String!) {
    ai {
      create_ai_settings(input: { supplier_pid: $supplierId, voicemail_email: $voicemailEmail }) {
        voicemail_email
      }
    }
  }
`;

export const UPDATE_AI_SETTINGS = gql`
  mutation UpdateAISettings($supplierId: String!, $emailEnabled: Boolean = false, $voicemailEmail: String!) {
    ai {
      update_ai_settings(
        input: { supplier_pid: $supplierId, email_enabled: $emailEnabled, voicemail_email: $voicemailEmail }
      ) {
        email_enabled
        voicemail_email
      }
    }
  }
`;
