import graphQLClient from "@/api/graphql-client";
import queryClient from "@/api/query-client";
import { Customer, User } from "@/utils/types";
import { getUserCompany } from "@/utils/user";
import { formatPhoneNumber } from "@butter-technologies/dish";
import {
  Avatar,
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  Td,
  Tr,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  Tooltip,
  Badge,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState, useRef } from "react";
import { HiCheck, HiOutlineTrash } from "react-icons/hi";
import InputMask from "react-input-mask";
import { isValidEmail, isValidPhoneNumber } from "@/utils/validation";
import {
  CREATE_BUTTER_COMPANY_AND_USER,
  CREATE_BUTTER_USER,
  DELETE_BUTTER_USER,
  UPDATE_BUTTER_USER,
} from "@/api/mutations/user";
import { queryAllUsersByCompanyId } from "@/api/queries/user";
import { UPDATE_CONVERSATION_PARTICIPANT, DELETE_CONVERSATION_PARTICIPANT } from "@/api/mutations/participant";

export default function UserRow({
  user,
  users,
  setUsers,
  customer,
  isUpdate,
  setAddRows,
  isBuyer,
  channelType,
  currIndex,
}: {
  user: User | null;
  users?: User[];
  setUsers: Dispatch<SetStateAction<User[] | undefined>>;
  customer?: Customer;
  isUpdate: boolean;
  setAddRows?: Dispatch<SetStateAction<(User | undefined)[]>>;
  isBuyer: boolean;
  channelType: "Email" | "Voicemail" | "SMS" | "GrubAssist";
  currIndex?: number;
}): ReactNode {
  const toast = useToast();
  const [userId, setUserId] = useState(user?.userId);
  const [displayName, setDisplayName] = useState(user?.userName);
  const [emailAddress, setEmailAddress] = useState(user?.userEmail);
  const [phoneNumber, setPhoneNumber] = useState(user?.userPhone);
  const [userTitle, setUserTitle] = useState("");
  // const [channelType, setChannelType] = useState(user?.channelType);
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const cancelAlertRef = useRef<HTMLButtonElement>(null);
  const {
    company: { companyId, companyName },
  } = getUserCompany();

  useEffect(() => {
    setDisplayName(user?.userName);
    setEmailAddress(user?.userEmail);
    setPhoneNumber(user?.userPhone);
    // setChannelType(user?.channelType ?? "Voicemail");
  }, [user]);

  useEffect(() => {
    if (setAddRows && currIndex !== undefined && users?.some((elem) => elem.userId === userId)) {
      setAddRows((prev) => [...prev.slice(0, currIndex).concat(prev.slice(currIndex + 1))]);
    }
  }, [users]);

  const isValid = useMemo(() => {
    if (
      hasChanged(
        {
          displayName: user?.userName,
          emailAddress: user?.userEmail,
          phoneNumber: user?.userPhone,
          userTitle: user?.userTitle,
        },
        { displayName, emailAddress, phoneNumber, userTitle }
      )
    ) {
      if (["", undefined].includes(displayName)) {
        return false;
      }
      if ((emailAddress && !isValidEmail(emailAddress)) || (phoneNumber && !isValidPhoneNumber(phoneNumber))) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }, [user, displayName, emailAddress, phoneNumber, userTitle]);

  // @ts-expect-error
  function hasChanged(original, updated) {
    for (let key in original) {
      if (updated[key] !== "" && original[key] !== updated[key]) {
        return true;
      }
    }
    return false;
  }

  async function handleSave() {
    // add a contact
    if (!user) {
      try {
        setIsLoading(true);
        if (!companyId) {
          // create butter company and user for the supplier
          const resultUser = await mutateCreateButterCompanyAndUser.mutateAsync({
            address: "",
            companyType: customer ? "BUYER" : "SELLER",
            companyName: companyName,
            userEmail: emailAddress ?? "",
            userName: displayName ?? "",
            userPhone: formatPhoneNumber(phoneNumber ?? ""),
            userTitle: userTitle,
          });
          //@ts-expect-error
          if (resultUser?.errors) {
            toast({
              title: `Unable to add a contact`,
              //@ts-expect-error
              description: resultUser?.errors,
              status: "error",
              duration: 5000,
              isClosable: true,
              onCloseComplete: () => {},
            });
            return;
            //@ts-expect-error
          } else if (!resultUser?.butter?.create_butter_company_and_user) {
            toast({
              title: `Unable to add a contact`,
              description:
                "Please check if you have a valid email or phone number, or if there is already a conversation in progress.",
              status: "error",
              duration: 5000,
              isClosable: true,
              onCloseComplete: () => {},
            });
            return;
          }
        } else {
          // create butter user when there is a butter company
          const resultUser = await mutateCreateButterUser.mutateAsync({
            companyId: customer
              ? customer.companyId && customer.companyId !== ""
                ? customer.companyId
                : null
              : companyId,
            userName: displayName ?? "",
            userPhone: formatPhoneNumber(phoneNumber ?? ""),
            userEmail: emailAddress ?? "",
            userTitle: userTitle,
          });
          //@ts-expect-error
          if (resultUser?.errors) {
            toast({
              title: `Unable to add a contact`,
              //@ts-expect-error
              description: resultUser?.errors,
              status: "error",
              duration: 5000,
              isClosable: true,
              onCloseComplete: () => {},
            });
            return;
            //@ts-expect-error
          } else if (!resultUser?.butter?.create_butter_user) {
            toast({
              title: `Unable to add a contact`,
              description:
                "Please check if you have a valid email or phone number, or if there is already a conversation in progress.",
              status: "error",
              duration: 5000,
              isClosable: true,
              onCloseComplete: () => {},
            });
            return;
          }
        }
        toast({
          title: `You have added ${displayName || emailAddress || phoneNumber} to this conversation!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsSaved(true);
        //@ts-expect-error
        setUserId(resultUser?.butter?.create_butter_user?.pid);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
      return;
    }

    // update a user
    try {
      setIsLoading(true);
      const resultUser = await mutateUpdateButterUser.mutateAsync({
        userId: userId && userId !== "" ? userId : null,
        userName: displayName ?? "",
        userEmail: emailAddress ?? "",
        userPhone: formatPhoneNumber(phoneNumber ?? ""),
        userTitle,
      });

      //@ts-expect-error
      if (resultUser?.errors) {
        toast({
          title: `Unable to update a user`,
          //@ts-expect-error
          description: resultUser?.errors,
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
        return;
        //@ts-expect-error
      } else if (!resultUser?.butter?.update_butter_user) {
        toast({
          title: `Unable to update a user`,
          description:
            "Please check if you have a valid email or phone number, or if there is already a conversation in progress.",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
        return;
      }
      setIsSaved(true);

      // Retrieve latest users after deletion
      const fetchUsers = await queryAllUsersByCompanyId(companyId);
      const { users }: { users: User[] } = fetchUsers ? await queryClient.fetchQuery(fetchUsers) : { users: [] };
      setUsers(users);
      toast({
        title: `You have updated ${displayName || phoneNumber || emailAddress}`,
        status: "success",
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => {},
      });
      setTimeout(() => {
        setIsSaved(false);
      }, 1500);
    } finally {
      setIsLoading(false);
    }
  }
  async function handleDelete() {
    if (!user) return;
    try {
      await mutateDeleteButterUser?.mutateAsync({
        userId: userId && userId !== "" ? userId : null,
      });
      toast({
        title: `You have deleted ${displayName || phoneNumber || emailAddress}`,
        description: `${displayName || phoneNumber || emailAddress} will no longer be on the customer user list`,
        status: "success",
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => {},
      });
    } catch {
      toast({
        title: `Try again later`,
        description: `An error occurred while trying to delete ${displayName || phoneNumber || emailAddress}`,
        status: "error",
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => {},
      });
    } finally {
      onAlertClose();
    }
  }

  // create user
  const mutateCreateButterUser = useMutation<
    unknown,
    unknown,
    {
      companyId: string | null;
      userEmail: string | null | undefined;
      userName: string;
      userPhone: string | null | undefined;
      userTitle: string;
    }
  >(
    async ({ companyId, userEmail, userName, userPhone, userTitle }) => {
      return await graphQLClient.request(CREATE_BUTTER_USER, {
        companyId,
        userName,
        userPhone,
        userEmail,
        userTitle,
      });
    },
    {
      onSuccess: () => {},
      onError: (e) => {
        toast({
          // @ts-expect-error
          title: e.response?.errors
            // @ts-expect-error
            ?.map((e) => e.message)
            .join(", ")
            .slice(0, -1),
          description: "Please try again",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
      },
    }
  );

  // create company and user
  const mutateCreateButterCompanyAndUser = useMutation<
    unknown,
    unknown,
    {
      address: string;
      companyType: string;
      companyName: string;
      userEmail: string | null | undefined;
      userName: string | null | undefined;
      userPhone: string | null | undefined;
      userTitle: string | null | undefined;
    }
  >(
    async ({ address, companyType, companyName, userEmail, userName, userPhone, userTitle }) => {
      return await graphQLClient.request(CREATE_BUTTER_COMPANY_AND_USER, {
        address,
        companyType,
        companyName,
        userEmail,
        userName,
        userPhone,
        userTitle,
      });
    },
    {
      onSuccess: () => {},
      onError: (e) => {
        toast({
          // @ts-expect-error
          title: e.response?.errors
            // @ts-expect-error
            ?.map((e) => e.message)
            .join(", ")
            .slice(0, -1),
          description: "Please try again",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
      },
    }
  );

  // update participant
  const mutateUpdateParticipant = useMutation<
    unknown,
    unknown,
    {
      conversationId: string;
      participantId: string;
      channelType: string;
      email: string;
      name: string;
      phoneNumber: string;
      userId: string;
      type: string;
    }
  >(
    async ({ conversationId, participantId, channelType, email, name, phoneNumber, userId, type }) => {
      return await graphQLClient.request(UPDATE_CONVERSATION_PARTICIPANT, {
        conversationId,
        participantId,
        channelType,
        email,
        name,
        phoneNumber,
        userId: userId,
        type,
      });
    },
    {
      onSuccess: () => {},
      onError: (e) => {
        toast({
          // @ts-expect-error
          title: e.response?.errors
            // @ts-expect-error
            ?.map((e) => e.message)
            .join(", ")
            .slice(0, -1),
          description: "Please try again",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
      },
    }
  );

  // delete participant
  const mutateDeleteParticipant = useMutation<
    unknown,
    unknown,
    {
      conversationId: string;
      participantId: string;
    }
  >(
    async ({ conversationId, participantId }) => {
      return await graphQLClient.request(DELETE_CONVERSATION_PARTICIPANT, {
        conversationId,
        participantId,
      });
    },
    {
      onSuccess: () => {},
      onError: (e) => {
        toast({
          // @ts-expect-error
          title: e.response?.errors
            // @ts-expect-error
            ?.map((e) => e.message)
            .join(", ")
            .slice(0, -1),
          description: "Please try again",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
      },
    }
  );

  // update user
  const mutateUpdateButterUser = useMutation<
    unknown,
    unknown,
    {
      userId: string | null;
      userEmail: string | null | undefined;
      userName: string;
      userPhone: string | null | undefined;
      userTitle: string;
    }
  >(
    async ({ userId, userEmail, userName, userPhone, userTitle }) => {
      return await graphQLClient.request(UPDATE_BUTTER_USER, {
        userId,
        userName,
        userEmail,
        userPhone,
        isActive: true,
        userTitle,
      });
    },
    {
      onSuccess: () => {},
      onError: (e) => {
        toast({
          // @ts-expect-error
          title: e.response?.errors
            // @ts-expect-error
            ?.map((e) => e.message)
            .join(", ")
            .slice(0, -1),
          description: "Please try again",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
      },
    }
  );

  // delete user - update to false active
  const mutateDeleteButterUser = useMutation<
    unknown,
    unknown,
    {
      userId: string | null;
    }
  >(
    async ({ userId }) => {
      return await graphQLClient.request(DELETE_BUTTER_USER, {
        userId,
      });
    },
    {
      onSuccess: () => {},
      onError: (e) => {
        toast({
          // @ts-expect-error
          title: e.response?.errors
            // @ts-expect-error
            ?.map((e) => e.message)
            .join(", ")
            .slice(0, -1),
          description: "Please try again",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
      },
    }
  );
  return (
    <Tr
      _hover={{
        bgColor: "gray.50",
        boxShadow: "0 1px 8px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Td>
        <HStack>
          <Avatar name={user?.userName} size="2xs" />
          <Input
            value={displayName ?? ""}
            onChange={(e) => setDisplayName(e.target.value)}
            placeholder="Display Name"
            size="sm"
            variant="flushed"
          />
        </HStack>
      </Td>
      <Td>
        <HStack>
          <Input
            value={userTitle ?? ""}
            onChange={(e) => setUserTitle(e.target.value)}
            placeholder="User Title"
            size="sm"
            w="20"
            variant="flushed"
          />
        </HStack>
      </Td>
      {/* <Td>
        <Menu>
          <MenuButton as={Button} variant="unstyled" isDisabled={user?.userRole !== "SALESPERSON"}>
            <Text textAlign="center" as="span">
              {channelType == "All" ? "-" : channelType}
            </Text>
            <Icon as={ChevronDownIcon} boxSize="3" ml="2" />
          </MenuButton>
          <MenuList>
            {["Voicemail", "Email", "SMS", "GrubAssist"].map((elem, index) => (
              <MenuItem onClick={() => setChannelType(elem)} key={index}>
                {elem}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Td> */}
      <Td>
        <Input
          value={phoneNumber ?? ""}
          as={InputMask}
          mask="+1 (999) 999-9999"
          onChange={(e) => e.target.value !== "+1 (___) ___-____" && setPhoneNumber(e.target.value)}
          placeholder="Phone Number"
          size="xs"
          w="28"
          variant="flushed"
        />
        {phoneNumber && (
          <Tooltip
            label={`${phoneNumber} is now identifiable for voicemail orders of ${isBuyer ? customer?.customerName : companyName}`}
            fontSize="md"
            placement="top"
          >
            <Badge variant="subtle" colorScheme="blue" fontSize="8" ml="2">
              Voicemail
            </Badge>
          </Tooltip>
        )}
      </Td>
      <Td>
        <HStack>
          <Input
            value={emailAddress ?? ""}
            onChange={(e) => setEmailAddress(e.target.value)}
            placeholder="Email Address"
            size="sm"
            w="48"
            variant="flushed"
            isTruncated
            // isDisabled={user?.userRole !== "SALESPERSON"}
          />
          {emailAddress && (
            <Tooltip
              label={`${emailAddress} is now identifiable for email orders of ${isBuyer ? customer?.customerName : companyName} once configured to forward order emails copies to ai@butterapp.io`}
              fontSize="md"
              placement="top"
            >
              <Badge variant="subtle" colorScheme="blue" fontSize="8" ml="2">
                Email
              </Badge>
            </Tooltip>
          )}
        </HStack>
      </Td>

      <Td>
        <Button
          aria-label="Add/Update"
          _focusVisible={{ boxShadow: "none" }}
          w="20"
          h="6"
          onClick={handleSave}
          gap="1"
          alignItems="center"
          colorScheme={isValid ? "primary" : "gray"}
          borderRadius="full"
          isDisabled={!isValid || isSaved}
          isLoading={isLoading}
          fontSize="xs"
        >
          {isSaved && <Icon as={HiCheck} boxSize="4" color="inherit" />}
          {isSaved ? (isUpdate ? "Updated!" : "Added!") : isUpdate ? "Update" : "Add"}
        </Button>
        <IconButton
          variant="unstyled"
          aria-label="Delete"
          icon={<Icon as={HiOutlineTrash} boxSize="4" color="gray.400" _hover={{ color: "gray.800" }} />}
          onClick={() => {
            if (user) {
              onAlertOpen();
            } else {
              setAddRows &&
                currIndex !== undefined &&
                setAddRows((prev) => [...prev.slice(0, currIndex).concat(prev.slice(currIndex + 1))]);
              return;
            }
          }}
          _focusVisible={{ boxShadow: "none" }}
          w="12"
          pt="1"
        />
      </Td>
      <AlertDialog isCentered isOpen={isAlertOpen} leastDestructiveRef={cancelAlertRef} onClose={onAlertClose}>
        <AlertDialogOverlay>
          <AlertDialogContent w="90%">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>
            <AlertDialogBody>Are you sure to delete {displayName || phoneNumber || emailAddress}? </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelAlertRef} onClick={onAlertClose}>
                Cancel
              </Button>
              <Button colorScheme="red" ml={3} onClick={handleDelete}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Tr>
  );
}
