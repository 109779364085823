import { searchPriceSheetByCustomerId } from "@/api/queries/price-sheet";
import queryClient from "@/api/query-client";
import { ProductInfoMap, PriceSheetItem } from "@/utils/types";
import { useState } from "react";

const useProductInfo = (supplierId: string, customerId: string) => {
  const [data, setData] = useState<ProductInfoMap>({});
  const [idList, setIdList] = useState<string[]>([]);
  const fetchData = async (ids: string[]) => {
    const productIds = Array.from(new Set(ids)).filter((id) => !idList.includes(id));
    if (!productIds?.length) return;
    const filterIds = productIds.join(",");
    const fetchSuggestions = searchPriceSheetByCustomerId(supplierId, customerId, "", filterIds, 0, 200, false);
    const { products }: { products: PriceSheetItem[] } = (await queryClient.fetchQuery(fetchSuggestions)) ?? {};
    if (products?.length) {
      setData((prevState) => {
        products?.forEach((item) => {
          prevState[item.id] = item;
          setIdList([...idList, item.id]);
        });
        return prevState;
      });
    }
  };
  return { data, fetchData };
};

export default useProductInfo;
