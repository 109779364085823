import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  List,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useNumberInput,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Params, useLoaderData, useNavigation } from "react-router-dom";
import DetailRow from "@/components/items/detail-row";
import { queryOrderDetailByOrderId } from "@/api/queries/order-detail";
import queryClient from "@/api/query-client";
import { OrderDetail } from "@/utils/types";
import { getUserCompany } from "@/utils/user";
import { formatTitle } from "@/utils/formatter";
import { HiPlus } from "react-icons/hi";

export async function loader({ params }: { params: Params }) {
  const {
    company: { companyId, companyName },
  } = getUserCompany();
  const { orderId } = params as { orderId: string };
  const fetchOrderDetail = queryOrderDetailByOrderId(companyId, orderId);
  const { order }: { order: OrderDetail } = (await queryClient.fetchQuery(fetchOrderDetail)) ?? {};
  return { order, companyName };
}

export default function OrderEditor() {
  const { state } = useNavigation();
  const [lineItems, setLineItems] = useState(null);
  const { order } = useLoaderData() as { order: OrderDetail };

  return (
    <Box mt="6" mb="4" borderRadius="lg" borderColor="gray.100" borderWidth="thin">
      <HStack
        w="full"
        justifyContent="space-between"
        h={{ base: "16", lg: "12" }}
        borderBottomColor="gray.100"
        borderBottomWidth="thin"
        pl="8"
        pr="4"
      >
        <Text fontWeight="semibold" color="primary.600">
          {order.lineItems.length} products
        </Text>
        <AddLineItem orderId={order.id} isAddable={!["Cancelled", "Confirmed"].includes(order.status)} />
      </HStack>
      <HStack
        spacing="8"
        w="full"
        h={{ base: "16", lg: "12" }}
        borderBottomColor="gray.100"
        borderBottomWidth="thin"
        pl="8"
        pr="4"
      >
        {["productName", "originalQuantity", "updatedQuantity", "unitPrice", "total", "-"].map((elem) => (
          <Text
            fontWeight="medium"
            fontSize="xs"
            w={elem == "productName" ? "72" : "32"}
            textAlign={elem == "productName" ? "left" : "center"}
            key={elem}
          >
            {formatTitle(elem)}
          </Text>
        ))}
      </HStack>
      <List h="calc(100vh - 16rem)" overflowY="auto">
        {state == "loading" && (
          <Box
            position="absolute"
            top="0"
            right="0"
            bottom="0"
            left="0"
            backgroundColor="rgba(255, 255, 255, 0.7)"
            zIndex="9999"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="primary.500" size="xl" />
          </Box>
        )}
        {order.lineItems.map((elem, index) => (
          <DetailRow
            item={elem}
            isFirst={index == 0}
            isEditable={!["Cancelled", "Confirmed"].includes(order.status)}
            key={index}
          />
        ))}
      </List>
    </Box>
  );
}

function AddLineItem({ orderId, isAddable }: { orderId: number; isAddable: boolean }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);

  const [qty, setQty] = useState<number | null>(null);
  return (
    <>
      <Button onClick={onOpen} w="32" colorScheme="green" isDisabled={!isAddable}>
        <Icon as={HiPlus} boxSize="4" mr="1" />
        Add Line Item
      </Button>

      <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
        <ModalContent>
          <ModalHeader>Add an item to order #{orderId}</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody py="6">
            <HStack>
              <FormControl>
                <FormLabel>Quantity</FormLabel>
                <ItemQuantity setQty={setQty} />
              </FormControl>

              <FormControl>
                <FormLabel>Select an Item</FormLabel>
                <Input placeholder="select" />
              </FormControl>
            </HStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" mr="2" w="24">
              Save
            </Button>
            <Button onClick={onClose} w="24">
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function ItemQuantity({ setQty }: { setQty: Dispatch<SetStateAction<number | null>> }) {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step: 1,
    defaultValue: 0,
    min: 0,
    // precision: 2,
  });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  useEffect(() => {
    setQty(parseInt(input.value));
  }, [input.value]);

  return (
    <HStack maxW="40">
      <Button {...inc}>+</Button>
      <Input {...input} />
      <Button {...dec}>-</Button>
    </HStack>
  );
}
