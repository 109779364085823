import graphQLClient from "@/api/graphql-client";
import { ADD_ORDER_MESSAGE } from "@/api/mutations/message";
import { CREATE_MESSAGE_MEDIA_URL } from "@/api/mutations/order";
import {
  useDisclosure,
  useToast,
  Button,
  Icon,
  FormControl,
  Textarea,
  HStack,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  PopoverBody,
  PopoverHeader,
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  Center,
  Box,
  VStack,
  Text,
  CircularProgress,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useRef, useState, useCallback } from "react";
import { HiPencil } from "react-icons/hi";
import { useDropzone } from "react-dropzone";
import { AiFillFileAdd } from "react-icons/ai";
import uploadFile from "@/api/upload";

export default function OrderMessage({
  orderId,
  customerName,
  isAddable,
  onMessageAdded,
}: {
  orderId: number;
  customerName: string;
  isAddable: boolean;
  onMessageAdded: (message: unknown) => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const textRef = useRef(null);
  const [orderMessage, setOrderMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const toast = useToast();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      if (!file.type.startsWith("audio/")) {
        console.log("Not an audio file");
        return;
      }
      console.log(file);
      setSelectedFile(file);
      console.log("Uploading...", file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "audio/*": [],
    },
    maxFiles: 1,
    multiple: false,
  });

  // add order message
  const mutateAddOrderMessage = useMutation<unknown, unknown, { orderId: number; content: string; type: string }>(
    async ({ orderId, content, type }) => {
      return await graphQLClient.request(ADD_ORDER_MESSAGE, {
        orderId,
        content,
        type,
      });
    }
  );

  // create message media url
  const mutateCreateMediaUrl = useMutation<unknown, unknown, { filename: string }>(async ({ filename }) => {
    return await graphQLClient.request(CREATE_MESSAGE_MEDIA_URL, {
      filename,
    });
  });

  async function handleSaveMessage() {
    try {
      setIsLoading(true);
      if (tabIndex == 0) {
        const res: any = await mutateAddOrderMessage.mutateAsync({
          orderId,
          content: orderMessage,
          type: "Text",
        });
        onMessageAdded(res?.ai?.create_ai_order_message);
      } else {
        const urlRes = await mutateCreateMediaUrl.mutateAsync({ filename: selectedFile!.name });
        // @ts-expect-error
        const { upload_url, download_url } = urlRes?.ai.create_message_media_url;
        // upload media
        await uploadFile(selectedFile!, upload_url, (progress) => {
          setProgress(Math.round(progress));
        });
        const res: any = await mutateAddOrderMessage.mutateAsync({
          orderId,
          content: download_url,
          type: "AudioUrl",
        });
        onMessageAdded(res?.ai?.create_ai_order_message);
      }
      toast({
        title: "Message added!",
        description: `You have successfully added a message to order ${orderId} for ${customerName}`,
        status: "info",
        duration: 3000,
        isClosable: true,
        onCloseComplete: () => {},
      });
    } catch (err) {
      console.log(err);
      toast({
        title: "Failed to save the message",
        description: `There has been an error saving this message to order ${orderId} for ${customerName}, try again later.`,
        status: "error",
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => {},
      });
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async () => {
    await handleSaveMessage();
    handleClose();
  };

  const handleClose = () => {
    setOrderMessage("");
    setTabIndex(0);
    setProgress(0);
    setSelectedFile(null);
    onClose();
  };

  return (
    <>
      <Popover
        onClose={handleClose}
        isOpen={isOpen}
        onOpen={() => {
          setOrderMessage("");
          onOpen();
        }}
        initialFocusRef={textRef}
        placement="top"
      >
        <PopoverTrigger>
          <Button
            leftIcon={<Icon as={HiPencil} boxSize="4" />}
            onClick={onOpen}
            variant="outline"
            colorScheme="green"
            isDisabled={!isAddable}
            size="xs"
            _focusVisible={{ outline: "none" }}
            mx="auto"
            borderRadius="md"
          >
            Add Message
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader fontWeight="medium">A message for order #{orderId}</PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody px="2" w={["full", "20rem"]}>
            <Center>
              <Tabs
                index={tabIndex}
                size="xs"
                variant="unstyled"
                onChange={(index) => {
                  setTabIndex(index);
                }}
              >
                <TabList w="200px" borderWidth="thin" borderRadius="md" borderColor="primary.500">
                  {["Text", "Voicemail"].map((option, index) => (
                    <Tab
                      key={index}
                      borderRightWidth={index == 0 ? "thin" : "0"}
                      borderRightColor="primary.500"
                      color="gray.600"
                      bgColor="white"
                      _hover={{ color: "gray.600", bgColor: "primary.50" }}
                      _selected={{ color: "white", bgColor: "primary.400" }}
                      fontSize="xs"
                      borderLeftRadius={index == 0 ? "md" : "none"}
                      borderRightRadius={index == 1 ? "md" : "none"}
                      w="32"
                      fontWeight="medium"
                    >
                      {option}
                    </Tab>
                  ))}
                </TabList>
                <TabIndicator />
              </Tabs>
            </Center>
            {tabIndex == 0 ? (
              <FormControl mt="2">
                <Textarea
                  value={orderMessage}
                  onChange={(e) => setOrderMessage(e.target.value)}
                  placeholder="Enter an order message"
                  size="sm"
                  ref={textRef}
                />
              </FormControl>
            ) : (
              <FormControl mt="2">
                <Center
                  py={5}
                  px={2}
                  cursor="pointer"
                  bg={isDragActive ? "gray.50" : "transparent"}
                  _hover={{ bg: "gray.50" }}
                  transition="background-color 0.2s ease"
                  borderRadius={4}
                  border="1px dashed"
                  borderColor="gray.300"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <Box>
                    {selectedFile ? (
                      <VStack>
                        <HStack>
                          <Icon as={AiFillFileAdd} mr={2} w={4} h={4} color="primary.500" />
                          <Text>Selected File: {selectedFile.name}</Text>
                        </HStack>
                        {progress > 0 && (
                          <HStack alignItems="center">
                            <Text fontSize="xs" color="primary.500">
                              Upload progress: {progress}%
                            </Text>
                            <CircularProgress value={progress} size="20px" color="primary.500" thickness="10px" />
                          </HStack>
                        )}
                      </VStack>
                    ) : isDragActive ? (
                      <HStack h="16">
                        <Icon as={AiFillFileAdd} w={4} h={4} color="gray.200" />
                        <Text fontSize="xs">Drop the file here...</Text>
                      </HStack>
                    ) : (
                      <HStack h="16">
                        <Icon as={AiFillFileAdd} w={4} h={4} color="gray.200" />
                        <Text fontSize="xs">
                          Drag and drop the audio file here, or click to browse and select the file
                        </Text>
                      </HStack>
                    )}
                  </Box>
                </Center>
              </FormControl>
            )}
            <HStack justifyContent="end" mt="2">
              <Button
                colorScheme="primary"
                w="16"
                onClick={handleSubmit}
                isLoading={isLoading}
                isDisabled={tabIndex === 0 ? !orderMessage : !selectedFile}
                size="sm"
              >
                Save
              </Button>
              <Button onClick={handleClose} w="16" size="sm">
                Cancel
              </Button>
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
}
