import { createContext, useContext } from "react";

interface SidebarContextValue {
  isOpen: boolean;
  onToggle: () => void;
}

const SidebarContext = createContext<SidebarContextValue>({
  isOpen: false,
  onToggle: () => {},
});

export function useSidebarContext(): SidebarContextValue {
  return useContext(SidebarContext);
}

export default SidebarContext;
