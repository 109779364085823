import { formatTitle } from "@/utils/formatter";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  HStack,
  Icon,
  Progress,
  Text,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { HiAnnotation } from "react-icons/hi";
import { useNavigate, useLocation, useNavigation } from "react-router-dom";

export default function ProgressCrumb(): ReactNode {
  const { state } = useNavigation();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const currentPage = pathname.split("/")[1]; // orders, or customers
  const activeTab = pathname.split("/").pop(); // analyzer, summary, or conversation id
  const orderNumber = pathname.split("/")[2]; // order number, or customer id

  return (
    <Box>
      <Box h="2" w="full">
        {state == "loading" && <Progress size="xs" isIndeterminate borderRadius="none" />}
      </Box>

      <HStack ml="6">
        <Breadcrumb fontWeight="medium" fontSize="md" display={{ base: "none", md: "block" }}>
          {pathname.split("/")?.length > 1 && (
            <BreadcrumbItem color="gray.800">
              <BreadcrumbLink
                as={Flex}
                alignItems="center"
                color="gray.800"
                _hover={{ cursor: "default", textDecor: "none" }}
              >
                <Icon as={HiAnnotation} boxSize="6" color="gray.800" />
                <Text ml="2" color="gray.800">
                  AI Orders
                </Text>
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}

          {pathname.split("/")?.length > 1 && (
            <BreadcrumbItem>
              <BreadcrumbLink
                as={Button}
                variant="link"
                onClick={() => {
                  navigate(`/${currentPage}`);
                }}
              >
                {formatTitle(currentPage)}
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}

          {pathname.split("/")?.length > 2 && (
            <BreadcrumbItem>
              <BreadcrumbLink
                as={Button}
                variant="link"
                onClick={() => {
                  navigate(`/${currentPage}/${orderNumber}/${activeTab}`);
                }}
              >
                {orderNumber}
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}

          {/* {pathname.split("/")?.length > 3 && (
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink
                as={Button}
                variant="link"
                onClick={() => {
                  navigate(`/${currentPage}/${orderNumber}/${activeTab}`);
                }}
              >
                {formatTitle(activeTab)}
              </BreadcrumbLink>
            </BreadcrumbItem>
          )} */}
        </Breadcrumb>
      </HStack>
    </Box>
  );
}
