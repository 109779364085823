import { createContext, ReactNode, useState, useEffect, useContext, Dispatch, SetStateAction } from "react";

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt: () => Promise<void>;
}

const PromptInstallContext = createContext<{
  promptable: BeforeInstallPromptEvent | null;
  promptToInstall: () => void;
  isInstalled: boolean;
  isHide: boolean;
  setIsHide: Dispatch<SetStateAction<boolean>>;
}>({
  promptable: null,
  promptToInstall: () => {},
  isInstalled: false,
  isHide: false,
  setIsHide: () => {},
});

export function usePromptInstallContext(): {
  promptable: BeforeInstallPromptEvent | null;
  promptToInstall: () => void;
  isInstalled: boolean;
  isHide: boolean;
  setIsHide: Dispatch<SetStateAction<boolean>>;
} {
  return useContext(PromptInstallContext);
}

export default function PromptInstallProvider({ children }: { children: ReactNode }): ReactNode {
  const [promptable, setPromptable] = useState<BeforeInstallPromptEvent | null>(null);

  const [isInstalled, setIsInstalled] = useState(false);

  const [isHide, setIsHide] = useState(false);

  const promptToInstall = async (): Promise<any> => {
    if (promptable) {
      await promptable.prompt();
      return;
    }
    return await Promise.reject(new Error('Tried installing before browser sent "beforeinstallprompt" event'));
  };

  useEffect(() => {
    const ready = (e: BeforeInstallPromptEvent): void => {
      e.preventDefault();
      setPromptable(e);
    };

    window.addEventListener("beforeinstallprompt", ready as any);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready as any);
    };
  }, []);

  useEffect(() => {
    const onInstall = (): void => {
      setIsInstalled(true);
    };

    window.addEventListener("appinstalled", onInstall as any);

    return () => {
      window.removeEventListener("appinstalled", onInstall as any);
    };
  }, []);

  return (
    <PromptInstallContext.Provider
      value={{
        promptable,
        promptToInstall,
        isInstalled,
        isHide,
        setIsHide,
      }}
    >
      {children}
    </PromptInstallContext.Provider>
  );
}
