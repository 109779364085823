import { useEffect } from "react";
import { useAuth, isIos, isSafari } from "@butter-technologies/dish";
import InstallPrompt from "@/components/items/install-prompt";
import { butterManifest } from "@/config/pwa-manifest";
import { usePWA } from "@/providers/pwa-context";

// @ts-ignore: This will only show on iosSafari so is not registered on WorkerNavigator, but will be handled perfectly well if not detected
const pwaInstalledOnIos = window.navigator?.standalone === true;

// Set Butter Manifest on page load
const initialManifest = JSON.stringify(butterManifest);
const blob = new Blob([initialManifest], { type: "application/json" });

const manifestURL = URL.createObjectURL(blob);
document.querySelector("#manifest-placeholder")?.setAttribute("href", manifestURL);

export default function PWASupport() {
  const auth = useAuth();

  const {
    showIosInstallPrompt,
    onShowIosInstallPrompt,
    closeIosInstallPrompt,
    iosInstallPromptShown,
    setIosInstallPromptShown,
  } = usePWA();

  // iOS Install Prompt Loading
  useEffect(() => {
    // The install prompt needs to be on Safari iOS (iPad or iPhone), as it's e
    const isAuthenticated = auth?.user;
    const iosSafari = isIos && isSafari;

    if (isAuthenticated && iosSafari && !pwaInstalledOnIos && !iosInstallPromptShown) {
      onShowIosInstallPrompt();
      setIosInstallPromptShown(true);
    }
  }, [isIos, auth, isSafari, iosInstallPromptShown]);

  return <InstallPrompt isOpen={showIosInstallPrompt} onClose={closeIosInstallPrompt} />;
}
