import graphQLClient from "@/api/graphql-client";
import InputMask from "react-input-mask";
import { UPDATE_CONVERSATION_PARTICIPANT } from "@/api/mutations/participant";
import { CREATE_BUTTER_COMPANY_AND_USER, CREATE_BUTTER_USER } from "@/api/mutations/user";
import { formatPhoneNumber } from "@/utils/validation";
import { FormLabel, FormControl, Input, InputGroup, InputLeftElement, useToast, Button } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Customer, OrderDetail, CreateButterCompanyAndUserResponse, CreateButterUserResponse } from "@/utils/types";

export default function ContactForm({
  order,
  customer,
  participant,
  textRef,
  goNextPage,
}: {
  order: OrderDetail;
  customer: Customer | null;
  participant: {
    displayName?: string | null;
    emailAddress?: string | null;
    phoneNumber?: string | null;
    isUser?: boolean;
  };
  textRef: React.MutableRefObject<HTMLTextAreaElement | null>;
  goNextPage: ({ customerCompanyId }: { customerCompanyId?: string }) => void;
}) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleInputSubmit = async (): Promise<{ customerCompanyId: string } | undefined> => {
    let resultUser: CreateButterCompanyAndUserResponse | CreateButterUserResponse | null = null;
    let userId: string | null = null;
    try {
      setIsLoading(true);

      if (!customer?.companyId) {
        // create butter company and user
        resultUser = await mutateCreateButterCompanyAndUser.mutateAsync({
          // @ts-expect-error
          address: customer?.shippingAddress ?? customer?.billingAddress,
          companyType: "BUYER",
          companyName: customer?.customerName ?? "",
          userName: userName ?? "",
          userPhone: participant.phoneNumber ? participant.phoneNumber : formatPhoneNumber(phoneNumber ?? ""),
          userEmail: participant.emailAddress ? participant.emailAddress : emailAddress ?? "",
          userTitle: "",
        });
        userId =
          resultUser?.butter?.create_butter_company_and_user?.users?.find(
            (elem: { name: string; pid: string }) => elem.name === userName
          )?.pid ?? null;

        if (resultUser?.errors) {
          toast({
            title: `Unable to add a contact`,
            description: resultUser?.errors,
            status: "error",
            duration: 5000,
            isClosable: true,
            onCloseComplete: () => {},
          });
          return;
        } else if (!resultUser?.butter?.create_butter_company_and_user) {
          toast({
            title: `Unable to add a contact`,
            description:
              "Please check if you have a valid email or phone number, or if there is already a conversation in progress.",
            status: "error",
            duration: 5000,
            isClosable: true,
            onCloseComplete: () => {},
          });
          return;
        }
      } else {
        // create butter user when there is a butter company
        resultUser = await mutateCreateButterUser.mutateAsync({
          companyId: customer.companyId,
          userName: userName ?? "",
          userPhone: participant.phoneNumber ? participant.phoneNumber : formatPhoneNumber(phoneNumber ?? ""),
          userEmail: participant.emailAddress ? participant.emailAddress : emailAddress ?? "",
          userTitle: "",
        });
        userId = resultUser?.butter?.create_butter_user?.pid;

        if (resultUser?.errors) {
          toast({
            title: `Unable to add a contact`,
            description: resultUser?.errors,
            status: "error",
            duration: 5000,
            isClosable: true,
            onCloseComplete: () => {},
          });
          return;
        } else if (!resultUser?.butter?.create_butter_user) {
          toast({
            title: `Unable to add a contact`,
            description:
              "Please check if you have a valid email or phone number, or if there is already a conversation in progress.",
            status: "error",
            duration: 5000,
            isClosable: true,
            onCloseComplete: () => {},
          });
          return;
        }
      }

      // update participant
      const resultParticipant = await mutateUpdateConversationParticipant.mutateAsync({
        // @ts-expect-error
        conversationId: Number(order?.messages[0]?.participant?.conversationId),
        // @ts-expect-error
        participantId: Number(order?.messages[0]?.participant?.participantId),
        // @ts-expect-error
        channelType: order?.messages[0]?.participant?.channelType,
        email: participant.emailAddress ? participant.emailAddress : emailAddress ?? "",
        name: userName ?? "",
        phoneNumber: participant.phoneNumber ? participant.phoneNumber : formatPhoneNumber(phoneNumber ?? ""),
        userId,
        type: "BUYER",
      });

      toast({
        title: `You have added ${userName || emailAddress || phoneNumber} to this customer.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
      //@ts-expect-error
      return resultUser?.butter?.create_butter_company_and_user?.pid;
    }
  };

  // create user
  const mutateCreateButterUser = useMutation<
    CreateButterUserResponse,
    unknown,
    {
      companyId: string;
      userEmail: string | null | undefined;
      userName: string;
      userPhone: string | null | undefined;
      userTitle: string;
    }
  >(
    async ({ companyId, userEmail, userName, userPhone, userTitle }) => {
      return await graphQLClient.request(CREATE_BUTTER_USER, {
        companyId,
        userName,
        userPhone,
        userEmail,
        userTitle,
      });
    },
    {
      onSuccess: () => {},
      onError: (e) => {
        toast({
          // @ts-expect-error
          title: e.response?.errors
            // @ts-expect-error
            ?.map((e) => e.message)
            .join(", ")
            .slice(0, -1),
          description: "Please try again",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
      },
    }
  );
  // create company and user
  const mutateCreateButterCompanyAndUser = useMutation<
    CreateButterCompanyAndUserResponse,
    unknown,
    {
      address: string;
      companyType: string;
      companyName: string;
      userEmail: string | null | undefined;
      userName: string | null | undefined;
      userPhone: string | null | undefined;
      userTitle: string | null | undefined;
    }
  >(
    async ({ address, companyType, companyName, userEmail, userName, userPhone, userTitle }) => {
      return await graphQLClient.request(CREATE_BUTTER_COMPANY_AND_USER, {
        address,
        companyType,
        companyName,
        userEmail,
        userName,
        userPhone,
        userTitle,
      });
    },
    {
      onSuccess: () => {},
      onError: (e) => {
        toast({
          // @ts-expect-error
          title: e.response?.errors
            // @ts-expect-error
            ?.map((e) => e.message)
            .join(", ")
            .slice(0, -1),
          description: "Please try again",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
      },
    }
  );

  // update participant
  const mutateUpdateConversationParticipant = useMutation<
    unknown,
    {
      errors?: { message: string }[];
    },
    {
      conversationId: string;
      participantId: string;
      channelType: string;
      email: string;
      name: string;
      phoneNumber: string;
      userId: string | null;
      type: string;
    }
  >(
    async ({ conversationId, participantId, channelType, email, name, phoneNumber, userId, type }) => {
      return await graphQLClient.request(UPDATE_CONVERSATION_PARTICIPANT, {
        conversationId,
        participantId,
        channelType,
        email,
        name,
        phoneNumber,
        userId,
        type,
      });
    },
    {
      onSuccess: () => {},
      onError: (e) => {
        toast({
          // @ts-expect-error
          title: e.response?.errors
            // @ts-expect-error
            ?.map((e) => e.message)
            .join(", ")
            .slice(0, -1),
          description: "Please try again",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
      },
    }
  );

  return (
    <>
      <FormLabel fontSize="2xs" ml="1">
        Enter the full name (optional)
      </FormLabel>
      <FormControl mt="2">
        <Input
          type="name"
          onChange={(e) => setUserName(e.target.value)}
          size="sm"
          // @ts-expect-error
          ref={textRef}
          colorScheme="cyan"
        />
      </FormControl>
      <FormLabel fontSize="2xs" ml="1" mt="4">
        {participant.phoneNumber ? "We have detected a phone number below" : "Enter the phone number (optional)"}
      </FormLabel>
      <FormControl mt="2">
        <InputGroup>
          <InputLeftElement pointerEvents="none" color="gray.600" fontSize="sm" children="+1" />
          <Input
            as={InputMask}
            mask="(999) 999-9999"
            maskPlaceholder=""
            type="tel"
            value={participant.phoneNumber ? participant.phoneNumber.replace(/^(\+1|1)/, "") : phoneNumber}
            isDisabled={participant.phoneNumber !== null}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </InputGroup>
      </FormControl>
      <FormLabel fontSize="2xs" ml="1" mt="4">
        {participant.emailAddress ? "We have detected an email address below" : "Enter the email address (optional)"}
      </FormLabel>
      <FormControl id="email" mb="2">
        <Input
          type="email"
          pattern=".+@globex\.com"
          value={participant.emailAddress ? participant.emailAddress : emailAddress}
          isDisabled={participant.emailAddress !== null}
          onChange={(e) => setEmailAddress(e.target.value)}
        />
      </FormControl>
      <Button
        colorScheme="cyan"
        bgColor="cyan.200"
        w="full"
        onClick={async () => {
          const customerCompanyId = await handleInputSubmit();
          // @ts-expect-error
          goNextPage({ customerCompanyId: customerCompanyId && customerCompanyId !== "" ? customerCompanyId : null });
        }}
        isLoading={isLoading}
        isDisabled={!customer}
        size="sm"
      >
        Add a Contact
      </Button>
    </>
  );
}
