import { useDisclosure } from "@chakra-ui/react";
import { useLocalStorage } from "@saas-ui/react";
import { noop } from "lodash";
import { PropsWithChildren, createContext, useContext, useMemo } from "react";

interface PWAContext {
  showIosInstallPrompt: boolean;
  onShowIosInstallPrompt: () => void;
  iosInstallPromptShown: boolean;
  setIosInstallPromptShown: (shown: boolean) => void;
  closeIosInstallPrompt: () => void;
}

export const PWAContext = createContext<PWAContext>({
  showIosInstallPrompt: false,
  onShowIosInstallPrompt: noop,
  iosInstallPromptShown: false,
  setIosInstallPromptShown: noop,
  closeIosInstallPrompt: noop,
});

export function PWAContextProvider({ children }: PropsWithChildren) {
  const [iosInstallPromptShown, setIosInstallPromptShown] = useLocalStorage("iosInstallPromptShown", false);
  const {
    isOpen: showIosInstallPrompt,
    onOpen: onShowIosInstallPrompt,
    onClose: closeIosInstallPrompt,
  } = useDisclosure();

  const value = useMemo(
    () => ({
      showIosInstallPrompt,
      onShowIosInstallPrompt,
      closeIosInstallPrompt,
      iosInstallPromptShown,
      setIosInstallPromptShown,
    }),
    [
      showIosInstallPrompt,
      setIosInstallPromptShown,
      closeIosInstallPrompt,
      iosInstallPromptShown,
      setIosInstallPromptShown,
    ]
  );

  return <PWAContext.Provider value={value}>{children}</PWAContext.Provider>;
}

export const usePWA = () => useContext(PWAContext);
