import { gql } from "graphql-request";

export const CREATE_BUTTER_USER = gql`
  mutation CreateButterUser(
    $companyId: String!
    $userEmail: String
    $userName: String
    $userPhone: String
    $userTitle: String
  ) {
    butter {
      create_butter_user(
        company_pid: $companyId
        user_email: $userEmail
        user_name: $userName
        user_phone: $userPhone
        user_role: SALESPERSON
        user_title: $userTitle
      ) {
        pid
        name
        phone_number
        email
      }
    }
  }
`;

export const UPDATE_BUTTER_USER = gql`
  mutation UpdateButterUser(
    $userId: String!
    $userEmail: String
    $userName: String
    $userPhone: String
    $isActive: Boolean
    $userTitle: String
  ) {
    butter {
      update_butter_user(
        pid: $userId
        active: $isActive
        user_email: $userEmail
        user_name: $userName
        user_phone: $userPhone
        user_role: SALESPERSON
        user_title: $userTitle
      ) {
        pid
        name
        phone_number
        email
      }
    }
  }
`;

export const DELETE_BUTTER_USER = gql`
  mutation DeleteButterUser($userId: String!) {
    butter {
      delete_butter_user(pid: $userId)
    }
  }
`;

export const CREATE_BUTTER_COMPANY_AND_USER = gql`
  mutation CreateButterCompanyAndUser(
    $address: AddressInput!
    $companyType: CompanyType!
    $companyName: String
    $userEmail: String
    $userName: String
    $userPhone: String
    $userTitle: String
  ) {
    butter {
      create_butter_company_and_user(
        address: $address
        company_type: $companyType
        user_email: $userEmail
        user_name: $userName
        user_phone: $userPhone
        user_title: $userTitle
        company_name: $companyName
      ) {
        pid
        name
        users {
          name
          pid
        }
      }
    }
  }
`;
