import { gql } from "graphql-request";

export const CONFIRM_ORDER = gql`
  mutation ConfirmOrder(
    $supplierPid: String!
    $orderId: Int!
    $lineItems: [AI_LineItem_Input]!
    $editedJobs: [AI_app__resource__app_order__schema__TranslationJob_Input]!
    $deliveryDate: String!
    $buyerNote: String!
  ) {
    ai {
      confirm_order(
        input: {
          supplier_pid: $supplierPid
          order_id: $orderId
          line_items: $lineItems
          edited_jobs: $editedJobs
          delivery_date: $deliveryDate
          buyer_note: $buyerNote
        }
      ) {
        ai_order_id
      }
    }
  }
`;

export const UPDATE_DRAFT_ORDER = gql`
  mutation UpdateDraftOrder(
    $supplierPid: String!
    $orderId: Int!
    $lineItems: [AI_LineItem_Input]!
    $editedJobs: [AI_app__resource__app_order__schema__TranslationJob_Input]!
    $deliveryDate: String!
    $buyerNote: String!
  ) {
    ai {
      update_draft_order(
        input: {
          supplier_pid: $supplierPid
          order_id: $orderId
          line_items: $lineItems
          edited_jobs: $editedJobs
          delivery_date: $deliveryDate
          buyer_note: $buyerNote
        }
      )
    }
  }
`;

export const ORDER_DETAIL_WITHOUT_AUTH = gql`
  mutation OrderDetailWithoutAuth($customerId: String!, $orderId: Int!, $supplierId: String!) {
    ai {
      order_detail_without_auth(customer_id: $customerId, order_id: $orderId, supplier_pid: $supplierId) {
        created_ts
        customer_address
        customer_id
        customer_name
        delivery_date
        id
        line_items {
          description
          id
          last_ordered_date
          product_id
          slug
          quantity
          similar_suggestions
          unit
          unit_price
        }
        translation_jobs {
          action
          id
          result
          text
        }
        status
        supplier_pid
        updated_ts
        salt
      }
    }
  }
`;

export const LAST_ORDER_DATE = gql`
  mutation LastOrderDate($supplierId: String!, $customerId: String!, $productIds: [String]!, $orderId: Int!) {
    ai {
      get_last_order_date(
        input: {
          supplier_pid: $supplierId
          customer_id: $customerId
          product_ids: $productIds
          current_order_id: $orderId
        }
      ) {
        items {
          last_order_date
          product_id
        }
      }
    }
  }
`;

export const CREATE_MANUAL_ORDER = gql`
  mutation CreateManualOrder(
    $supplierId: String!
    $customerId: String
    $messages: [AI_CreateManualOrderMessage_Input]!
  ) {
    ai {
      create_ai_manual_order(input: { supplier_pid: $supplierId, customer_id: $customerId, messages: $messages })
    }
  }
`;

export const CANCEL_ORDER = gql`
  mutation CancelOrder($supplierId: String!, $orderId: Int!) {
    ai {
      cancel_order(supplier_pid: $supplierId, order_id: $orderId)
    }
  }
`;

export const SET_ORDER_CUSTOMER = gql`
  mutation SetOrderCustomer(
    $customerId: String!
    $orderId: Int!
    $customerCompanyId: String
    $isGrubAssist: Boolean = false
  ) {
    ai {
      set_order_customer(
        input: {
          customer_id: $customerId
          order_id: $orderId
          customer_company_pid: $customerCompanyId
          is_grub_assist: $isGrubAssist
        }
      )
    }
  }
`;

export const UPDATE_ORDER_CUSTOMER = gql`
  mutation UpdateOrderCustomer($customerId: String!, $orderId: Int!) {
    ai {
      update_ai_order_customer(input: { customer_id: $customerId, order_id: $orderId })
    }
  }
`;

export const RETRY_PENDING_ORDER = gql`
  mutation RetryPendingOrder($orderId: Int!, $supplierPid: String!, $customerId: String!, $customerName: String!) {
    ai {
      retry_ai_pending_order(
        input: {
          order_id: $orderId
          supplier_pid: $supplierPid
          new_customer_id: $customerId
          new_customer_name: $customerName
        }
      ) {
        success
      }
    }
  }
`;

export const CREATE_MESSAGE_MEDIA_URL = gql`
  mutation CreateMessageMediaUrl($filename: String!) {
    ai {
      create_message_media_url(input: { filename: $filename }) {
        upload_url
        download_url
      }
    }
  }
`;

export const REPROCESS_ORDER = gql`
  mutation ReprocessOrder($supplierId: String!, $orderId: Int!) {
    ai {
      reprocess_order(input: { supplier_pid: $supplierId, order_id: $orderId })
    }
  }
`;
