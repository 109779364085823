import { gql } from "graphql-request";
import graphQLClient from "@/api/graphql-client";
import type { CompanyAltCase, QueryError, User } from "@/utils/types";

const ALL_USERS_BY_COMPANY_ID_QUERY = gql`
  query AllUsersByCompanyId($companyId: ID!) {
    butter_company(company_pid: $companyId) {
      users {
        email
        name
        phone_number
        pid
        role
        title
      }
    }
  }
`;

export const queryAllUsersByCompanyId = (
  companyId: string
): {
  queryKey: [string, string];
  queryFn: () => Promise<{ users: User[] } | QueryError>;
} => ({
  queryKey: ["users", companyId],
  queryFn: async (): Promise<{ users: User[] } | QueryError> => {
    try {
      const { butter_company: company }: { butter_company: CompanyAltCase } = await graphQLClient.request(
        ALL_USERS_BY_COMPANY_ID_QUERY,
        {
          companyId,
        }
      );
      const { users: _users } = company;
      const users =
        _users?.map((elem) => ({
          userId: elem.pid,
          userEmail: elem.email,
          userPhone: elem.phone_number,
          userName: elem.name,
          userRole: elem.role,
          userTitle: elem.title,
        })) ?? [];

      return { users };
    } catch (error) {
      return {
        error: {
          message: "An error occurred while querying users: " + String(error),
        },
      };
    }
  },
});

const ALL_USERS_BY_CUSTOMER_ID_QUERY = gql`
  query AllUsersByCustomerId($companyId: ID!, $customerId: ID!) {
    company(company_pid: $companyId) {
      customer(customer_id: $customerId) {
        butter_company {
          users {
            email
            name
            phone_number
            pid
            role
            title
          }
        }
      }
    }
  }
`;

export const queryAllUsersByCustomerId = (
  companyId: string,
  customerId: string
): {
  queryKey: [string, string, string];
  queryFn: () => Promise<{ users: User[] } | QueryError>;
} => ({
  queryKey: ["users", companyId, customerId],
  queryFn: async (): Promise<{ users: User[] } | QueryError> => {
    try {
      const { company }: { company: CompanyAltCase } = await graphQLClient.request(ALL_USERS_BY_CUSTOMER_ID_QUERY, {
        companyId,
        customerId,
      });
      const { users: _users } = company?.customer?.butter_company!;
      const users =
        _users?.map((elem) => ({
          userId: elem.pid,
          userEmail: elem.email,
          userPhone: elem.phone_number,
          userName: elem.name,
          userRole: elem.role,
          userTitle: elem.title,
        })) ?? [];

      return { users };
    } catch (error) {
      return {
        error: {
          message: "An error occurred while querying users: " + String(error),
        },
      };
    }
  },
});
