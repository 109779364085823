import { NavLink, Params, useLoaderData, useLocation } from "react-router-dom";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Icon,
  Link,
  Spacer,
  Stack,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { formatAmount, formatNumber } from "@/utils/formatter";
import { ReactNode, useState, useEffect } from "react";
import type { OrderDetail, OrderLineItem, ProductInfoMap, PriceSheetItem } from "@/utils/types";
import { queryOrderDetailByOrderId } from "@/api/queries/order-detail";
import { searchPriceSheetByCustomerId } from "@/api/queries/price-sheet";
import { getUserCompany, isFoundationFoods } from "@/utils/user";
import queryClient from "@/api/query-client";
import { formatDate } from "@/utils/formatter";
import { BsDot } from "react-icons/bs";
import { HiArrowLeft, HiX } from "react-icons/hi";

const useProductInfo = (supplierId: string, customerId: string) => {
  const [data, setData] = useState<ProductInfoMap>({});
  const [idList, setIdList] = useState<string[]>([]);
  const fetchData = async (ids: string[]) => {
    const productIds = Array.from(new Set(ids)).filter((id) => !idList.includes(id));
    if (!productIds?.length) return;
    const filterIds = productIds.join(",");
    const fetchSuggestions = searchPriceSheetByCustomerId(supplierId, customerId, "", filterIds, 0, 200, false);
    const { products }: { products: PriceSheetItem[] } = (await queryClient.fetchQuery(fetchSuggestions)) ?? {};
    if (products?.length) {
      setData((prevState) => {
        products?.forEach((item) => {
          prevState[item.id] = item;
          setIdList([...idList, item.id]);
        });
        return prevState;
      });
    }
  };
  return { data, fetchData };
};

export async function loader({ params }: { params: Params }) {
  const {
    company: { companyId },
  } = getUserCompany();
  const { orderId } = params as { orderId: string };
  const fetchOrderDetail = queryOrderDetailByOrderId(companyId, orderId);
  const { order }: { order: OrderDetail } = (await queryClient.fetchQuery(fetchOrderDetail)) ?? {};
  return { order };
}
export default function OrderInvoice(): ReactNode | null {
  const { order } = (useLoaderData() as { order: OrderDetail }) ?? {};
  const { data: productInfoData, fetchData: fetchProductInfo } = useProductInfo(order.companyId, order.customerId);
  const isFF = isFoundationFoods(order.companyId);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const sParam = queryParams.get("s");

  useEffect(() => {
    const productIds = order.lineItems.map((elem) => elem.productId);
    fetchProductInfo(productIds);
  }, [order]);

  const { attachments } = { attachments: [] };
  const totalAmount = order?.lineItems?.reduce((prev: number, next: OrderLineItem) => {
    const productPrice = productInfoData[next.productId]?.productPrice;
    const unitPrice = productInfoData[next.productId]?.unitPrice;
    const packageSize = productInfoData[next.productId]?.packageSize ?? 1;
    const quantity = isFF ? next.quantity * Number(packageSize) : next.quantity;
    const price = isFF ? unitPrice : productPrice;
    const discount = productInfoData[next.productId]?.couponAmount
      ? (100 - Number(productInfoData[next.productId]?.couponAmount)) / 100
      : 1;
    prev += Number((quantity * (price ?? 0) * discount).toFixed(2));
    return prev;
  }, 0);
  return (
    <Box
      title={`View ${order?.customerName}'s Summary`}
      mt="4"
      mx={{ base: 0, lg: 2 }}
      px={{ base: 2, lg: 6 }}
      h={{ base: "calc(100vh - 10rem)", lg: "calc(100vh - 12rem)" }}
      overflowY="auto"
    >
      <HStack
        as={NavLink}
        to={`/orders/${order.id}/${"analyzer"}${sParam ? `?s=${sParam}` : ""}`}
        borderWidth="thin"
        w="fit-content"
        p="2"
        borderRadius="lg"
        borderColor="gray.200"
      >
        <Icon as={HiArrowLeft} boxSize="4" color="gray.600" />
        <Text fontSize="xs"> Go to Analyzer</Text>
      </HStack>
      <Card p={{ base: "2", lg: "8" }} my="4" borderRadius="12" boxShadow="none">
        <CardHeader pt="0">
          <Heading size={{ base: "sm", lg: "md" }} textAlign="center">
            # {order?.id}
          </Heading>
        </CardHeader>

        <CardBody borderTopWidth="1px" borderTopColor="gray.400">
          <Stack divider={<StackDivider />} spacing="4">
            <VStack>
              <Heading size="sm">{order?.customerName}</Heading>
              <Text pt="2" fontSize="sm">
                Delivery Date {formatDate(order?.deliveryDate)}
              </Text>
            </VStack>
            <Box>
              <Heading size="sm">Details</Heading>
              {order?.lineItems?.map((item, index: number) => {
                const { description: productName, productId } = item;
                const productPrice = productInfoData[productId]?.productPrice;
                const unitPrice = productInfoData[productId]?.unitPrice;
                const unit = productInfoData[productId]?.unit;
                const packageSize = productInfoData[productId]?.packageSize ?? 1;
                const discount = productInfoData[productId]?.couponAmount
                  ? (100 - Number(productInfoData[productId]?.couponAmount)) / 100
                  : 1;
                const quantity = isFF ? item.quantity * packageSize : item.quantity;
                const price = isFF ? unitPrice : productPrice;
                const totalPrice = (quantity ?? 0) * (price ?? 0);
                return (
                  <HStack key={index} pt="2">
                    <Box fontSize="sm" alignItems="center" display={{ base: "block", lg: "flex" }}>
                      <Text>{quantity}</Text>
                      <Icon as={HiX} boxSize="3" color="gray.400" />
                      <Text>{productId}</Text>
                      <Icon as={BsDot} boxSize="3" color="gray.400" />
                      <Text> {productName}</Text>
                      <Text color="gray.400" ml={{ base: 0, lg: 2 }}>
                        ${formatNumber(price)}
                      </Text>
                      {isFF && (
                        <Text color="gray.400" textTransform="lowercase">
                          /{unit}
                        </Text>
                      )}
                    </Box>
                    <Spacer />

                    {/* {discount < 1 && (
                        <Text fontSize="sm" color="gray.400" textDecoration="line-through">
                          {formatAmount(totalPrice)}
                        </Text>
                      )} */}
                    <Text fontSize="sm">{formatAmount(totalPrice * discount)}</Text>
                  </HStack>
                );
              })}
            </Box>
            <Box display="flex" pt="2">
              <Heading size="sm">Total</Heading>
              <Spacer />
              <Text fontSize="sm" fontWeight="semibold">
                {formatAmount(totalAmount)}
              </Text>
            </Box>
            {attachments?.length > 0 && (
              <Box>
                <Heading size="sm">Attachments</Heading>
                <Stack spacing="2" mt="2">
                  {attachments.map((attachment, index) => (
                    <Link
                      key={index}
                      color="blue.500"
                      download={attachment}
                      href={attachment}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {attachment || "un-named attachment"}
                    </Link>
                  ))}
                </Stack>
              </Box>
            )}
          </Stack>
        </CardBody>
      </Card>
    </Box>
  );
}
