import { GraphQLClient } from "graphql-request";
import { instantiateGraphQLClient } from "@butter-technologies/dish";

const { VITE_CENTRAL_SERVER_BASE_URL: baseUrl } = import.meta.env;
const graphQLClient: GraphQLClient = instantiateGraphQLClient(baseUrl);

export const graphQLClientWithoutAuth = new GraphQLClient(`${baseUrl}/graphql`);

export const graphQLClientWithAuth = (token: string) => {
  return new GraphQLClient(`${baseUrl}/graphql`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });
};

export default graphQLClient;
