import { createContext, useContext, ReactNode } from "react";
import { UseDisclosureReturn, useDisclosure } from "@chakra-ui/react";

interface IOrderContext extends UseDisclosureReturn {}
export const OrderContext = createContext<IOrderContext | undefined>(undefined);

export default function NewOrderProvider({ children }: { children: ReactNode }) {
  const disclosure = useDisclosure();

  return <OrderContext.Provider value={disclosure}>{children}</OrderContext.Provider>;
}

export const useOrderContext = () => {
  const context = useContext(OrderContext);
  if (!context) {
    throw new Error("useOrderContext must be used within a NewOrderProvider");
  }
  return context;
};
