import { Params, useLoaderData, useNavigate } from "react-router-dom";
import {
  Badge,
  Box,
  Flex,
  HStack,
  Heading,
  Icon,
  Spacer,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Center,
  VStack,
  Button,
  Divider,
} from "@chakra-ui/react";
import { getUserCompany } from "@/utils/user";
import queryClient from "@/api/query-client";
import { ReactNode, useEffect, useState } from "react";
import type { Conversation, Customer, Order, User } from "@/utils/types";
import { queryCustomerByCustomerId } from "@/api/queries/customer";
import { useSidebarContext } from "@/components/layouts/sidebar-shell";
import { queryAllOrdersByCompanyId } from "@/api/queries/order-list";
import { queryConversationByConversationId } from "@/api/queries/conversation";
import { formatDate, formatTimestamp, formatTitle } from "@/utils/formatter";
import { HiOutlineChatAlt, HiOutlineExternalLink } from "react-icons/hi";
import UserList from "@/components/items/user-list";
import { queryAllUsersByCustomerId } from "@/api/queries/user";

// one conversation id - one customer id
export async function loader({ params }: { params: Params }): Promise<{
  customer: Customer;
  conversation: Conversation;
  hasMore: boolean;
  orders: Order[];
  users: User[];
}> {
  const { customerId, conversationId } = params as { customerId: string; conversationId: string };

  const {
    company: { companyId },
  } = getUserCompany();

  const fetchCustomer = queryCustomerByCustomerId(companyId, customerId);
  const { customer }: { customer: Customer } = (await queryClient.fetchQuery(fetchCustomer)) ?? {};

  const fetchOrders = queryAllOrdersByCompanyId(companyId, "", "", customerId, 0, 999, false);
  const { hasMore, orders = [] }: { hasMore: boolean; orders: Order[] } =
    (await queryClient.fetchQuery(fetchOrders)) ?? {};

  const fetchConversation = queryConversationByConversationId(companyId, conversationId);
  const { conversation }: { conversation: Conversation } = (await queryClient.fetchQuery(fetchConversation)) ?? {};

  const fetchUsers = customer ? queryAllUsersByCustomerId(companyId, customer.customerId) : null;
  const { users }: { users: User[] } = fetchUsers ? await queryClient.fetchQuery(fetchUsers) : { users: [] };

  return { customer, conversation, hasMore, orders, users };
}

export default function CustomerPage(): ReactNode {
  const {
    customer,
    conversation: _conversation,
    hasMore,
    orders,
    users,
  } = useLoaderData() as {
    customer: Customer;
    conversation: Conversation;
    hasMore: boolean;
    orders: Order[];
    users: User[];
  };
  const [conversation, setConversation] = useState(_conversation);
  const [tabIndex, setTabIndex] = useState(0);
  const { isOpen: isSidebarOpen } = useSidebarContext();
  const { customerName } = customer || {};
  const navigate = useNavigate();
  const channelTypes = ["Email", "Voicemail", "SMS"]; // "GrubAssist"

  useEffect(() => {
    setConversation(_conversation);
  }, [_conversation]);

  return (
    <Box mr={{ base: 0, lg: "6" }} px={{ base: 4, lg: 0 }}>
      <Flex my="2" mx="2" flexDir={{ base: "column", lg: "row" }} alignItems="center">
        <Heading
          fontSize={{ base: "lg", lg: "2xl" }}
          fontWeight="semibold"
          ml={isSidebarOpen ? "0" : { lg: "8" }}
          mt={{ base: "-2", lg: "0" }}
          mr={{ base: "0", lg: "8" }}
          isTruncated
          textAlign="left"
          maxW={{ base: "full", lg: "auto" }}
        >
          {customerName}
        </Heading>
        <Spacer />
        <HStack gap={{ base: "5", md: "6" }}>
          {[
            {
              label: "Total Users",
              value: conversation?.participants?.filter((elem) => elem.type == "BUYER").length,
            },
            {
              label: "Total Orders",
              value: orders?.length,
            },
          ].map(({ label, value }) => (
            <CustomerStat key={label} label={label} value={value?.toString() ?? ""} />
          ))}
        </HStack>
      </Flex>
      <Tabs align="center" onChange={(index) => setTabIndex(index)} index={tabIndex} variant="unstyled" pos="relative">
        <TabList>
          <Tab
            fontSize="md"
            fontWeight="semibold"
            _selected={{ color: "gray.800" }}
            _hover={{ color: "gray.800" }}
            color="gray.600"
          >
            Users
          </Tab>
          <Tab
            fontSize="md"
            fontWeight="semibold"
            _selected={{ color: "gray.800" }}
            _hover={{ color: "gray.800" }}
            color="gray.600"
          >
            Orders
          </Tab>
          {/* <Tab _selected={{ color: "gray.800" }} _hover={{ color: "gray.800" }} color="gray.600">
            Conversation History
          </Tab> */}
        </TabList>
        <TabIndicator h="2px" bgColor="gray.600" borderRadius="1px" />
        <TabPanels h="calc(100vh - 10rem)" overflowY="auto" mt="2">
          <TabPanel px="0">
            <VStack spacing="4">
              <ChannelPanel channelType="GrubAssist" conversation={conversation} users={users} customer={customer} />
            </VStack>
          </TabPanel>
          <TabPanel p="0">
            {/* Orders */}
            <Stack
              mt="2"
              spacing="2"
              overflowY="auto"
              h="calc(100vh - 11rem)"
              borderRadius="lg"
              borderWidth="thin"
              borderColor="gray.100"
            >
              <TableContainer overflowY="auto">
                <Table variant="simple">
                  <Thead pos="sticky" zIndex={1} top="0" bgColor="white" shadow="inset 0 -1px 0 #dbd9dd">
                    <Tr>
                      {["orderId", "orderStatus", "updatedAt", "createdOn", "deliveryDate", "messageTotal"].map(
                        (elem, index) => (
                          <Th bgColor="white" key={index}>
                            {formatTitle(elem)}
                          </Th>
                        )
                      )}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {orders.length == 0 ? (
                      <Tr>
                        <Td colSpan={6} border="none">
                          <Center>
                            <VStack>
                              <Text px="6" pt="4" color="gray.400">
                                No orders found
                              </Text>
                            </VStack>
                          </Center>
                        </Td>
                      </Tr>
                    ) : (
                      orders?.map(
                        (
                          {
                            id: orderId,
                            createdAt,
                            customerId,
                            customerName,
                            deliveryDate,
                            updatedAt,
                            orderStatus,
                            firstMessage,
                            messages,
                          },
                          index
                        ) => (
                          <Tr key={index}>
                            <Td>
                              <Button
                                fontWeight="semibold"
                                variant="unstyled"
                                _hover={{ color: "blue.400" }}
                                display="flex"
                                color="gray.800"
                                onClick={() => navigate(`/orders/${orderId}/analyzer`)}
                              >
                                {orderId}
                                <Icon as={HiOutlineExternalLink} boxSize="4" ml="2" />
                              </Button>
                            </Td>
                            <Td>
                              <Badge
                                variant="outline"
                                colorScheme={
                                  {
                                    Confirmed: "blue",
                                    NeedsReview: "green",
                                    Initialized: "gray",
                                  }[orderStatus]
                                }
                                textTransform="capitalize"
                                textAlign="center"
                                fontWeight="medium"
                                borderRadius="full"
                                fontSize="xs"
                                w="24"
                              >
                                {formatTitle(orderStatus)}
                              </Badge>
                            </Td>
                            <Td>{formatTimestamp(updatedAt)}</Td>
                            <Td>{formatDate(createdAt)}</Td>
                            <Td>{deliveryDate ? formatDate(deliveryDate) : "-"}</Td>
                            <Td>
                              <Button
                                fontWeight="semibold"
                                variant="unstyled"
                                _hover={{ color: "blue.400" }}
                                display="flex"
                                color="gray.800"
                                onClick={() => navigate(`/orders/${orderId}/analyzer`)}
                              >
                                <Icon as={HiOutlineChatAlt} boxSize="4" />
                                <Text ml="2">{messages?.length ?? 0}</Text>
                              </Button>
                            </Td>
                            {/* <Td>
                              <IconButton
                                aria-label="Link"
                                onClick={() => navigate(`/orders/${orderId}/analyzer`)}
                                fontWeight="semibold"
                                variant="unstyled"
                                _hover={{ color: "blue.400" }}
                                display="flex"
                                color="gray.600"
                                icon={<Icon as={HiOutlineExternalLink} />}
                                boxSize="4"
                                cursor="pointer"
                              ></IconButton>
                            </Td> */}
                          </Tr>
                        )
                      )
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>
          </TabPanel>
          {/* <TabPanel px="0">
            {!customer ? (
              <Box>No customer to view history</Box>
            ) : (
              <HistoryPage
                customer={JSON.stringify({
                  customerId: customer.customerId,
                  customerName: customer.customerName,
                  conversationId: conversation.conversationId,
                })}
              />
            )}
          </TabPanel> */}
        </TabPanels>
      </Tabs>
    </Box>
  );
}

const ChannelPanel = ({
  channelType,
  users,
  customer,
}: {
  channelType: "Email" | "Voicemail" | "SMS" | "GrubAssist";
  conversation: Conversation;
  users: User[];
  customer: Customer;
}) => (
  <Box borderWidth="thin" borderColor="gray.200" borderRadius="xl" p="4" maxW="full">
    <Text ml="2" mb="2" w="lg" textAlign="center" mx="auto">
      Our AI system processes orders by analyzing email and voicemail messages, identifying and matching them based on
      provided phone numbers and email addresses.
    </Text>
    <Divider bgColor="gray.100" w="full" />

    <Text textAlign="left" ml="2" my="2" fontWeight="normal" fontSize="sm">
      Please provide the full name, phone number, and/or email address of a team user for email and voicemail orders.
    </Text>
    {/* <Text textAlign="left" ml="2" mb="2" fontWeight="normal" w="xl" fontSize="sm">
      {channelType == "Email"
        ? "Enter the full name and corresponding email address of a user for email forwarding. Our AI system will use these email addresses to identify and match orders."
        : channelType == "Voicemail"
          ? "Enter the full name and corresponding phone number of a user for voicemail forwarding. Our AI system will use these phone numbers to identify and match orders."
          : channelType == "SMS"
            ? "Enter the full name and corresponding phone number of a user for message texting. These phone numbers will be invited to a text message group chat with our AI system."
            : ""}
    </Text> */}

    <Box borderWidth="thin" borderColor="gray.100" maxH="60" mx="4">
      <UserList users={users} channelType={channelType} isBuyer={true} customer={customer} />
    </Box>
  </Box>
);

const CustomerStat = (props: { label: string; value: string }) => {
  const { label, value, ...boxProps } = props;
  return (
    <Box w="32" py="2" textAlign="center" borderRadius="lg" borderWidth="thin" {...boxProps}>
      <HStack justifyContent="center">
        <Text textStyle="sm" color="gray.600">
          {label}
        </Text>
        <Heading size={{ base: "sm", md: "md" }} fontWeight="medium">
          {value}
        </Heading>
      </HStack>
    </Box>
  );
};
