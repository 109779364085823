import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";

const SortOrderContext = createContext<{
  isDesc: boolean;
  setIsDesc: Dispatch<SetStateAction<boolean>>;
  orderBy: "total" | "overdue" | "display";
  setOrderBy: Dispatch<SetStateAction<"total" | "overdue" | "display">>;
}>({
  isDesc: false,
  setIsDesc: () => {},
  orderBy: "total",
  setOrderBy: () => {},
});

export function useSortOrderContext(): {
  isDesc: boolean;
  setIsDesc: Dispatch<SetStateAction<boolean>>;
  orderBy: "total" | "overdue" | "display";
  setOrderBy: Dispatch<SetStateAction<"total" | "overdue" | "display">>;
} {
  return useContext(SortOrderContext);
}

export default function SortOrderProvider({ children }: { children: ReactNode }): ReactNode {
  // Get values from local storage for initial states
  const [isDesc, setIsDesc] = useState<boolean>(JSON.parse(localStorage.getItem("isDesc") ?? "true"));
  const [orderBy, setOrderBy] = useState<"total" | "overdue" | "display">(
    (localStorage.getItem("orderBy") as "total" | "overdue" | "display") ?? "total"
  );

  return (
    <SortOrderContext.Provider
      value={{
        isDesc,
        setIsDesc,
        orderBy,
        setOrderBy,
      }}
    >
      {children}
    </SortOrderContext.Provider>
  );
}
