import { gql } from "graphql-request";
import graphQLClient from "@/api/graphql-client";
import type { CompanyAltCase, PriceSheetItem, QueryError } from "@/utils/types";

export const SEARCH_PRICE_SHEET_BY_CUSTOMER_ID_QUERY = gql`
  query SearchPriceSheetByCompanyId(
    $companyId: ID!
    $customerId: ID!
    $search: ProductSearch
    $offset: Int
    $maxLength: Int
  ) {
    company(company_pid: $companyId) {
      customer(customer_id: $customerId) {
        price_sheet(search: $search, offset: $offset, max_length: $maxLength) {
          id
          parent_product_id
          slug
          name
          description
          category_name
          unit
          unit_price
          product_price
          package_size
          package_unit
          last_order_date
          coupon_code
          coupon_type
          coupon_amount
          images {
            id
            url
          }
          # suggested_retail_price
          # gross_profit_margin
          # upc_label
        }
      }
    }
  }
`;

export const searchPriceSheetByCustomerId = (
  companyId: string,
  customerId: string,
  searchText: string,
  filterIds: string,
  offset: number,
  maxLength: number,
  invalidateCache: boolean
): {
  queryKey: [string, string, string, string, string, number, number, boolean];
  queryFn: () => Promise<{ products: PriceSheetItem[] } | QueryError>;
} => ({
  queryKey: ["priceSheet", companyId, customerId, searchText, filterIds, offset, maxLength, invalidateCache],
  queryFn: async (): Promise<{ products: PriceSheetItem[] } | QueryError> => {
    try {
      const search = {} as { text?: string; filter_ids?: string };
      if (searchText) {
        search["text"] = searchText;
      }
      if (filterIds) {
        search["filter_ids"] = filterIds;
      }
      const { company }: { company: CompanyAltCase } =
        (await graphQLClient.request(SEARCH_PRICE_SHEET_BY_CUSTOMER_ID_QUERY, {
          companyId,
          customerId,
          search,
          offset,
          maxLength,
          invalidateCache,
        })) ?? {};
      const { price_sheet } = company.customer;

      const products =
        price_sheet?.map((elem) => ({
          id: elem.id,
          name: elem.name,
          slug: elem.slug,
          unit: elem.unit,
          unitPrice: elem.unit_price == null ? null : parseFloat(elem.unit_price),
          productPrice: elem.product_price == null ? null : parseFloat(elem.product_price),
          packageSize: elem.package_size == null ? null : parseFloat(elem.package_size),
          images: elem.images,
          lastOrderDate: elem.last_order_date,
          couponAmount: elem.coupon_amount,
        })) ?? [];
      return { products };
    } catch (error) {
      return {
        error: {
          message: "An error occurred while querying price sheet: " + String(error),
        },
      };
    }
  },
});
